@use "variables";

#Hero {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .h1 {
        font-size: 2.25rem;
    }
    .heroInfo a, .heroInfoMobile a {
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
    .slide.for-articles {
        max-width: 800px;
        margin: 0 auto;
    }
    .hero {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: variables.$hero-img-ratio;
        margin: 0 auto;
    }
    .lm-hero{
        padding-bottom: 35%;
        @media(max-width: variables.$bp-sm-min){
            padding-bottom: 50%;
        }
    }
    .hero.articleView {
        display: block;
        @media (max-width: variables.$bp-sm-min) {
            display: none;
        }
    }
    .articleImg {
        display: none;
        img {
            width: 100%;
            height: 100%;
        }
        @media (max-width: variables.$bp-xs-max) {
            display: block;
        }
    }
    .heroContent {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
    .heroImg { //force the hero image to be a certain aspect ratio
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        img {
            transition: 0.5s;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center; //for broken image display;
            object-fit: cover;
            @media (max-width: variables.$bp-xs-max) {
                height: 100%;
                width: auto;
                min-width: 100%;
            }
        }
    }
    .heroInfo {
        bottom: 0;
        color: variables.$banner-overlay-color;
        background-color: variables.$banner-overlay-bg;
        text-align: left;
        padding: 0.5rem 1.5rem;
        box-shadow: variables.$hero-text-shadow;
        border-width: variables.$hero-text-outline-width;
        border-style: variables.$hero-text-outline-style;
        border-color: variables.$hero-text-outline-color;
        border-radius: variables.$hero-text-border-radius;
        border-bottom: none;
        @media(max-width: variables.$bp-xs-max){
            display: none;
        }
    }
    .heroInfoSide {
        display: none;
    }
    .heroInfoMobile {
        display: none;
        height: 12rem;
        background-color: #141416;
        text-align: left;
        padding: 0 1.5rem;
        position: relative;
        justify-content: center;
        flex-direction: column;
        border: none;
        .h1 {
            font-size: 2rem;
            margin: 0.5rem 0;
        }
        .p {
            margin: 0 0 0.5rem;
        }
        .max-2-lines {
            display: inline-block;
            display: -webkit-box;
        }
        .max-3-lines {
            display: none;
        }
        @media(max-width: variables.$bp-xs-max){
            display: flex;
        }
        @media(max-width: variables.$bp-xxs-max){
            .h1 {
                font-size: 1.5rem;
            }
            .max-3-lines {
                display: -webkit-box;
            }
            .max-2-lines {
                display: none;
            }
        }
    }
    a:hover {
        .h1, .h2, .h3 {
            text-decoration: underline;
        }
    }
    .lm-heroInfoMobile{
        background-color: variables.$primary-color;
        border: none;
        & div {
            color: #fff;
            justify-content: center;
        }
        & p {
            color: #fff;
            text-align: center;
            font-style: italic;
        }
    }
}
