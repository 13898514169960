@use "variables";

.ArticleSummaryVertical {
    display: flex;
    flex-direction: column;
    position: relative;
    transition: 0.5s;
    overflow: hidden;
    border-style: variables.$a-s-v-outline-style;
    border-width: variables.$a-s-v-outline-width;
    border-color: variables.$a-s-v-outline-color;
    border-radius: variables.$a-s-v-border-radius;
    background-color: variables.$a-s-v-bg;
    padding: variables.$a-s-v-padding;
    margin: variables.$a-s-v-margin;
    box-shadow: variables.$a-s-v-shadow;
    height: 100%;
    .thumbnail {
        width: 100%;
        height: 50%;
        flex-basis: 50%;
        overflow: hidden;
    }
    &.featured .thumbnail {
        flex-basis: 70%;
    }
    &.featured .text {
        flex-basis: 30%;
        h2 {
            font-size: variables.$heading3-font-size;
            font-weight: variables.$bold-font-weight;
            @media (max-width: variables.$bp-md-min) {
                font-size: variables.$heading5-font-size;
            }
        }
    }
    .thumbnail-wrapper {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .thumbnail-wrapper, .article-link, .article-img, picture, img {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    img {
        object-fit: cover;
    }
    .text {
        flex-grow: 1;
        height: 50%;
        padding: 1rem 0 0;
        header {
            display: flex;
            flex-direction: column;
        }
        h2 {
            margin-top: 0;
            font-weight: variables.$default-font-weight;
            font-size: variables.$heading5-font-size;
            font-weight:variables.$bold-font-weight;
            line-height: 1.1;
        }
        h6 {
            margin: 0;
        }
        main {
            margin: 0;
        }
        .summary {
            margin-top: 0;
        }
        footer {
            align-items: flex-end;
            a {
                height: fit-content;
            }
            .sponsoredBy {
                flex-wrap: wrap;
                span {
                    margin-right: 5px;
                }
                text-align: right;
                @media (max-width:variables.$bp-xxs-max) {
                    display: none;
                }
            }
            .logo {
                max-width: 180px;
                max-height: 100%;
                margin-left: 1rem;
                picture {
                    display: flex;
                    img {
                        max-height: 30px;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

.isIE {
    .ArticleSummaryVertical {
        > .thumbnail, > .text {
            flex: 1 1 auto !important;
        }
        .thumbnail {
            max-height: 240px;
        }
        &.featured {
            > .thumbnail, > .text {
                flex: 1 1 auto !important;
            }
            .thumbnail {
                max-height: 350px;
            }
        }
    }
}
