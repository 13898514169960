@use "../../sass/variables";

#CookiePolicy {
    height: 0;
    overflow: hidden;
    width: 100%;
    background-color: variables.$link-color;
    transition: height 1s;
    color: #fff;
    font-size: 0.75rem;
    position: relative;
    z-index: 1000;

    .main-container {
        height: 100%;
    }

    .cookie-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    button {
        cursor: pointer;
        padding: 0.5rem;
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
        width: 8rem;
        flex-shrink: 0;
        margin-left: 1rem;
    }

    a {
        text-decoration: underline;
        cursor: pointer;
    }
}

#CookiePolicy.show {
    @media (min-width: variables.$bp-sm-min) {
        height: variables.$cookie-policy-height;
    }

    @media (max-width: variables.$bp-xs-max) {
        height: variables.$cookie-policy-height-xs;
    }

    @media (max-width: variables.$bp-xxs-max) {
        height: variables.$cookie-policy-height-xxs;

        .cookie-content {
            text-align: center;
            flex-direction: column;
            justify-content: center;
        }

        button {
            margin: 0.2rem 0;
            padding: 0.2rem;
        }
    }
}