@use "variables";

//Styles for Trends single article view
body.trends {
  #MainContainer {
    padding-top: variables.$header-height-bottom;
  }

  //Styles for trends header menu(_trends_header.pug)
  #MainHeader {
    height: variables.$header-height-bottom;
    border-width: 1px;

    #MainHeaderContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: variables.$header-height-bottom - variables.$header-outline-width;
      max-width: variables.$trends-header-max-width;

      #HeaderLeft {
        display: flex;
        align-items: stretch;
      }

      #brand {
        align-self: stretch;
        margin: 0;
        height: auto;
        background-image: variables.$mobile-logo-url;
        width: 70px;
        margin-left: 0;
        display: block;
        flex-direction: column;
      }
    }
  }
}

#TrendsContent {
  .slide .heroImg {
    max-height: 55vh;
    overflow: hidden;
  }

  /* css Parallax Styles */
  .parallax-container {
    display: block;
    position: relative;
    height: 45vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:empty {
      height: 0;

      &+.ArticleViewPage>.Trends.Default {
        margin-top: 0 !important;

        article {
          margin-top: 0;
        }
      }
    }

    .parallax-img {
      pointer-events: none;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 100%;
      width: 100vw;
    }

    @media (max-width: variables.$bp-sm-max) {
      height: 28vh;

      .parallax-img {
        background-attachment: initial;
      }
    }
  }

  main .unpublished {
    position: relative;
    overflow: hidden;
    filter: grayscale(1) opacity(0.8);

    #MainContent {
      position: absolute;
      top: 10vh;
      left: 0;
      right: 0;
      bottom: 0;

      #ArticleView {
        margin: 0 auto;
        background: none;

        article {
          margin: 0 auto;
          max-width: 100%;
        }
      }

      @media (max-width: variables.$bp-sm-max) {
        position: relative;
        top: 0;
      }
    }
  }

  #MainContent {

    #ArticleView,
    .breadcrumbs {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

    .articleWrapper {
      margin: 0;
      display: flex;
      max-width: 100%;
      border: none;
      border-bottom: 1px solid #e5e5e5;

      article {
        margin-top: -20vh;
        background-color: #fff;
        width: 100%;
        // border-top: 1px solid #e5e5e5;
        // border-left: 1px solid #e5e5e5;
        padding: 2rem;

        @media (max-width: variables.$bp-xs-min) {
          padding: 2rem 0;
        }

        header {
          position: relative;

          h1 {
            margin-top: 0;
          }

          h3 {
            font-style: italic;
            font-weight: 100;
          }
        }

        img:not(.avatar) {
          max-width: 100%;
        }

        // #ArticleBody::before{
        //     content: "";
        //     border-right: solid 1px #e5e5e5;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     padding: 130px;
        // }
        footer .author-section {
          display: flex;
          align-items: center;

          .bio-section {
            margin-left: 1rem;
          }

          picture {
            width: 100px;
            flex-shrink: 0;
          }

          img.avatar {
            padding: 0;
            max-width: 100%;
            height: auto;
            border-radius: 50%;
            object-fit: cover;
          }

          p {
            margin-top: 0;
          }
        }

        @media (max-width: variables.$bp-xs-min) {
          footer .author-section {
            flex-direction: column;
          }

          footer .bio-section {
            margin-top: 1rem;
          }
        }

        @media (max-width: variables.$bp-sm-max) {
          margin-top: 0;
        }
      }

      @media (max-width: variables.$bp-xs-max) {
        flex-direction: column;

        >* {
          flex: 1 1 auto;
        }
      }

      @media (max-width: variables.$bp-md-min) {
        .articleWrapper #ArticleBody::before {
          border-right: none;
        }
      }

      .imageBlock:first-of-type {
        width: 100% !important;
        margin: 1rem 0;
      }
    }

    #Sidebar {
      padding-top: 2rem;

      .sidebar-item>* {
        top: calc(#{variables.$header-height-top} + 3rem);
      }

      &.headerShown {
        .sidebar-item>* {
          top: calc(#{variables.$header-height-top} + #{variables.$header-height-bottom} + 3rem);
        }
      }
    }
  }
}

#MainContainer #TrendsContent #ConversionPageView article.main-container aside {
  top: calc(#{variables.$header-height-top} + 3rem);
}