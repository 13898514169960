@use "variables";

#TrendsListHero {
    header {
        width: 100%;
    }

    #TrendsFeatured {
        display: flex;
        padding: 1.5rem;
        width: 100%;
        margin: 0 0.5rem 1rem;
        .heroWrapper {
            order: 2;
            position: relative;
            flex-basis: 50%;
            width: 50%;
            margin-left: 1rem;
        }
        .heroInfoSide {
            order: 1;
            flex-basis: 50%;
            width: 50%;
        }
        .heroImg {
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .articleTabImg {
                height: 300px;
                .missingImage {
                    position: relative;
                }
            }
        }
        .article-img:before {
            content: none;
        }
        ul {
            padding-left: 1.2rem;
        }
        li {
            padding-bottom: 0.5rem;
        }
        @media(max-width: variables.$bp-xs-max){
            flex-direction: column;
            padding: 0;
            .h2 {
                font-weight: 500;
                font-size: 1.3rem;
            }
            .heroWrapper {
                order: 1;
                position: relative;
                width: 100%;
                flex-basis: 100%;
                margin: 0 0 1rem;
            }
            .heroInfoSide {
                order: 2;
                width: 100%;
                flex-basis: 100%;
                padding: 1rem;
            }
            .heroImg {
                img {
                    max-height: 300px;
                }
            }
            .action-btn{
                margin: 1rem auto 0;
                max-width: 250px;
            }
        }
    }

    .isIE {
        .heroWrapper, .heroInfoSide {
            @media(max-width: variables.$bp-xs-max){
                flex-basis: auto !important;
            }
        }
    }
}
