@use "../../sass/variables";

#Modal .modal-gate {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button-wrapper, .modal-gate-content {
        width: 500px;
        &.large {
            width: 820px;
        }
        position: relative;
        max-width: calc(100% - 30px);
    }

    .modal-gate-content {
        max-height: 90vh;
        border-radius: 6px;
        overflow-y: auto;
    }

    .close-btn {
        &.corner {
            position: absolute;
            padding: 0.5rem;
            top: 0;
            right: 0;
        }
        &.in-form {
            margin-top: 0.7rem;
        }
        &:hover {
            text-decoration: underline;
        }
    }

    .smart-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        font-size: 1.125rem;
        font-weight: variables.$default-font-weight;
        border-radius: 6px 6px 0 0;
        background-color: variables.$tertiary-color;
        @media (max-width: variables.$bp-sm-min) {
            font-size: 1rem;
        }
    }

    form {
        border-radius: 0;
        display: flex;
        flex-direction: column;
        background-color: #eee;
        label {
            font-size: variables.$heading-small-font-size;
        }
        input,
        select {
            font-size: variables.$heading-small-font-size;
            border-radius: 5px;
            width: 100%;
            padding: 0.3rem;
        }
        button {
            width: 250px;
            align-self: center;
        }
        .form-row {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .form-group {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.5rem;
                text-align: left;
                width: 48%;
            }
            @media (max-width: variables.$bp-sm-min) {
                flex-direction: column;
                .form-group {
                    flex: 1 auto;
                    width: 100% !important;
                }
            }
        }
        .error {
            margin: 0;
            padding: 5px;
            text-align: left;
            color: variables.$warning-color;
        }
        .error-inline {
            font-size: variables.$extra-small-font-size;
            margin-bottom: 1em;
            background-color: transparent;
        }
        .alert {
            color: variables.$warning-color;
        }
    }

    .form-footer {
        font-size: variables.$extra-small-font-size;
        border-radius: 0 0 6px 6px;
        .email {
            color: variables.$tertiary-color;
        }
    }
    .pdfRelatedContent {
        position: relative;
        background-color: #eee;
        text-align: center;

        .articleTabList {
            gap: 1rem;
            padding-bottom: 0.5rem;
            justify-content: flex-start;
            &::-webkit-scrollbar {
                width: 10px; // Width of the scrollbar
                height: 10px; // Height of the scrollbar (for horizontal scrolling)
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1; // Color of the track
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #888; // Color of the thumb
                border-radius: 10px;

                &:hover {
                    background: #555; // Color of the thumb when hovered
                }
            }
        }
        .articleTab {
            gap: 1rem;
            flex: 1;;
            width: calc(33% - 1rem);
            padding-bottom: 1rem;
            margin: 0;
            background-color: #fff;
        }
        .card-list-arrow {
            display: none;
        }
        @media (max-width: 650px) {
            .articleTabList {
                flex-wrap: nowrap;
                overflow-x: auto;
                scroll-snap-type: x mandatory;
                -webkit-overflow-scrolling: touch;
                scroll-behavior: smooth;
                transition: scroll-left 0.3s ease;
                > * {
                    min-width: 250px;
                }
                .articleTab {
                    scroll-snap-align: start;
                }
            }
            .card-list-arrow {
                background-color: variables.$primary-color;
                color: #fff;
                font-weight: bold;
                position: absolute;
                top: 65%;
                right: 9%;
                transform: translate(50%, -50%);
                height: 50px;
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
                font-size: 24px;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}
