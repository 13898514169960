@use "variables";

.event-card {
    border: 1px solid #ccc;
    background: variables.$advertisement-bg;
    padding: 1rem;
    line-height:1.4;
    margin: 2.5rem auto 1rem;
    width: 300px;
    flex-grow: 0 !important;
    text-align: left;
    @media (max-width: variables.$bp-sm-max) {
        margin: 0;
        width: 100%;
    }
    .extras {
        margin-top:1rem;
    }
    h3 {
        margin-top:0;
        font-weight:variables.$bolder-font-weight;
    }
    a {
        text-decoration:none;
    }
    .event-details-label {
        font-family: variables.$primary-font;
    }
    time {
        display:block;
        font-size:variables.$heading6-font-size;
        color:variables.$text-dark-color;
        font-family:variables.$secondary-font;
    }
    .sponsors {
        background-color: #fff;
        padding: 1rem;
    }
}

#Sidebar .event-card .socialMedia {
    justify-content: flex-start;
    display: unset;
    float: unset;
    p {
        margin: revert;
    }
}
