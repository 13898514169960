#speechify-root {
  $cta-color: rgb(52, 197, 241);
  display: inline-block !important; // force the styling on speechify player
  width: 100% !important; // force the styling on speechify player
  max-width: 450px !important; // force the styling on speechify player
  margin-top: 1rem !important;

  * {
    white-space: nowrap;
  }

  #gated-cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    #AudioArticleSignUp {
      width: 100%;
    }
  }

  #inline-player-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 200px;
    padding: 15px 10px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 229, 229);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 17px 0px;

    #inline-player-main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 8px;

      #inline-player-header a {
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
      }

      #seekbar-container {
        margin-top: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3px;
        height: 20px;

        #seekbar-bar {
          position: relative;
          width: 85%;
          border: 1px solid rgba(0, 0, 0, 0.08);
          margin: 0 16px;
          color: rgb(52, 197, 241);
        }

        #seekbar-thumb {
          position: absolute;
          height: 10px;
          width: 10px;
          background-color: $cta-color;
          border-radius: 100px;
          left: -5px;
          top: -5px;
        }
      }
    }

    #speechify-icon {
      line-height: 0;
      margin-left: 12px;
      color: rgb(52, 197, 241);
    }
  }
}
