@use "variables";

#Sidebar {
  display: flex;
  justify-content: center;
  flex-grow: 1;

  .sidebar-item {
    margin: 1rem 0;

    >* {
      position: sticky;
      transition: 0.5s ease-in-out;
      top: 0.5rem;
      margin: 0;
    }

    // Hide the sidebar-item by default if it contains the halfpage ad unit (for performance purposes)
    &:has(.advertisement.halfPage-ad) {
      display: none;
    }

    // Display the sidebar-item if the halfpage ad unit serves creative
    &:has(.advertisement.halfPage-ad:not([style*="display: none;"])) {
      display: flex;
    }
  }

  &.headerShown {
    .sidebar-item>* {
      top: 7rem;
    }

    top: calc(#{variables.$header-height-top} + #{variables.$header-height-bottom});
  }

  .CurrentIssue {
    background-color: transparent;
    margin: 0;

    @media (max-width: variables.$bp-sm-max) {
      display: flex;
      justify-content: center;
    }
  }

  #SidebarContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 300px;

    .main-container {
      width: 100%;
      padding: 0 1rem;
    }

    >* {
      flex: 1 1 auto;
    }
  }

  h3,
  .h3 {
    font-size: variables.$heading6-font-size;
    font-weight: variables.$bold-font-weight;
  }

  .h1.subTitle {
    font-size: variables.$heading3-font-size;
    padding-bottom: 0.5rem;
  }

  .learmoreBtn {
    display: none;
  }

  .ad {
    width: 300px;
    height: 250px;
  }

  .advertisement+.advertisement {
    margin-top: 0;
  }

  .articleTabList {
    justify-content: center;
  }

  .articleTab {
    // don't do animation for sidebar article tabs
    opacity: 1 !important;
    transform: none !important;
    margin: 0.75rem 0 0.5rem;
    padding: 0 0.5rem 1rem;
  }

  @media (min-width: variables.$bp-md-min) {
    .articleTab {
      width: calc(100% - 1rem);
    }
  }

  @media (max-width: variables.$bp-sm-max) {
    .schemaAddress {
      justify-content: center;
    }
  }

  .socialMedia {
    justify-content: center;
  }
}

#SidebarWithAssetInfo {
  margin: 0 auto;
}