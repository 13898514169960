@use "sass:math";
@use "variables";

.articleGroup {
    margin: 1rem 0;
    width: 100%;
    .group {
        display: grid;
        grid-template-columns: repeat(4, minmax(20%, 1fr));
        grid-template-rows: repeat(2, math.div(variables.$grid-feature-height, 2));
        gap: variables.$grid-gap;
        grid-gap: variables.$grid-gap;
        margin-top: 0.5rem;
        .feature {
            grid-column: 1/3;
            grid-row: 1/3;
            width: 100%;
            height: 100%;
        }
        .grid-ad {
            margin: 0 auto auto;
        }
        article {
            padding: 0;
            border: none;
        }
        article:nth-child(1) {
            border-right: 1px solid variables.$card-outline-color;
            padding-right: 1rem;
        }
        article footer {
            border-bottom: 1px solid variables.$card-outline-color;
            padding-bottom: 0.5rem;
        }
        @media (max-width: variables.$bp-md-min) {
            grid-template-columns: repeat(2, minmax(45%, 1fr));
            grid-template-rows: none;
            gap: 1.5rem;
            grid-gap: 1.5rem;
            padding-bottom: 0;
            border-bottom: 0;
            article, article:nth-child(1)  {
                border-width: 0 0 1px;
                padding-right: 0;
            }
            article footer {
                padding-bottom: 1rem;
            }
        }
        @media (max-width: variables.$bp-xxs-max) {
            display: flex;
            flex-wrap: wrap;
            .thumbnail {
                flex-basis: auto;
            }
        }
    }
}
