.video-block {
    .video-player {
        position: relative;
        display: block;
        height: 0;
        padding: 0;
        overflow: hidden;
        padding-bottom: 56.25%; /* Assume a 16x9 ratio */
        &.ratio-4x3 {
            padding-bottom: 75%;
        }
        .embed-responsive-item,
        iframe,
        embed,
        object,
        video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
    p {
        margin: 0;
    }
}
