@use "variables";

.ad {
  color: #fff;
  background-color: variables.$missing-content-color;
  margin: 1em auto;
  width: 720px;
  max-width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
}

.advertisement {
  text-align: center;

  &>div {
    position: relative;
  }

  &>div::before {
    content: "Advertisement";
    color: #999999;
    padding: 0;
    font-size: 11px;
    position: absolute;
    bottom: -17px;
    right: 0;
    left: 0;
    text-align: center;
    text-transform: uppercase;
  }

  &[data-zone*="LEADERBOARD"],
  &[data-zone*="BILLBOARD"] {
    // grey background on Ads
    background-color: variables.$advertisement-bg;
    padding: 1rem 0;
    width: 100%;
  }

  &[data-zone*="LEADERBOARD"] {
    height: 138px;
    width: 100%;
  }

  &[data-zone*="BILLBOARD"] {
    height: 300px;
    width: 100%;
    padding: 1rem 0;
  }

  &[data-zone*="MEDIUM-RECTANGLE"],
  &[data-zone*="MEDIUM_RECTANGLE"] {
    height: 270px;
  }

  &[data-zone*="TORPEDO"],
  &[data-zone*="ANCHOR"] {
    height: variables.$torpedo-ad-height;
  }

  &[data-zone*="HALF"] {
    height: 600px;
  }

  .topLeaderboard-ad,
  .topLeaderboardBelowHero-ad,
  .bottomLeaderboard-ad {
    margin: 0;

    @media (max-width: variables.$bp-sm-max) {
      width: 100%;
      height: 85px;
      margin: 1rem auto;
    }
  }

  @media (max-width: variables.$bp-sm-max) {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    &[data-zone*="LEADERBOARD"] {
      height: 85px;
      width: 100%;
    }

    &[data-zone*="BILLBOARD"] {
      height: 135px;
      width: 100%;
    }
  }

  @media (min-width: variables.$bp-xs-min) {
    &--sponsored-boombox {
      float: right;
      padding: 0 !important;
      width: 300px !important;
      height: 250px !important;
      margin: 0.75rem 0 1rem 2rem !important;
    }
  }
}

.relocate-desktop-ad,
.relocate-mobile-ad {
  display: none;
}

.advertisement.preHeader-ad {
  margin: 0;
}