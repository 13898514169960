@use "variables";

.newsletter-signup {
    display: inline-block;
    border: 1px solid #144E8C;
    border-radius: 10px;
    margin: 1rem 0;
    padding: 2rem 2rem 1.5rem;
    background-color: #ebf9fe;
    width: 100%;
    box-shadow: 0px 4px 17px 0px #0000001A;

    h2 {
        font-size: xx-large;
        color: #00123b;
        font-weight: bolder;
        line-height: 130% !important;
        margin: 0;
    }

    h3 {
        font-size: 24px;
        color: #00123b;
        font-weight: normal;
        margin: 0;
    }

    b {
        font-weight: bolder;
    }

    .newsletter-signup-main {
        display: flex;
        gap: 2rem;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .text-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        min-width: 300px;
        flex-grow: 1;
        gap: 1rem;
    }

    .form-area {
        flex-grow: 1;
        min-width: 250px;
    }

    form {
        border: 1px solid #00123b;
        border-radius: 6px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #fff;
    }

    label {
        font-family: variables.$primary-font;
    }

    .input {
        width: 100%;
        border: 1px solid #34C5F166;
        box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px 2px 3px rgba(0, 0, 0, 0.075);
        border-radius: 5px;
        background: #fff;
    }

    ::placeholder {
        color: #BBBBBB;
        font-style: italic;
    }

    .error {
        margin: 0;
    }

    input[name="email"],
    select[name="lmg_demo_country"] {
        height: 40px;
        width: 100%;
        border: none;
        padding: 0.5rem;
        font-family: variables.$primary-font;
        font-weight: 100;
        outline: none;
        background: transparent;
        text-align: left;
    }

    .hs_lmg_demo_country {
        .input {
            padding-right: 10px;
        }

        select {
            cursor: pointer;
        }
    }

    .hs-error-msgs {
        margin: 0 !important;
        padding: 0;
        font-family: variables.$primary-font;
        color: variables.$warning-color;

        li {
            display: block;
        }
    }

    .hs-button {
        background-color: #00123b;
        border: none;
        border-radius: 3px;
        color: variables.$action-btn-color;
        padding: 8px 10px;
        font-family: variables.$primary-font;
        width: 100%;
    }

    .submitted-message {
        margin: 0 !important;
        line-height: 1.5 !important;
        padding: 5px 8px 0;
        font-family: variables.$primary-font;
    }

    .newsletter-signup-footer {
        font-size: 12px;
        margin-top: 1rem;
        text-align: center;
    }
}