@use "variables";

.TrendsSummary {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: calc(25% - 1rem);
  -ms-flex-preferred-size: calc(33.3333% - 1.5rem);
  max-width: calc(25% - 1.5rem);
  height: auto;
  padding: 0;
  margin: 0.5rem;
  border: 1px variables.$a-s-h-outline-style variables.$a-s-h-outline-color;
  @media (max-width: variables.$bp-sm-max) {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
  @media (max-width: variables.$bp-xs-max) {
    flex-basis: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
  }
  .thumbnail {
    position: relative;
    flex-shrink: 0;
    display: flex;
    background: url("../assets/dot.png");
    background-image: image-set(
      url("../assets/dot.webp") type("image/webp"),
      url("../assets/dot.png") type("image/png")
    );
    border-width: variables.$a-s-h-outline-width;
    border-color: variables.$a-s-h-outline-color;
    border-style: variables.$a-s-h-outline-style;
  }
  .thumbnailWrapper {
    transition: 0.5s;
    width: 100%;
    padding-bottom: variables.$thumbnail-img-ratio;
    position: relative;
    margin: 0;
    background-color: variables.$missing-content-color;
    .article-summary-label {
      transform: unset;
      left: 1rem;
      font-weight: normal;
    }
    .article-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    picture {
      position: relative;
      height: 100%;
      width: 100%;
      display: inline-block;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 500ms ease-in;
      overflow: hidden;
    }
  }
  .text {
    padding: 1rem;
    .max-4-lines > * {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
  .footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 1rem 1rem;
    @media (max-width: variables.$bp-xs-max) {
      align-items: center;
      justify-content: center;
    }
    .bp-sponsor {
      width: fit-content;
      margin: 1rem 0 0;
    }
  }
}
