@use "variables";

.socialMedia {
    color: variables.$header-color;
    max-width: 450px;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 5px 0;
    float:right;
    p {
        margin: 0 4px 0 0;
    }
    .socialMediaIcon {
        span {
            display:none;
        }
        svg {
            color: variables.$tertiary-color;
            height: 1.675rem;
            width: 1.675rem;
            margin: 0.15rem;
            transform:scale(1);
            transition:transform 100ms ease-in;
            &:hover {
                color: #3a4b79;
            }
        }
    }
    .at-style-responsive .at-share-btn {
        margin: 0 3px;
        &:focus, &:hover {
            transform: none;
            outline: none;
        }
        &:hover {
            background-color: #3a4b79 !important;
        }
    }
}
