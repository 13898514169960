@media print {
    #MainHeader {
        display: none;
    }

    #MainContainer {
        #Hero {
            display: none;
        }

        #Sidebar {
            display: none;
        }

        .CurrentIssue {
            display: none;
        }

        #Sponsored {
            display: none;
        }

        #Marketplace {
            display: none;
        }

        #ExtraContent {
            display: none;
        }
    }

    #MainFooter {
        display: none;
    }

    #Torpedo {
        display: none;
    }

    #Peelback {
        display: none;
    }

    .advertisement {
        display: none;
    }
}
