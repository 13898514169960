.LatestIssue {
    .flex {
        flex-direction: column;
    }
    h1, .h1 {
        color: inherit;
    }
    h4 {
        margin-top:0;
    }
}
