@use "../../sass/variables";

.ArticleNavigation {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    align-items: center;
    @media all and (max-width:variables.$bp-xxs-max){
        flex-direction:column-reverse;
    }
    &> div {
        max-width:400px;
        min-width:30%;
        @media all and (max-width:variables.$bp-xxs-max){
            width:100vw;
        }
    }
    .next{
        text-align:right;
        margin:0.5rem 1rem;
    }
    .previous{
        text-align:left;
        margin:0.5rem 1rem;
    }
    .ArticleNavigationTab {
        position: relative;
        height: auto;
        background-color: variables.$body-bg;
        border: 1px solid variables.$a-s-h-outline-color;
        overflow: hidden;
        background: #f5f5f5;
        padding:1rem;
        align-items: center;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
        transform:scale(1);
        &:hover {
            // box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
            // transform:scale(1.025);
            background:variables.$primary-color;
            color:#fff;
            box-shadow:none;
            transition: all 300ms ease-in-out;
            a, i{
                color:#fff;
            }
        }
        .ArticleNavigationTabContent{
            display:flex;
            width:100%;
            align-items: center;
        }
        .label {
            margin:0 1rem;
        }
        p {
            margin:0;
            text-align:right;
            line-height:1.2;
        }
        a {
            display: flex;
            justify-content: flex-end;
            text-decoration:none;
            align-items: center;
            .missingImage, img {
                height: 50px;
                width: 50px;
                margin-right:auto;
                display:block;
                position: relative;
            }
        }
        i {
            color:variables.$primary-color;
            font-size:2.5rem;
            margin:0;
        }
        .fa-chevron-square-right:before {
            content: "\f32b";
        }
        .fa-chevron-square-left:before {
            content: "\f32a";
        }
    }
    .previousArticle .ArticleNavigationTabContent, .previousArticle a{
        flex-direction:row-reverse;
        justify-content: flex-start;
        .missingImage, img {
            margin-left:auto;
            margin-right:0;
        }
    }
    .previousArticle p {
        text-align:left;
    }
    div:only-of-type{
        &.nextArticle {
            margin-left:auto;
        }
        &.previousArticle {
            margin-right:auto;
        }
    }
    .nextArticle .ArticleNavigationTabContent, .nextArticle a{
        flex-direction:row;
        justify-content: flex-end;
        .missingImage, img {
            margin-right:auto;
            margin-left:0;
        }
    }
    .nextArticle p {
        text-align:right;
        flex: 1;
    }
    @media all and (max-width:variables.$bp-xxs-max){
        .nextArticle p, .previousArticle p {
            text-align:center;
            margin-bottom:4rem;
        }
    }

}
