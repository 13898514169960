@use "../../sass/variables";

#Modal .magazine-gate-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    z-index: 9999;
    background-color: rgba(0,0,0,0.5);

}

#Modal .magazine-gate-content {
    width: 600px;
    max-width: calc(100% - 30px);
    margin: 10% auto;
    height: auto;
    padding: 2rem 2rem 1rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: visible;
    h1 {
        margin: 0;
    }
    input[type=submit] {
        padding: 0.5rem 1rem;
        border-radius: variables.$btn-radius;
        border: 1px solid variables.$primary-color;
        font-weight: lighter;
        background: variables.$primary-color;
        &:hover {
            background: variables.$secondary-color;
            color: variables.$primary-color-inverted;
            border-color: variables.$primary-color;
        }
    }
    .close-btn {
        float: right;
        font-size: 28px;
        font-weight: 800;
        cursor: pointer;
        margin-top: -15px;
        margin-right: -10px;
    }
}
@media(min-height:400px) and (max-height:700px){
    #Modal .magazine-gate-content{
        margin: 2% auto;
    }
}

.showing-error {
    .submitted-message { //don't show the hubspot submitted message, display our own
        display: none;
    }
}
