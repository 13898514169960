@use "variables";

body.showPreHeader {
  margin-top: variables.$pre-header-height-lg;
  @media (max-width: variables.$bp-sm-max) {
    margin-top: variables.$pre-header-height-xs;
  }
}

#preHeader {
  width: 100%;
  height: variables.$pre-header-height-lg;
  position: fixed;
  top: 0;
  z-index: -1;
  + #MainHeader + #MainContainer {
    background-color: variables.$body-bg;
    position: relative;
    z-index: 2;
  }
  .section,
  section {
    margin: 0;
    &.advertisement {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12), inset 0px 4px 17px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: variables.$bp-sm-max) {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    height: variables.$pre-header-height-xs;
  }
}

#MainHeader {
  background-color: variables.$body-bg;
  box-shadow: variables.$header-shadow;
  border-width: variables.$header-outline-width;
  border-style: variables.$header-outline-style;
  border-color: variables.$header-outline-color;
  position: relative;
  z-index: 3;
  height: variables.$header-height-top + variables.$header-height-bottom + variables.$header-outline-width;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    margin: 0 !important;
  }
  &.collapse {
    height: 0 !important;
    transition: height 0.5s;
  }
  &.collapse *,
  &.collapse > * {
    display: none !important;
  }

  #MainHeaderContent {
    width: 90vw;
    max-width: variables.$header-max-width;
    margin: 0 auto;
    padding: 0 1rem;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: variables.$header-height-top variables.$header-height-bottom;
  }

  #brand {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
    display: inline-block;
    background-image: variables.$logo-url;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    margin: 0.5rem 0;
  }

  #HeaderTop {
    grid-column: 2 / 7;
    grid-row: 1 / 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0.5rem 0;
  }

  #MenusMobile {
    grid-column: 2 / 7;
    grid-row: 2 / 2;
    display: none;
  }

  #MenusDesktop {
    grid-column: 2 / 7;
    grid-row: 2 / 2;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    align-items: center;

    .dropdown {
      position: relative;
      display: inline-block;
      .navItem,
      .subNavItem {
        text-transform: uppercase;
        font-weight: 400;
        text-decoration: none;
        white-space: nowrap;
      }
      .navItem {
        display: block;
        &:hover {
          color: variables.$primary-color;
        }
      }
      .navItem.inverted {
        color: variables.$action-btn-color;
        background-color: variables.$action-btn-bg;
        box-shadow: variables.$action-btn-shadow;
      }
    }
    .dropdown:not(.static):hover > .navItem {
      background-color: transparent;
      color: variables.$primary-color;
      &.inverted {
        background-color: variables.$primary-color;
        color: #fff;
      }
    }

    /** Handle dropdown-content */
    $subNavItem-height: 2.2rem;
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #fff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 6;
      border: 2px solid variables.$primary-color;
      .subNavItem {
        display: flex;
        flex-basis: $subNavItem-height;
        padding: 0 1rem;
        align-items: center;
        background-color: #fff;
      }
      .subNavItem:hover {
        color: variables.$primary-color-inverted;
        background-color: variables.$primary-color;
      }
    }
    /** Handle single column dropdown content layout */
    .dropdown:hover .dropdown-content {
      display: flex;
      flex-direction: column;
      & > * {
        flex-basis: $subNavItem-height;
      }
    }
    /** Handle multi column dropdown content layout */
    .dropdown:hover .dropdown-content.multi-col {
      display: grid;
      grid-template-rows: repeat(6, $subNavItem-height);
      grid-auto-flow: column;
    }
    .dropdown:last-of-type .dropdown-content {
      right: 0; // to avoid the dropdown-content on the last menu item exceed the screen size on the right
    }
  }

  @media (max-width: 1500px) {
    #MainHeaderContent {
      grid-template-columns: 1fr 1fr;
    }
    #brand {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    #MenusDesktop {
      grid-column: 1 / 7;
      grid-row: 2 / 3;
      justify-content: space-between;
    }
  }

  @media (max-width: variables.$bp-md-max) {
    height: variables.$header-height-top + variables.$header-outline-width;
    #MainHeaderContent {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: variables.$header-height-top;
    }
    #MenusMobile {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
    }
    #MenusDesktop {
      display: none;
    }
  }

  @media (max-width: variables.$bp-xs-max) {
    #MainHeaderContent {
      width: 100%;
    }
    #brand {
      grid-column: 1 / 4;
    }
    .InlineSearch,
    .subscribe-btn.action-btn {
      display: none;
    }
  }
}
