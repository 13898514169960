.CurrentMagazineIssueSidebar {
  display: flex;
  justify-content: center;
  .magazine {
    display: flex;
    width: 300px;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    background: #f8f8f8;
  }
  .magazine-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;

    .top,
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: stretch;
      text-align: end;
    }

    .magazine-text {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    .magazine-summary {
      color: #00123b;
    }
  }

  .magazine-image {
    img {
      border: 1px solid #555555;
    }
  }

  button {
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 6px;
    border: 1px solid #00123b;
    background: #00123b;

    a {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.467px;
      text-decoration: none;
    }

    &:hover {
      background: #5973b8;
    }
  }
}
