@use "variables";

/* TODO: check where we can use $tertiary-color instead of a bunch of action-btn-color. */
button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button, html input[type=button], input[type=reset], input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}
button, select {
    text-transform: none;
}
button {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

.action-btn {
    display: flex;
    max-height: 2.5rem;
    align-items: center;
    justify-content: center;
    color: variables.$action-btn-color;
    background: variables.$action-btn-bg;
    border: variables.$action-btn-border;
    padding: variables.$action-btn-padding;
    border-radius: variables.$action-btn-radius;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    &.primary {
        background: variables.$primary-color;
    }
    &.secondary {
        background: variables.$secondary-color;
    }
    &:active{
        top: 2px;
    }
    &:hover {
        transition: transform 20ms;
        text-decoration: none;
        background: variables.$action-btn-hover-bg;
        color: variables.$action-btn-hover-color;
        border-color: variables.$action-btn-hover-color;
    }
    &:disabled {
        background: variables.$secondary-color;
        color: variables.$action-btn-color;
        border-color: variables.$action-btn-border;
        pointer-events: none;
    }
    &.compact {
        width: fit-content;
    }
}

.action-btn.inverted {
    background: variables.$action-btn-hover-bg;
    color: variables.$action-btn-hover-color;
    border-color: variables.$action-btn-hover-color;
    &:hover {
        color: variables.$action-btn-color;
        background: variables.$action-btn-bg;
        border-color: variables.$action-btn-hover-bg;
    }
}

.action-btn.small {
    padding: 0.25rem 0.5rem;
}

.btn {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: variables.$action-btn-radius;
    border:1px solid transparent;
    display:inline-block;
    text-decoration:none;
    &.branded {
        background:variables.$action-btn-bg;
    }
    &.hollow{
        background: transparent;
    }
    &.altBranded {
        background:variables.$alt-brand-color;
        text-align:center;
    }
    &:hover {
        border-color:#fff;
    }
}

svg {
    &[data-prefix="far"], &[data-prefix="fas"], &[data-prefix="fal"] {
        margin-right: 0.35rem;
    }
}
