@use "../../sass/_variables";

.AlphaPaginationContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: variables.$text-muted-color;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-width: variables.$pagination-outline-width;
    border-style: variables.$pagination-outline-style;
    border-color: variables.$pagination-outline-color;
    border-radius: variables.$pagination-border-radius;
    background-color: variables.$pagination-bg;
    @media (max-width: variables.$bp-xs-min){
        flex-direction: column;
        justify-content: center;
    }
    button {
        background: transparent;
        border: none;
        padding: 0.25rem 0.5rem;
        color: variables.$link-color;
        cursor: pointer;
        // border-radius: 100%;
    }
    button:hover {
        text-decoration: underline;
    }
    button.selected:hover {
        text-decoration: none;
    }
    button.selected {
        cursor: default;
        background-color: variables.$link-color;
        color: #fff;
    }
    button:disabled {
        color: variables.$text-muted-color;
        cursor: default;
    }
}
