@use "../../sass/variables";

#HSInlineForm {
    .progress-wrapper {
        width: 100%;
        display:  flex;
        justify-content:  space-between;
        align-items:  flex-end;
        margin:  0  auto;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            background-color: #555;
            position: absolute;
            top: 30%;
            left: 40px;
            transform: translateY(-50%);
            height: 2px;
            width: calc(100% - 80px);
            z-index: -1;
        }
        .progress-bar {
            background-color: variables.$secondary-color;
            position: absolute;
            top: 30%;
            left: 40px;
            transform: translateY(-50%);
            height: 4px;
            width: 0%;
            z-index: -1;
            transition: 0.4s ease;
        }
        .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            .progress-text {
                padding-top: 0.5rem;
                color: variables.$secondary-color;
            }
            .progress-circle {
                border: 2px solid #555;
                height: 32px;
                width: 32px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                transition: 0.4s ease;
                transition-delay: 1s;
                padding-top: 2px;
            }
            &.active-form .progress-circle {
                background-color: variables.$secondary-color;
            }
        }
    }
    .in-line-form {
        width: 100%;
        padding: 2rem;
        .hs-input {
            box-sizing: border-box;
            max-width: unset;
            height: 40px;
            width: 100%;
            padding: 0 0.5rem;
            border-radius: 6px 6px 0px 0px;
            background: #fff;
            border: 2px solid #ccc;
            box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
            border-bottom: 4px solid #ccc;
            text-align: left;
            &[type="checkbox"] {
                background: none;
                height: 20px;
                width: 20px;
                margin-right: 1rem;
                box-shadow: none;
            }
        }
        li.hs-form-checkbox {
            label {
                display: flex;
                margin-bottom: 1rem;
            }
        }
        .hs-form-required {
            margin-left: 3px;
        }

        .hs-form-required,
        .hs-error-msgs, .hs-error-msgs label {
            color: variables.$warning-color;
            font-weight: normal;
            padding-left: 0;
        }
        .hs-button, input[type=submit] {
            // Exactly the same as .action-btn
            display: inline-block;
            color: variables.$action-btn-color;
            background: variables.$action-btn-bg;
            border: variables.$action-btn-border;
            padding: variables.$action-btn-padding;
            border-radius: variables.$action-btn-radius;
            text-transform: uppercase;
            margin: 0.25rem 0;
            text-decoration: none;
            &:hover {
                transition: transform 20ms;
                text-decoration: none;
                background: variables.$action-btn-hover-bg;
                color: variables.$action-btn-hover-color;
                border-color: variables.$action-btn-hover-color;
            }
        }

        .submitted-message p {
            text-align: left !important;
            margin: 0.5rem 0;
            font-size: 1.5rem;
            .action-btn {
                font-size: 1rem;
                font-family: variables.$secondary-font;
                text-transform: unset;
            }
        }

        .inputs-list:first-child {
            padding-top: 6px;
        }

        .inputs-list > li + li {
            padding-top: 2px;
        }

        ul {
            list-style: none;
        }

        .hs-input:focus {
            border-bottom: 4px solid variables.$tertiary-color;
        }

        label,
        .hs-form {
            font-family: variables.$primary-font;
            color: #000;
        }

        input.hs-input.error,
        div.field.error input,
        div.field.error textarea,
        div.field.error .chzn-choices,
        textarea.hs-input.error,
        select.hs-input.error {
            border-color: variables.$warning-color;
            border-bottom: 4px solid variables.$warning-color;
            margin: 0;
        }
        .field {
            margin-bottom: 2rem;
        }
    }

    .form-header, .form-footer {
        background-color: #efefef;
        padding: 1.5rem 2rem;
        a {
            color: variables.$tertiary-color;
            text-decoration: underline;
        }
    }
    .form-footer small{
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
    @media (max-width: variables.$bp-xxs-max){
        .in-line-form, .form-footer {
            padding: 1rem;
        }
    }
    fieldset {
        max-width: unset !important;
    }
}
