@use "variables";

#DigitalForumLandingPage {
    width: 100%;
    margin: 0 0 1rem;

    >* {
        padding: 0 2rem 0 0;
    }

    @media (max-width: variables.$bp-sm-max) {
        margin-bottom: 0;

        >* {
            padding: 0;
        }
    }
}