@use "../../../sass/variables";

#LeadForm {
  form {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem 2rem;
    background: rgba(245, 245, 245, 1);
  }

  .heading {
    font-size: variables.$heading3-font-size;
    font-weight: variables.$default-font-weight;
    padding: 1rem 1.5rem;
    background: variables.$primary-color;
    color: #fff;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-radius: 8px 8px 0 0;
    margin-bottom: 0;
  }
  .form-title {
    border-bottom: 2px solid variables.$primary-color;
    display: inline-block;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
  }
  input,
  select,
  textarea {
    &[type="checkbox"] {
      width: 25px;
      height: 25px;
      border-radius: variables.$border-radius;
      margin: 0 0.5rem;
    }
    margin: 0 0 1rem;
    font-size: 1.105rem;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid variables.$border-color;
  }

  .form-section {
    .form-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (max-width: variables.$bp-sm-min) {
        flex-direction: column;
      }

      &.one-item {
        .form-group {
          width: 100%;
        }
      }

      &.two-items {
        .form-group {
          width: 45%;
        }
      }

      &.three-items {
        .form-group {
          width: 30%;
        }
      }

      @media screen and (max-width: variables.$bp-sm-min) {
        .form-group {
          flex: 1 auto;
          width: 100% !important;
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      text-align: left;
    }
    .product-info {
      padding: 1rem;
      margin-bottom: 1rem;
      display: flex;
      background: #fff;
      .product-detail {
        padding: 1rem;
      }
      .product-image {
        min-width: 200px;
        height: 200px;
        position: relative;
        overflow: hidden;
        padding: 0;
        margin: 0;
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
      @media screen and (max-width: variables.$bp-xs-min) {
        flex-direction: column;
      }
      .vedor-logo {
        width: 100%;
        height: auto;
        overflow: hidden;
        img {
          height: 100%;
          width: 150px;
          padding: 0.5rem 0rem;
        }
      }
    }
    .form-section-wrapper {
      padding: 1rem;
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: variables.$border-radius;
      background: #fff;
    }

    .inline-fieldset {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem;
      margin: 0.5rem 0;
    }
  }
  a {
    color: variables.$primary-color;
    text-decoration: underline;
  }
  .error {
    margin: 0;
    padding: 5px;
    text-align: left;
    color: variables.$warning-color;
  }
  .error-inline {
    font-size: 0.9rem;
  }
  .alert {
    // font-size: 14px;
    font-style: italic;
    color: variables.$warning-color;
  }
}
.smart-label {
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: variables.$bold-font-weight;
  .inline-required {
    margin-left: 4px;
    margin-right: auto;
    color: variables.$warning-color;
    font-size: 8px;
  }
  .optIn {
    font-size: 0.9rem;
    font-weight: 100;
  }

  i {
    font-size: 8px;
    margin-bottom: 8px;
    color: variables.$warning-color;
  }
}
