@use "variables";

.CurrentIssue {
    width: 100%;
    background-color: #ccc;
    margin: 1.5rem 0 3rem;
    z-index: 2;
    .main-container {
        max-height: 240px;
        height: 240px;
        @media (max-width: variables.$bp-sm-max) {
            max-height: 100%;
        }
        @media (max-width: variables.$bp-xs-max) {
            height: 100%;
        }
    }
    .section-padding {
        padding:0;
        background-image: url(../assets/magazine_bg.jpg), linear-gradient(to bottom, #144e8c 0%, #041e50 100%);
        background-size: cover, auto;
    }
    #MagazineContent {
        max-width: 980px;
        margin:0 auto;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .magazine-image {
            transform: rotate(0deg);
            width: 210px;
            padding: 0;
            margin: 0;
            img {
                width: 100%;
                box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.3);
            }
        }
        .magazine-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 1.5rem;
            margin: 0 0.5rem 1rem;
            > * {
                flex: 1 1 auto;
            }
            h1, .h1, h4, .h4, p {
                width: 100%;
                margin: 0.25rem 0;
            }
        }
        .left {
            height:100%;
            display: flex;
            align-items: center;
            @media (max-width: variables.$bp-xs-max) {
                width: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding:2rem 0 3rem;
                text-align: center;
            }
        }
        .right.text-white {
            h3, .h3 {
                color: #fff;
            }
        }
    }
}
.section.CurrentIssue + footer{
    margin-top:-3rem;
}
