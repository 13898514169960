@use "variables";

/*********************************************************************************************
   Typography - General global typography
**********************************************************************************************/

h1, .h1 {
    font-family: variables.$primary-font;
    color: variables.$header-color;
    font-weight: 900;
    font-size: 2rem;
    line-height:variables.$default-line-height;
    margin: 1.5rem 0 1rem;
    > p {
        font-size: inherit;
        margin: 0;
    }
}
h2, .h2 {
    font-family: variables.$primary-font;
    color: variables.$header-color;
    font-weight: 400;
    font-size: 1.5rem;
    margin: 1rem 0;
    > p {
        font-size: inherit;
        margin: 0;
    }
}

h2.larger, .h2.larger {
    font-size: 1.7rem;
}

h2.smaller, .h2.smaller {
    font-size: 1.3rem;
}

h3, .h3 {
    font-family: variables.$primary-font;
    color: variables.$header-color;
    font-weight: 900;
    font-size: 1.1rem;
    margin: 0.75rem 0;
    > p {
        font-size: inherit;
        margin: 0;
    }
}

h3.larger, .h3.larger {
    font-size: 1.125rem;
}

h3.smaller, .h3.smaller {
    font-weight: 400;
    font-size: 0.8rem;
    margin: 1rem 0;
    > p {
        font-size: inherit;
        margin: 0;
    }
}

h4, .h4 {
    // font-family: $primary-font;
    color: variables.$header-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    margin: 0.75rem 0;
    > p {
        font-size: inherit;
        margin: 0;
    }
}

h5, .h5 {
    font-family: variables.$primary-font;
    color: variables.$header-color;
    font-size: 1rem;
    font-weight: 400;
    margin: 0.375rem 0;
    > p {
        font-size: inherit;
        margin: 0;
    }
}

h6, .h6 {
    font-family: variables.$primary-font;
    font-size: 1rem;
    color: variables.$text-muted-color;
    font-weight: 300;
    margin: 0.75rem 0;
    > p {
        font-size: inherit;
        margin: 0;
    }
}

.p {
    // font-size: 1rem;
    margin: 1rem 0;
    // font-family: $primary-font;
}

.title, .subTitle {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: baseline;
    color: variables.$header-color;
    width: 100%;
    padding: 0 0 0.25rem;
    margin: 1.5rem 0 0.5rem;
    border-bottom: 1px solid variables.$header-color;
    > * {
        margin: 0;
    }
}

time {
    padding:0;
    margin:0;
    font-size: variables.$heading-small-font-size;
    font-style: italic;
    font-weight: 300;
    color: variables.$text-muted-color;
    letter-spacing: 0.5px;
}

@media (max-width: variables.$bp-xs-max) {
    h1, .h1 {
        font-size: variables.$heading2-font-size;
    }
}
