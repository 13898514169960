@use "variables";

.missingImage {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #fff;
    background-color: variables.$missing-content-color;
    svg {
        height: 3rem;
        width: auto;
    }
}
