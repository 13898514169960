@use "variables";

.articleTabList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: center;
}
#Sponsored {
  margin: 1rem auto 0;
  h2 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 20px;
    border-bottom: none;
  }
  .articleTabList {
    justify-content: space-between;
  }
  @media (min-width: variables.$bp-xs-min) and (max-width: variables.$bp-xs-max) {
    .articleTabList {
      justify-content: center;
    }
    .articleTab {
      margin-bottom: 0.75rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: variables.$bp-xxs-max) {
    .articleTab {
      margin-right: 0;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: variables.$bp-sm-min) {
    .articleTab:last-child {
      margin-right: 0;
    }
  }
}
