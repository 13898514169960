@use "variables";

.MagazineGateBtn {
    display: flex;
    .btn {
        white-space: nowrap;
        font-size: 14px;
        text-transform: uppercase;
        background-color: unset;
        padding: 0.5rem;
        &.downloadBtn{
            margin-right: 3rem;
        }
        &:focus{
            outline: 0;
        }
        &:hover{
            text-decoration: underline;
            border-color: transparent;
        }
    }
    @media(max-width: variables.$bp-xxs-max) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .btn {
            margin: 0;
        }
    }
}
