@use "../../sass/_variables";

.InlineSearch {
    display: flex;
    height: 100%;
    flex-grow: 1;
}

.InlineSearchContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &.open {
        .InlineSearchForm {
            opacity: 1;
        }
    }
    .icon-btn {
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: variables.$tertiary-color;
        position: absolute;
        &:focus, &:hover {
            color: variables.$secondary-color;
        }
    }
    .InlineSearchForm {
        width: 100%;
        max-width: 280px;
        overflow: hidden;
        position: relative;
        right: 0;
        bottom: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        transition: width 0.5s ease, height 0.5s ease, opacity 0.25s ease;
        background-color: variables.$placeholder-bg;
        padding: 0;
        opacity: 0;
        form {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .search-input {
                width: 100%;
                padding: 0.5rem;
                border-style: solid;
                border-width: 0 0 2px;
                border-color: variables.$tertiary-color;
                background-color: transparent;
                color: variables.$tertiary-color;
                &::placeholder {
                    font-style: italic;
                    color: variables.$placeholder-color;
                }
            }
        }
    }
}
