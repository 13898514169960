@use "variables";

.MagazineSummary {
    width: 100%;
    display: flex;
    transition: 0.5s;
    overflow: hidden;
    border-style: variables.$a-s-h-outline-style;
    border-width: variables.$a-s-h-outline-width;
    border-color: variables.$a-s-h-outline-color;
    border-radius: variables.$a-s-h-border-radius;
    padding: variables.$a-s-h-padding;
    margin: variables.$a-s-h-margin;
    .thumbnail .thumbnailWrapper {
        border: 1px solid variables.$text-muted-color;
        margin: 0;
        img {
            transition: 0.5s;
            display: block;
            width: 150px;
            height: 186px;
        }
    }
    .text {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        width: 65%;
        footer {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            width: 700px;
            max-width: 90%;
        }
    }
    &:not(.currentIssue) time, &:not(.currentIssue) footer{
        a {
            color: initial;
        }
    }
    time {
        text-transform: uppercase;
    }
    .missingImage {
        position: relative;
        width: 150px;
        height: 186px;
    }
}

