@use "../../sass/variables";

#MenusMobile {
  .SignIn {
    display: none;
  }
  .SearchBar {
    form {
      right: 2rem;
      height: 2rem;
      background-color: transparent;
      max-width: 100%;
    }
    input {
      position: relative;
    }
    .search-btn,
    .close-btn {
      font-size: 1.25rem;
      margin: 0;
    }
  }
  button {
    outline: none;
    border: none;
  }
  .open-btn {
    font-size: 1.5rem;
    color: variables.$header-icon-color;
    background-color: transparent;
    margin: 0;
    padding: 0 0 0 0.75rem;
  }
  .close-btn {
    font-size: 1.5rem;
    color: variables.$primary-color;
    background-color: transparent;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    &:hover {
      color: variables.$secondary-color;
    }
  }
  .subscribe-btn {
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
  .open {
    .menu-list {
      height: 100vh;
      overflow-y: scroll;
    }
  }
  .menu-list {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    background-color: #fff;
    z-index: 200;
    box-shadow: variables.$header-shadow;
    text-align: left;
    #menu-brand {
      background-image: variables.$mobile-menu-logo-url;
      display: block;
      height: 3rem;
      max-width: 15rem;
      width: 100%;
      background-repeat: no-repeat;
      margin: 1rem 0rem 1rem 2.5rem;
    }
  }
  .menu-list-wrapper {
    padding: 0rem;
    background-color: variables.$primary-color;
    margin: 0rem auto 1rem;
    display: flex;
    justify-content: center;
    height: 100%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  li {
    > a,
    div {
      width: 100%;
      display: inline-block;
      padding: 0.75rem;
      cursor: pointer;
      font-size: 1rem;
      text-transform: capitalize;
      font-weight: variables.$bold-font-weight;
    }
  }
  .closed > .subMenuItems {
    display: none;
  }
  .open > .subMenuItems {
    display: block;
  }
  .mobileMenu {
    background-color: variables.$primary-color;
    color: #000000;
    text-transform: uppercase;
    width: 100%;
    padding: 0.5rem 0;
    strong {
      align-items: center;
      color: #000000;
      border: 1px solid #fff;
      border-radius: 5px;
      padding: 0.9rem;
      font-family: variables.$primary-font;
      font-weight: normal;
      text-transform: capitalize;
      width: 70%;
      max-width: 270px;
      margin-top: 2rem;
    }
  }
  .menu-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  .menuItem a,
  .menuItem span {
    padding: 8px 0;
    padding-left: 8px;
    width: 60%;
  }

  .subMenu {
    padding-left: 8px;
    background: #fff;
    color: #5973b8;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
  }
  .mobileMenu.open > .menuItem {
    background: #5973b8;
    color: #fff;
  }
  button.submenu-toggle.chevron-icon {
    background: transparent;
    color: #000;
    width: 40%;
    text-align: right;
  }
  .mobileMenu.open .menu-item-container > button.submenu-toggle.chevron-icon {
    transform: rotate(90deg); /* Rotate the icon when the submenu is open */
    transition: transform 0.3s ease; /* Smooth transition for rotating the icon */
    color: #fff;
    width: auto;
  }
  .mobileMenu.open .subMenu {
    max-height: 800px; /* Adjust as needed */
  }
  .mobile-subscribe {
    padding: 0 1rem;
  }
  .mobile-subscribe a {
    width: auto;
    color: #ffffff;
    background-color: #333;
    border-radius: 5px;
    padding: 0.9rem;
    font-family: variables.$primary-font;
    font-weight: variables.$default-font-weight;
    text-transform: capitalize;
    width: 70%;
    max-width: 270px;
    margin-top: 1rem;
  }
  .InlineSearchContainer {
    justify-content: center;
    margin: 0rem auto 1rem;
    .InlineSearchForm {
      width: 100%;
      max-width: 100%;
      background-color: rgb(232, 240, 254);
      border-radius: 0px;
      padding: 0.8rem 0.5rem;
    }
    .search-input {
      width: 100%;
      left: 0;
      border-bottom: 1px solid #5973b8;
      text-align: left;
      padding: 3px 5px;
      font-size: 1.125rem;
      font-weight: variables.$bold-font-weight;
    }
    &.open.menu-search {
      @media (max-width: 380px) {
        margin-top: 1rem !important;
      }
    }
  }
}