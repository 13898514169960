@use "../../sass/variables";

#TrendsNav {
    position: sticky;
    margin:0;
    padding:0;
    top: 0;
    z-index: 15;
    transition: 0.6s;
    background-color:rgba(variables.$advertisement-bg, 1);
    box-shadow: variables.$header-shadow;
    border-bottom:6px solid variables.$primary-color;
    &.headerShown {
        top: variables.$header-height-bottom;
        box-shadow:none;
    }
    .TrendsNav {
        padding: 0;
        display:flex;
        justify-content: flex-start;
        width: 90vw;
        max-width: variables.$trends-header-max-width;
        position: relative;
        margin: 0 auto;
        @media (max-width: variables.$bp-md-min) {
            width: 100%;
            flex-wrap: wrap;
            margin: 0;
            .seriesTitle,
            .TrendsSponsor {
                padding: 0.5rem;
                max-width:50%;
                margin:0;
            }
        }
    }

    .TrendsSponsor,
    .seriesTitle {
        font-weight: 300;
        font-size:0.8rem;
        margin-right:1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width:110px;
        height:variables.$trends-header-height;
        overflow:hidden;
        align-items: flex-start;
        text-transform: uppercase;
        position: relative;
        p {
            margin:0;
        }
        img {
            width: auto;
            height: 100%;
            max-width:100%;
            max-height:2.75rem;
        }
        span {
            display:block;
            font-weight:600;
            text-transform:uppercase;
            line-height:1.2;
        }
    }
    .TrendsSponsor {
        align-items: center;
        max-width: 250px;
    }
    .menu-wrapper {
        position: relative;
        width: 100%;
        margin: 0;
        overflow: hidden;
        .menu {
            box-sizing: border-box;
            height:100%;
            margin:0;
            padding: 0 1rem;
            @media (max-width: variables.$bp-md-min) {
                padding: 0;
            }
        }
    }
    .ideaNavItem {
        font-weight: 300;
        font-size:0.8rem;
        flex-grow:1;
        // max-width:250px;
        height:80px;
        position:relative;
        border:none;

        @media (max-width: variables.$bp-md-min){
            height: 60px;
        }

        a {
            padding: 1rem 0.5rem;
            min-width: 0;
            display: inline-block;
            margin: 0;
            width: calc(100% - 40px);
            height: 100%;
            white-space: normal;
            position:relative;
            border:1px solid #ccc;
            border-right:0;
            border-left:0;
            border-bottom:0;
            background:#fff;
            display:flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0;
                font-weight:variables.$bold-font-weight;
            }
            &:after {
                content:"";
                border-radius: 0 20px 0 0;
                border: 1px solid #ccc;
                border-left:0;
                position:absolute;
                top:0;
                bottom:-5px;
                right: -26px;
                width: 30px;
                background: #fff;
                margin-top: -1px;
                box-shadow: 10px 0 12px rgba(0, 0, 0, 0.03);
            }
            &:before {
                content: "";
                border: 1px solid #ccc;
                position: absolute;
                top: 0;
                bottom: -1px;
                left: -40px;
                width: 41px;
                border-right: 0;
                border-bottom:0;
                background: #fff;
                z-index: -1;
                margin-top: -1px;
            }
            p:hover {
                // text-decoration:none;
            }
        }
        &.active:not(.unpublished):not(.seriesTitle),
        &:hover:not(.unpublished):not(.seriesTitle) {
            a {
                background:variables.$primary-color;
                // font-weight:400;
                color:#fff;
                position:relative;
                &:after, &:before {
                    background:variables.$primary-color;
                }
            }
        }
        &.unpublished {
            a {
                background:variables.$unpublished-bg;
                cursor: initial;
                color:variables.$text-muted-color;
                p {
                    text-decoration: none;
                    color:variables.$text-muted-color;
                }
                &:before, &:after {
                    background:variables.$unpublished-bg;
                }
            }
        }
    }
    .slick-list {
        //padding-top:1rem;
    }
    .slick-slide {
        &:first-of-type {
            padding-left:0.5rem;
            max-width:240px;
            border-left:1px solid #ccc;
        }
        &:not(:first-of-type).slick-current {
            .ideaNavItem a {
                padding-left: 1.5rem;
                @media (max-width: variables.$bp-md-min) {
                    padding-left: 2.5rem;
                }
            }
        }
        @media (max-width: variables.$bp-md-min) {
            &:first-of-type {
                border-left: none;
            }
        }
        @media (min-width: variables.$bp-md-min) {
            max-width:210px;
        }
    }
    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        height:100%;
        position: absolute;
        width: 1rem;
        //padding-top: 1rem;
        svg {
            font-size: 1rem;
            font-weight: bold;
            color: #fff;
            background: variables.$primary-color;
            padding: 0;
            height: 1.5rem !important;
            width: 1.5rem;
            border-radius: 50%;
            padding:0.3rem 0;
            position:absolute;
            filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.5));
        }
        &.slick-disabled {
            display: none;
        }
        &.slick-prev {
            left: 0;
            border-left: 1px solid #ccc;
            box-shadow: inset 5px 0 4px rgba(0, 0, 0, 0.2);
            svg {
                left:-100%;
                filter: drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.4));
            }
            @media (max-width: variables.$bp-md-min) {
                svg {
                    left: 0.25rem;
                }
            }
        }
        &.slick-next {
            right: 0;
            border-right: 1px solid #ccc;
            box-shadow: inset -5px 0 4px rgba(0, 0, 0, 0.2);
            svg {
                right:-100%;
                filter: drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.4));
            }
            @media (max-width: variables.$bp-md-min) {
                svg {
                    right: 0.25rem;
                }
            }
        }
        &:before {
            display: none;
        }
        @media (min-width: 1360px){
            // display:none;
        }
    }
}

