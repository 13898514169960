@font-face {
    font-family: "futura-pt";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_normal-300.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_normal-300.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_normal-400.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_normal-400.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_normal-500.woff2") format("woff2"),url("../fonts/FuturaPT/Futura_Pt_normal-500.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_normal-600.woff2") format("woff2"),url("../fonts/FuturaPT/Futura_Pt_normal-600.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_italic-300.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-300.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_italic-400.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-400.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_italic-500.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-500.woff") format("woff");
}
@font-face {
    font-family: "futura-pt";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/FuturaPT/Futura_Pt_italic-600.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-600.woff") format("woff");
}

@font-face {
    font-family: "open-sans";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_normal-300.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-300.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_normal-400.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-400.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_normal-600.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-600.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_normal-700.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-700.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_italic-300.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-300.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_italic-400.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-400.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_italic-600.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-600.woff") format("woff");
}
@font-face {
    font-family: "open-sans";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/OpenSans/Open_Sans_italic-700.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-700.woff") format("woff");
}
