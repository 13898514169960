@use "variables";
// #ArticleList {
//     @media (max-width: $bp-sm-max) {
//         p {
//             text-align:center;
//         }
//     }
// }
.ArticleList.VendorList {
    table {
        width:100%;
    }
    h3 {
        margin:1rem 0 0;
    }
    .sponsoredList,
    .fullList {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
    .fullList {
        margin-top:1rem;
    }
    .unsponsoredList {
        flex-basis:47%;
        width: 47%;
        display: flex;
        flex-direction: column;
        border-style: variables.$a-s-h-outline-style;
        border-width: variables.$a-s-h-outline-width;
        border-color: variables.$a-s-h-outline-color;
        border-radius: variables.$a-s-h-border-radius;
        padding:1rem 0;
        // padding: $a-s-h-padding;
    }
}
#vendorSite {
    font-family: Arial, Helvetica, sans-serif;
    font-size:90%;
    font-weight: normal;
    color: variables.$primary-color;
    padding: variables.$a-s-h-padding;


}
.featuredVendorList {
    width:100%;
    // border-bottom:1px solid $a-s-h-outline-color;
    flex-wrap: wrap;
    justify-content: flex-start;
    &:empty {
        padding-bottom: 0;
    }
    .featuredVendor {
        width: 23%;
        margin: 0 0.5rem;
        text-align:center;
        padding-top:1rem;
        &:only-child {
            margin-right: auto;
        }
        @media (max-width: variables.$bp-sm-min) {
            width:100%;
        }
    }
    figure {
        margin: 0rem auto;
        min-height:100px;
        position: relative;
        max-width: 200px;
        a:empty{
            display:block;
            background:#efefef;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .text {
        margin-top:0rem;
    }
    & + .title {
        margin-top:0;
    }
}
.VendorSummary {
    display: flex;
    flex-basis: 47%;
    width:47%;
    justify-content: space-between;
    padding:0.5rem 0;
    padding-right: 1rem;
    border-bottom:1px solid variables.$a-s-h-outline-color;
    min-height: 80px;
    @media (max-width: variables.$bp-sm-max) {
        display:block;
        width:100%;
        text-align:center;
        td {
            display:block;
        }
    }
    .thumbnailWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        height:100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .text {
        width: 100%;
        padding-left:0;
        main{
            h3 {
                a#vendorSite {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: normal;
                    color: variables.$primary-color !important;
                }
            }
        }
    }
    .thumbnail {
        width: 20%;
        overflow: hidden;
        max-height:80px;

    }
    .level-two{
        .article-summary-label{
            // bottom: 12px;
            // left: 0;
            bottom:0.75rem;
            left:0;
            // left:auto;
            height: 21px;
            width: 68px;
            background-color: variables.$primary-color;
            color: #fff;
            font-size: 12px;
            padding: 3px;
            transform: none;
        }
    }
}

@media(max-width: 700px){
    .ArticleSummary.VendorSummary{
        flex-wrap: wrap;
        .level-two{
            .article-summary-label{
                top:0.25rem;
                right:0;
                left:auto;
            }
        }
    }
}
@media(max-width: 568px){
    .ArticleList.VendorList .fullList{
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .ArticleSummary.VendorSummary{
        width:90vw;
        flex-direction: column;
        flex-wrap: nowrap;
        flex-basis: auto;
        .text{
            min-height: 2rem;
        }
        .thumbnailWrapper .article-img{
            position: static;
        }
        .level-two{
            .article-summary-label{
                top:0.5rem;
                right: 0;
            }
        }
    }
    .ArticleSummary.VendorSummary section.text .h3 {
        margin-top: 0;
    }
}

h3.vendor {
    margin:1rem 0 0;
}

