@use "variables";

input {
    font-size: 1rem;
    outline: none;
    color: #555;
}
input::placeholder {
    color: variables.$placeholder-color;
}

form {
    .btn {
        background-color: variables.$primary-color;
        color: #fff;
        border: none;
        box-shadow: variables.$btn-shadow;
        // text-transform: uppercase;
        // font-weight: 700;
        font-size: 1rem;
        padding: 0.75rem 1rem;
        border-radius: 0;
        cursor: pointer;
    }
    .input-standard {
        border-radius: 0;
        border: 1px variables.$border-color solid;
        font-size: 1rem;
        padding: 0.75rem 1rem;
        box-shadow: variables.$btn-shadow;
    }
}

.inline-searchform {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    input[name="for"] {
        flex-grow: 2;
    }
}
