@use "variables";

#TrendsList {
    flex-direction: column;
    #SSRList, #CSRList {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        margin-bottom: 2rem;
        flex-wrap: wrap;
    }
}
