.ArticleSection .notLoaded {
    padding-bottom: 25%;
}

.ArticleSection .isEmpty {
    display: none;
    &+ .advertisement {
        display: none;
    }
}
