@use "variables";

.thank-you-hubspot-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .field {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    label {
        color: variables.$body-color;
        text-align: left;
    }

    .hs-input {
        box-sizing: border-box;
        max-width: unset;
        height: 40px;
        width: 100%;
        padding: 0 0.5rem;
        margin: 0;
        border-radius: 6px 6px 0px 0px;
        background: #fff;
        border: 2px solid #ccc;
        box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-bottom: 4px solid #ccc;
        text-align: left;

        &:focus {
            border-bottom: 4px solid variables.$tertiary-color;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul[role="checkbox"] {

        @media (max-width: variables.$bp-xs-max) {
            columns: 1;
        }

        @media (min-width: variables.$bp-sm-min)and (max-width: variables.$bp-md-max) {
            columns: 2;
        }

        @media (min-width: variables.$bp-lg-min) {
            columns: 3;
        }

        label {
            display: flex;
            gap: 0.5rem;
        }

        .hs-input {
            height: 20px;
            width: 20px;
            box-shadow: none;
        }
    }

    .hs-form-required {
        margin-left: 3px;
        color: variables.$warning-color;
    }

    input[type=submit] {
        // Same as .action-btn
        display: inline-block;
        color: variables.$action-btn-color;
        background: variables.$action-btn-bg;
        border: variables.$action-btn-border;
        padding: variables.$action-btn-padding;
        border-radius: variables.$action-btn-radius;
        text-transform: uppercase;
        margin: 0.25rem 0;
        text-decoration: none;

        &:hover {
            transition: transform 20ms;
            text-decoration: none;
            background: variables.$action-btn-hover-bg;
            color: variables.$action-btn-hover-color;
            border-color: variables.$action-btn-hover-color;
        }
    }

    .is-placeholder {
        color: variables.$text-muted-color;
        font-style: italic;
    }

    .hs-error-msgs,
    .hs-error-msgs label {
        color: variables.$warning-color;
        font-weight: normal;
        padding-left: 0;
    }

    input.hs-input.error,
    div.field.error input,
    div.field.error .chzn-choices,
    textarea.hs-input.error,
    select.hs-input.error {
        border-color: variables.$warning-color;
        border-bottom: 4px solid variables.$warning-color;
    }
}