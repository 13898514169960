@use "variables";

.articleTab {
  display: flex;
  text-align: left;
  width: calc(50% - 1rem);
  overflow: hidden;
  align-items: center;
  border-style: variables.$a-t-h-outline-style;
  border-width: variables.$a-t-h-outline-width;
  border-color: variables.$a-t-h-outline-color;
  border-radius: variables.$a-t-h-border-radius;
  background-color: variables.$a-t-h-bg;
  padding: variables.$a-t-h-padding;
  margin: variables.$a-t-h-margin;
  box-shadow: variables.$a-t-h-shadow;
  @media (max-width: variables.$bp-xs-max) {
    margin: 0 0 1rem;
    width: 100%;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0.25rem;
  }
  h3.max-2-lines {
    display: none;
  }
  h3.max-4-lines {
    display: inline-block;
    display: -webkit-box;
  }
  h3.with-summary.max-3-lines {
    display: none;
  }
  h3.with-summary.max-2-lines {
    display: inline-block;
    display: -webkit-box;
  }
  h3.with-summary {
    margin-bottom: 0;
  }
  @media (max-width: variables.$bp-xxs-max) {
    h3.with-summary.max-3-lines {
      display: inline-block;
      display: -webkit-box;
    }
    h3.with-summary.max-2-lines {
      display: none;
    }
  }
  .summary {
    margin-top: 2px;
    > font,
    > span {
      font-family: variables.$primary-font !important;
      color: variables.$text-muted-color !important;
      font-size: 0.85rem !important;
    }
    @media (max-width: variables.$bp-xxs-max) {
      display: none !important;
    }
  }
  h6 {
    margin: 0;
  }
  .articleTabImg {
    position: relative;
    height: variables.$a-t-h-img-width;
    width: variables.$a-t-h-img-width;
    flex-shrink: 0;
    overflow: hidden;
    background: url("../assets/dot.png");
    background-image: image-set(
      url("../assets/dot.webp") type("image/webp"),
      url("../assets/dot.png") type("image/png")
    );
    border-width: variables.$a-s-h-outline-width;
    border-color: variables.$a-s-h-outline-color;
    border-style: variables.$a-s-h-outline-style;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
    a.articleImgLink {
      display: block;
      height: 100%;
    }
  }
  .articleTabText {
    padding: 2px 0.5rem 2px 1rem;
    font-style: normal;
    strong {
      font-weight: variables.$default-font-weight;
      font-style: normal;
    }
  }
}

.tabListAd {
  width: calc(33% - 1rem);
  background-color: variables.$advertisement-bg;
  padding: 1.5rem 0;
  border: 1px solid #e2e2e2 !important;
  align-self: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: variables.$bp-xs-max) {
    width: 100%;
  }
  .advertisement {
    // margin: 0.5rem;
  }
}

.articleTab.forMedia {
  display: block;
  width: calc(33% - 0.67rem);
  border-style: variables.$a-t-v-outline-style;
  border-width: variables.$a-t-v-outline-width;
  border-color: variables.$a-t-v-outline-color;
  border-radius: variables.$a-t-v-border-radius;
  background-color: variables.$a-t-v-bg;
  padding: variables.$a-t-v-padding;
  margin: 0 1rem 0 0;
  // flex-grow: 1;
  h3 {
    overflow: visible;
    display: block;
    -webkit-line-clamp: none;
    height: auto;
  }
  h3.with-summary {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 1rem;
  }
  .articleTabText {
    //padding:1rem 1rem 2rem;
    padding: 1rem 1rem 0;
  }
  .articleTabImg {
    position: relative;
    padding-top: 50%;
    height: auto;
    overflow: visible;
    width: 100%;
    background: url("../assets/dot.png");
    background-image: image-set(
      url("../assets/dot.webp") type("image/webp"),
      url("../assets/dot.png") type("image/png")
    );
    border-bottom-width: variables.$a-s-h-outline-width;
    border-bottom-color: variables.$a-s-h-outline-color;
    border-bottom-style: variables.$a-s-h-outline-style;
    border-right: none;
    a,
    span {
      margin-bottom: -1px;
    }
    .article-tab-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      margin-bottom: -1px;
    }
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
    a.articleImgLink {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  .summary {
    @media (max-width: variables.$bp-xxs-max) {
      display: -webkit-box !important;
    }
  }
  @media (min-width: variables.$bp-xs-min) and (max-width: variables.$bp-xs-max) {
    width: calc(50% - 1rem);
  }
  @media (max-width: variables.$bp-xxs-max) {
    width: calc(100% - 1rem);
    margin-bottom: 0.75rem;
  }
}
