@use "variables";

.AuthorViewPage {
    #Content {
        width: 100%;
    }
    h1.h2.title {
        border-bottom:0;
        &:after {
            display:none;
        }
    }
    .ArticleList {
        padding-top:0!important;
        .title {
            font-size:1.5rem;
            border-top:0;
            margin-top: 1rem;
        }
    }
    .author-info {
        display: flex;
        align-items: center;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 1rem;
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
        .title {
            font-weight: initial;
            font-size: 1.25rem;
            margin: 0;
            text-decoration: none;
            border: none;
        }
        img.avatar {
            padding: 0;
            margin-right: 1rem;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            object-fit: cover !important;
        }
        p {
            font-size: 0.8rem;
            color: #929292;
            padding: 0;
            margin: 0 0.25rem 0 0;
            letter-spacing: 0.5px;
        }
    }
    .ArticleSummary .author {
        border: none;
        padding: 0.5rem 0 0;
    }
    .author-content {
        width: 100%;
    }
}
