@use "variables";

.breadcrumbs {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0 0;
    font-size: 0.85rem;
    opacity: 0.75;
    font-style: italic;
    li {
        list-style-type: none;
        display: inline-block;
        a {
            color: variables.$link-color;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    li:after {
        content: "/";
        margin: 0.4rem;
    }
    li:last-child:after {
        content: ""
    }
}
