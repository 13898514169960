@use "variables";
#NewsletterSignUp {
    &.lazy-background.visible {
        background-image: url(../assets/eNewsletter_bg.jpeg);
        background-position: center;
        background-size: cover;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height:240px;
    max-height: 240px;
    @media (max-width: variables.$bp-sm-max) {
        flex-direction:column;
        text-align:center;
        height: 100%;
        max-height:100%;
    }

    label {
        font-size: 1rem;
        margin-bottom: 1rem;
        // font-style: italic;
    }
    input[type="email"] {
        height: 2.25rem;
        width: 300px;
        max-width: 100%;
        text-align: center;
        box-shadow: variables.$card-shadow;
        border: 1px solid #ccc;
        margin: 0.5rem auto;
    }
    .actions {
        margin: 0;
        padding: 0;
    }
    .hs-button {
        background: variables.$action-btn-bg;
        color: variables.$action-btn-color;
        box-shadow: variables.$action-btn-shadow;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem;
        font-weight: normal;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0;
        border: 1px solid #fff;
        cursor: pointer;
        font-family: variables.$primary-font;
    }
    input {
        position: relative;
        top: 0;
        left: 0;
    }
    input[type="submit"]:active{
        top: 2px;
    }
    ul {
        margin: 0;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .cover-img {
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg);
        padding:0 2rem;
        max-width:450px;
        margin-bottom: -1rem;
        margin-top: -1rem;
        img {
            width: 100%;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
        }
    }
    .cover-text {
        padding:2rem;
        .h1, p {
            margin:0;
        }
        button {
            margin-top: 1rem;
        }
    }
}

#NewsletterWrapper{
    .section#Newsletter{
        width: 100%;
        max-width: 1200px;
        padding: 0 1rem;
        opacity: 0.5;
        margin: 0 auto;
        transition-property: all;
        transition-duration: 1.5s;
        z-index: 2;
        &:hover {
            transition-property: all;
            transition-duration: 1.5s;
            opacity: 1;
        }
    }
}

.section#Newsletter{
    margin: 3rem auto 4rem;
    max-height:240px;
    @media (max-width: variables.$bp-sm-max) {
        max-height:100%;
    }
    .left {
        @media (max-width: variables.$bp-sm-max) {
            flex-direction:column;
            padding:2rem 0 3rem;
        }
    }
}
