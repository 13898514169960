@use "variables";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  font-size: variables.$base-font-size;
  font-weight: variables.$default-font-weight;
  overflow-x: clip;
  line-height: variables.$default-line-height;
  -webkit-font-smoothing: auto;
  scroll-behavior: smooth;

  &.fixed {
    overflow: clip;
  }
}

html,
body {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-text-size-adjust: none;
}

body {
  font-size: 1rem;
  font-family: variables.$primary-font;
  background: variables.$body-bg;
  color: variables.$body-color;
  line-height: variables.$default-line-height;

  >div {
    position: relative;
  }
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

#MainContainer {
  width: 100%;
  margin: 0 auto;
  max-width: 100vw;

  @media (min-width: variables.$bp-lg-min) {
    padding-top: variables.$header-height-top + variables.$header-height-bottom + variables.$header-outline-width;
  }

  @media (max-width: variables.$bp-md-max) {
    padding-top: variables.$header-height-top + variables.$header-outline-width;
  }

  button[disabled] {
    pointer-events: none;
  }
}

.infographic-layout {
  #MainContainer {
    padding-top: 4rem;
  }
}

#MainHeader:not(.fixed)+#MainContainer {
  padding-top: 0px;
}

#MainContent {
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 2rem;

  &[class*="TagPage"] {
    #Content {
      overflow-x: hidden;
    }
  }

  #Content {
    width: 100%;
    align-self: stretch;
    margin: 0 0 1rem;
    display: flex;
    flex-direction: column;

    >* {
      flex: 1 1 auto;
      padding: 0 2rem 0 0;
    }

    >main {
      width: 100%;
    }

    #Homepage {
      margin-top: 0;
    }

    &.noSidebar {
      .articleWrapper {
        max-width: 100%;
      }
    }
  }

  @media (max-width: variables.$bp-sm-max) {
    flex-wrap: wrap;

    #Content {
      margin-bottom: 0;

      >* {
        padding: 0;
      }

      #ArticleList {
        margin-top: 0;
      }
    }
  }
}

#Homepage .first {
  border: none;

  section.articleGroup>header h2 {
    border-top: none;
  }
}

.tag-group {
  width: 100%;
  border: variables.$border-color 1px solid;
  border-radius: variables.$border-radius;
  margin: 2em 0;
  padding: variables.$container-padding;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      width: 33%;
      padding: 0.3em 0;
      display: flex;
      align-items: center;

      @media (max-width: variables.$bp-xxs-max) {
        width: 50%;
      }
    }
  }

  a {
    color: variables.$primary-color;

    &:before {
      content: "#";
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

/**************/
/* Formatting */
/**************/

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  opacity: 1 !important;
}

.preload svg {
  display: none !important;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a>h1:hover,
a>.h1:hover,
a>h3:hover,
a>.h3:hover,
a>p:hover {
  text-decoration: underline;
}

a:hover+.h1,
a:hover+.h3 {
  text-decoration: underline;
}

a.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

a:focus {
  outline: 4px auto variables.$primary-color;
  outline-offset: -1px;
}

a.underline {
  text-decoration: underline;
}

/* Safely "Hide" for all but Screen Readers */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/***********/
/* Helpers */
/***********/
.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background-primary {
  background-color: variables.$primary-color !important;
}

.background-secondary {
  background-color: variables.$secondary-color !important;
}

.background-white {
  background-color: #fff !important;
}

.background-transparent {
  background-color: transparent !important;
}

.bold {
  font-weight: bold !important;
}

.bolder {
  font-weight: 900 !important;
}

hr.dotted {
  border: 1px dotted #eee;
  margin: 0;
}

.border-light-grey {
  border: 1px solid #e5e5e5;
}

.bottom-border {
  border-bottom: 1px solid !important;
}

.bp-sponsor {
  margin: 2rem auto;
  width: 100%;

  p {
    margin: 0;
  }

  .sponsor-img {
    max-width: fit-content;
    max-height: 2.75rem;
    height: 100%;
    width: auto;
  }
}

.capitalize {
  text-transform: capitalize !important;
}

.cover {
  width: 100%;
  height: 100%;
}

.card {
  box-shadow: variables.$card-shadow;
  border-width: variables.$card-outline-width;
  border-style: variables.$card-outline-style;
  border-color: variables.$card-outline-color;
  border-radius: variables.$card-border-radius;
  background-color: variables.$card-bg;
}

.category-group {
  border-radius: 6px;
  margin: 1rem auto;
  padding: 1rem 2rem;
}

.constrained-content {
  max-width: 50rem;
  margin: 0 auto;
}

.article-summary-label {
  position: absolute;
  z-index: 10;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-weight: variables.$bold-font-weight;
  background-color: #fff;
  padding: 0.4rem 1rem;
  color: variables.$link-color;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  border: 1px solid;
}

a .article-summary-label:hover {
  text-decoration: underline;
}

.columns-two {
  columns: 2;

  @media (max-width: variables.$bp-xs-max) {
    columns: 1;
  }
}

.columns-three {
  columns: 3;

  @media (max-width: variables.$bp-xs-max) {
    columns: 1;
  }
}

.columns-four {
  columns: 4;

  @media (max-width: variables.$bp-xs-max) {
    columns: 1;
  }
}

// Flex Helpers

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-around {
  align-items: space-around;
}

.flex-align-between {
  align-items: space-between;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-center {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

// Grid Helpers

.grid {
  display: grid;

  @media (max-width: variables.$bp-xs-max) {
    display: block;
  }
}

.grid-template-columns-4 {
  grid-template-columns: repeat(4, 1fr);

  // grid-template-rows: repeat(auto, 1fr);
  @media (max-width: variables.$bp-sm-max) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: variables.$bp-xs-max) {}
}

.grid-template-columns-3 {
  grid-template-columns: repeat(3, 1fr);

  // grid-template-rows: repeat(auto, 1fr);
  @media (max-width: variables.$bp-sm-max) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: variables.$bp-xs-max) {}
}

.grid-template-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-gap-default {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media (max-width: variables.$bp-xs-max) {
    margin: 1rem;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.font-primary {
  font-family: variables.$primary-font;
}

.font-secondary {
  font-family: variables.$secondary-font;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

// break out of containers for full-width / full-bleed container
.full-bleed {
  width: 100% !important;

  // margin-left: calc(50% - 50vw);
  @media (max-width: variables.$bp-xs-max) {
    margin-left: auto;
  }
}

// make sure the wrapper inside full-bleed centers (adjust width as needed)
.full-bleed>* {
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
}

.hero-header-credit {
  padding: 0.5rem;
  color: #222;
  text-transform: uppercase;
  font-size: 0.75rem;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;

  span.white {
    padding: 0.5rem;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
  }

  span.black {
    padding: 0.5rem;
    color: #222;
    background: rgba(255, 255, 255, 0.6);
  }

  a {
    color: variables.$link-color;
  }

  a:hover {
    text-decoration: underline;
  }
}

.hide {
  visibility: hidden !important;
}

.display-none {
  display: none;
}

.icon-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.alt-brand-btn {
  background: variables.$alt-brand-color;
  border: 1px solid #4a90e2;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  color: #fff;
  display: inline-block;
  height: 40px;
  width: 250px;
  text-align: center;
  margin-top: 1.5rem;

  @media (max-width: variables.$bp-sm-min) {
    display: block;
    margin: 1.5rem auto 0;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.invalid-feedback {
  display: block !important;
  color: #fff;
  padding: 1rem;
  background-color: #dc3545;
}

.input-btn-group {
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  max-width: 30rem;

  :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    border-top-left-radius: variables.$border-radius;
    border-bottom-left-radius: variables.$border-radius;
  }

  :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: variables.$border-radius;
    border-bottom-right-radius: variables.$border-radius;
  }

  input {
    border: 1px variables.$border-color solid;
    padding: 8px 12px;
    text-overflow: ellipsis;
    width: 100%;
  }

  button {
    font-size: 1rem;
    padding: 8px 12px;
    background-color: variables.$primary-color;
    color: #fff;
    flex-shrink: 0;
  }

  .input-group-icon {
    position: absolute;
    top: 4px;
    left: 8px;
    font-size: 20px;
  }
}

.input-btn-group.with-icon {
  input {
    padding-left: 40px;
  }
}

.italic {
  font-style: italic;
}

.generic-box {
  box-shadow: variables.$card-shadow;
  border-width: variables.$card-outline-width;
  border-style: variables.$card-outline-style;
  border-color: variables.$card-outline-color;
  border-radius: variables.$card-border-radius;
  background-color: variables.$card-bg;
  margin-bottom: 1rem;
  transition: 0.5s;
  padding: 1rem;
  text-align: left;

  @media (max-width: variables.$bp-xs-max) {
    text-align: center;
  }

  &.flex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: variables.$bp-xs-max) {
      flex-direction: column;
      align-items: center;
    }

    aside {
      flex: 1 1 auto;
    }
  }

  .link-list {
    margin: 1rem 0;
    padding: 0;
    list-style: none;

    a {
      color: variables.$primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.gradient-diagonal {
  background: variables.$secondary-color;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, variables.$secondary-color 0%, variables.$primary-color 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, variables.$secondary-color 0%, variables.$primary-color 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,
      variables.$secondary-color 0%,
      variables.$primary-color 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-horizontal {
  background: variables.$secondary-color;
  /* Old browsers */
  background: -moz-linear-gradient(left, variables.$secondary-color 0%, variables.$primary-color 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, variables.$secondary-color 0%, variables.$primary-color 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,
      variables.$secondary-color 0%,
      variables.$primary-color 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical {
  background: variables.$secondary-color;
  /* Old browsers */
  background: -moz-linear-gradient(top, variables.$secondary-color 0%, variables.$primary-color 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, variables.$secondary-color 0%, variables.$primary-color 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      variables.$secondary-color 0%,
      variables.$primary-color 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical-reverse {
  background: variables.$secondary-color;
  /* Old browsers */
  background: -moz-linear-gradient(top, variables.$primary-color 0%, variables.$secondary-color 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, variables.$primary-color 0%, variables.$secondary-color 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      variables.$primary-color 0%,
      variables.$secondary-color 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical-light {
  background: #4f7eb1;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4f7eb1 0%, #79caf0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4f7eb1 0%, #79caf0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      #4f7eb1 0%,
      #79caf0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical-leaderboard {
  background: #ddd;
  /* Old browsers */
  background: -moz-linear-gradient(bottom, #ddd 0%, #fff 50%, #ddd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, #ddd 0%, #fff 50%, #ddd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top,
      #ddd 0%,
      #fff 50%,
      #ddd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.header-color {
  color: variables.$header-color;
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0;
}

.listed-items {
  >a:after {
    content: ",";
    margin-right: 0.4rem;
  }

  >a:last-child:after {
    content: "";
  }
}

.main-container {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  padding: 0 variables.$main-container-horizontal-side-padding;
  max-width: variables.$main-container-max-width;

  &.relative {
    position: relative;
  }

  @media (max-width: variables.$bp-xs-max) {
    width: 100%;
  }
}

.no-border {
  border: none !important;
}

.no-background {
  background: none !important;
}

.no-wrap-text {
  white-space: nowrap;
  display: inline-block;
}

.no-hover-underline:hover {
  text-decoration: none !important;
}

.pull-right {
  position: absolute;
  right: 0;
}

.pull-left {
  position: absolute;
  left: 0;
}

.section {
  box-shadow: variables.$section-shadow;
  border: variables.$section-outline;
  margin: 1.5rem 0;
  position: relative;
  z-index: 1;
}

.section#MainFooter {
  margin: 0;
}

.section-padding {
  padding: variables.$section-padding;
}

.section.inverted {
  background: variables.$inverted-section-bg;
  color: variables.$inverted-section-color;
}

.section+.section {
  border-top: none;
}

.section-overflow {
  width: 100%;
  background-color: #bbb;
  margin: 5.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: variables.$section-overflow-height;
  max-height: variables.$section-overflow-height;

  @media (max-width: variables.$bp-xxs-max) {
    height: auto;
    max-height: none;
  }

  .section-container {
    width: 90vw;
    max-width: 1366px;
    margin: -2rem 0;
    border: 4px solid #aaa;
    border-radius: 6px;
    overflow: hidden;
    background-color: variables.$primary-color;
    color: #fff;
    height: calc(100% + 4rem);
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    @media (max-width: variables.$bp-xxs-max) {
      flex-direction: column;
      text-align: center;
    }

    h1,
    .h1 {
      color: inherit;
      margin: 0.5rem 0;
    }

    p {
      margin: 0;
    }

    .action-btn {
      margin-top: 1rem;
    }

    &.secondary {
      color: variables.$primary-color;
      border: 4px solid #ccc;
    }

    .left {
      margin-right: 2rem;

      @media (max-width: variables.$bp-xxs-max) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .right {
      @media (max-width: variables.$bp-xxs-max) {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
    }
  }

  .overflow-image {
    height: 100%;
    overflow: visible;

    @media (max-width: variables.$bp-xxs-max) {
      height: 180px;
      max-height: 180px;
    }

    img {
      background-color: #fff;
      height: calc(100% + 5rem);
      margin: -3rem auto;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

      &.default-image {
        background: none;
        box-shadow: none;
        // height: calc(100% + 5rem);
        // margin: -3rem auto;
      }
    }
  }
}

.sponsoredBy {
  padding-top: 0.5rem;
  color: #777;
  font-size: 0.875rem;
}

.sponsorName {
  text-transform: uppercase;
  color: variables.$primary-color;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.text-primary {
  color: variables.$primary-color !important;
}

.text-secondary {
  color: variables.$secondary-color !important;
}

.text-tertiary {
  color: variables.$tertiary-color !important;
}

.text-shadow {
  text-shadow: variables.$text-shadow !important;
}

.text-white {
  color: #fff !important;
}

.text-link {
  color: variables.$link-color !important;
}

.text-inverted {
  color: variables.$primary-color-inverted !important;
}

.text-placeholder {
  color: #999;
}

.text-with-space {
  margin-left: 0.4rem !important;
  margin-right: 0.4rem !important;
}

.text-with-space-left {
  margin-left: 0.4rem !important;
}

.text-with-space-right {
  margin-right: 0.4rem !important;
}

.text-with-more-space {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.text-with-more-space-left {
  margin-left: 1rem !important;
}

.text-with-more-space-right {
  margin-right: 1rem !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase !important;
}

.underline {
  text-decoration: underline !important;
}

@for $i from 0 through 6 {
  .m-t-#{$i} {
    margin-top: #{$i}rem;
  }

  .m-b-#{$i} {
    margin-bottom: #{$i}rem;
  }

  .m-l-#{$i} {
    margin-left: #{$i}rem;
  }

  .m-r-#{$i} {
    margin-right: #{$i}rem;
  }

  .m-v-#{$i} {
    margin-top: #{$i}rem;
    margin-bottom: #{$i}rem;
  }

  .m-h-#{$i} {
    margin-left: #{$i}rem;
    margin-right: #{$i}rem;
  }

  .p-b-#{$i} {
    padding-bottom: #{$i}rem;
  }

  .p-v-#{$i} {
    padding-top: #{$i}rem;
    padding-bottom: #{$i}rem;
  }

  .p-h-#{$i} {
    padding-left: #{$i}rem;
    padding-right: #{$i}rem;
  }

  .p-#{$i} {
    padding: #{$i}rem;
  }

  .m-#{$i} {
    margin: #{$i}rem;
  }
}

.m-h-a {
  margin-left: auto;
  margin-right: auto;
}

.m-l-a {
  margin-left: auto;
}

.l-h-1 {
  line-height: 1.15;
}

.l-h-3 {
  line-height: 1.3;
}

.l-h-5 {
  line-height: 1.5;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

/* Browswer Specific */

.isChrome {
  .max-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    // padding: 1px 0;
    >* {
      overflow: hidden;
      margin: 0;
    }
  }

  .max-3-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    // padding: 1px 0;
    >* {
      overflow: hidden;
      margin: 0;
    }
  }

  .max-4-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    // padding: 1px 0;
    >* {
      overflow: hidden;
      margin: 0;
    }
  }
}

.notChrome {
  .max-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.15;
    max-height: 2.4em; // fall back
    padding: 1px 0;

    >* {
      overflow: hidden;
      margin: 0;
    }
  }

  .max-2-lines.l-h-3 {
    line-height: 1.3;
    max-height: 2.7em; // fall back
  }

  .max-2-lines.l-h-5 {
    line-height: 1.5;
    max-height: 3em; // fall back
  }

  .max-3-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.15;
    max-height: 3.6em; // fall back
    padding: 1px 0;

    >* {
      overflow: hidden;
      margin: 0;
    }
  }

  .max-3-lines.l-h-3 {
    line-height: 1.3;
    max-height: 4em; // fall back
  }

  .max-3-lines.l-h-5 {
    line-height: 1.5;
    max-height: 4.5em; // fall back
  }

  .max-4-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 1.15;
    max-height: 4.8em; //fall back
    padding: 1px 0;

    >* {
      overflow: hidden;
      margin: 0;
    }
  }

  .max-4-lines.l-h-3 {
    line-height: 1.3;
    max-height: 5.3em; // fall back
  }

  .max-4-lines.l-h-5 {
    line-height: 1.5;
    max-height: 6em; // fall back
  }
}

// hide empty p tags and legacy p tags with br tags as first elements
p:empty {
  display: none !important;
}

.article-img,
.article-tab-img,
#FeaturedGrid .image {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transition: all 500ms ease-in-out;
  }

  &:hover {
    display: block;
    width: 100%;
    height: 100%;

    img,
    source {
      transform: scale(1.025);
    }
  }

  img,
  source {
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 500ms, filter 200ms ease-in-out;
  }
}

.share-widget {
  margin-top: 1rem;

  &>span {
    font-size: variables.$small-font-size;
  }

  .a2a_svg {
    margin-top: 0.3rem;
  }
}

//////////////////////////////////////////////////////////////////////////////////
// Below css are managing #Torpedo, which includes cookie policy and torpedo ad //
//////////////////////////////////////////////////////////////////////////////////

#Torpedo {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  transform: translate3d(0, 0, 0);
  box-shadow: variables.$torpedo-shadow;

  .advertisement {
    background: variables.$torpedo-bg;
    margin: 0;
    padding: 0;

    &[data-zone*="TORPEDO"]>div {
      padding: 0;
      background: none;
      border: none !important;
    }
  }
}

// Make HubSpot pop-ups above .torpedo-ad
#app:not(.cookie-policy) div[id^='hs-web'][id*='bottom'],
#app:not(.cookie-policy) div[class^='leadinModal'][class*='bottom'] .leadinModal-content {
  bottom: variables.$torpedo-ad-height;
}

// Make HubSpot pop-ups above cookie policy and .torpedo-ad
#app.cookie-policy div[id^='hs-web'][id*='bottom'],
#app.cookie-policy div[class^='leadinModal'][class*='bottom'] .leadinModal-content {
  @media (min-width: variables.$bp-sm-min) {
    bottom: calc(variables.$torpedo-ad-height + variables.$cookie-policy-height);
  }

  @media (max-width: variables.$bp-xs-max) {
    bottom: calc(variables.$torpedo-ad-height + variables.$cookie-policy-height-xs);
  }

  @media (max-width: variables.$bp-xxs-max) {
    bottom: calc(variables.$torpedo-ad-height + variables.$cookie-policy-height-xxs);
  }
}

//////////////////////////////////////////////////////////////////////////////////
// Above css are managing #Torpedo, which includes cookie policy and torpedo ad //
//////////////////////////////////////////////////////////////////////////////////