@use "variables";

#TrendsLandingPage {
    .border-light-grey{
        width: 100%;
    }
    .slide {
        display: flex;
        padding: 1.5rem;
        width: 100%;
    }
    .heroWrapper {
        order: 2;
        position: relative;
        flex-basis: 50%;
        width: 50%;
        margin-left: 1rem;
    }
    .heroInfoSide {
        order: 1;
        width: 50%;
        flex-basis: 50%;
    }
    .heroImg {
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    #Content {
        padding: 0 1.5rem;
    }
    @media(max-width: variables.$bp-xs-max){
        .slide {
            flex-direction: column;
            padding: 0;
        }
        .heroWrapper {
            order: 1;
            position: relative;
            width: 100%;
            margin: 0 0 1rem;
        }
        .heroInfoSide {
            order: 2;
            width: 100%;
            padding: 0 1rem;
        }
        .heroImg {
            img {
                max-height: 300px;
            }
        }
        #Content {
            padding: 0 1rem;
        }
    }
    #ArticleList {
        .articleTabImg {
            height: 4rem;
            width: 8rem;
        }
    }
}

.isIE {
    .heroWrapper, .heroInfoSide {
        @media(max-width: variables.$bp-xs-max){
            flex: 1 1 auto !important;
        }
    }
}
