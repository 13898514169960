@use "variables";

#MainFooter {
    text-align: center;
    padding-bottom: 7.5rem;
    position: relative;
    .main-container {
        flex-direction: column;
        align-items: center;
    }
    header {
        > * {
            display: inline-block;
            margin-bottom: 0;
        }
        .logo {
            height: 5rem;
            width: 15rem;
            margin-bottom: 1rem;
        }
        .logo.lazy-background.visible {
            background-image: variables.$footer-logo-url;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        .socialMedia .socialMediaIcon svg {
            color: variables.$primary-color-inverted;
            &:hover {
                color: variables.$action-btn-hover-bg;
            }
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        list-style: none;
        display: inline-block;
        margin: 0 1rem;
    }
    .facebookPages {
        li {
            color: variables.$text-muted-color;
        }
    }
    nav {
        margin: 2.5rem auto;
    }
    .labX {
        font-size: 0.85rem;
        > * {
            display: inline-block;
        }
        a {
            color: variables.$primary-color;
            margin: 0 4px;
        }
    }
    &.inverted .labX {
        a {
            color: variables.$primary-color-inverted;
        }
    }
    .copyRight {
        font-size: 0.85rem;
        width:100%;
        @media (max-width: variables.$bp-sm-max) {
            text-align: center;
            padding: 2rem 0 1rem;
        }
    }
}
