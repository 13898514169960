@use "variables";

#iframePage iframe {
    @media (min-width: variables.$bp-lg-min) {
        height: calc(100vh - variables.$header-height-top - variables.$header-height-bottom - variables.$header-outline-width - 0.3rem);
    }

    @media (max-width: variables.$bp-md-max) {
        height: calc(100vh - variables.$header-height-top - variables.$header-outline-width - 0.3rem);
    }
}