@use "variables";

.ArticleList.ArticleListCard {
    >.list {
        justify-content: space-between;
    }

    .ArticleSummary {
        width: calc(33.33333% - 0.5rem);
        flex-direction: column;
        background: variables.$primary-color;

        @media (max-width: variables.$bp-xxs-max) {
            width: 100%;
        }

        .thumbnail {
            .thumbnailWrapper {
                width: 100%;
                padding-bottom: 50%;
            }
        }

        section.text {
            flex: 1;
        }
    }
}

.ArticleSummary {
    display: flex;
    position: relative;
    transition: 0.5s;
    overflow: hidden;
    width: variables.$a-s-h-width;
    border-bottom: variables.$a-s-h-outline-style;
    border-width: variables.$a-s-h-outline-width;
    border-color: variables.$a-s-h-outline-color;
    border-radius: variables.$a-s-h-border-radius;
    background-color: variables.$a-s-h-bg;
    padding: variables.$a-s-h-padding;
    margin: variables.$a-s-h-margin;
    box-shadow: variables.$a-s-h-shadow;

    @media (max-width: variables.$bp-xxs-max) {
        flex-direction: column;
        padding-bottom: 0;
    }

    section.text a {
        color: variables.$secondary-color !important;
    }

    section.text .h3,
    section.text h3 {
        margin-top: 0;

        @media (max-width: variables.$bp-xxs-max) {
            margin-top: 1rem;
        }
    }

    section.text p {
        margin: 0;
    }

    .thumbnail {
        position: relative;
        flex-shrink: 0;
        display: flex;
        background: url("../assets/dot.png");
        background-image: image-set(url("../assets/dot.webp") type("image/webp"),
                url("../assets/dot.png") type("image/png"));
        border-width: variables.$a-s-h-outline-width;
        border-color: variables.$a-s-h-outline-color;
        border-style: variables.$a-s-h-outline-style;
    }

    .thumbnailWrapper {
        transition: 0.5s;
        width: variables.$a-s-h-img-width;
        padding-bottom: variables.$thumbnail-img-ratio;
        position: relative;
        margin: 0;
        background-color: variables.$missing-content-color;

        span {
            margin-bottom: -1px;
        }

        .article-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        picture {
            position: relative;
            height: 100%;
            width: 100%;
            display: inline-block;
            overflow: hidden;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 500ms ease-in;
            overflow: hidden;
        }

        @media (max-width: variables.$bp-xs-max) {
            width: 0.67*variables.$a-s-h-img-width;
            padding-bottom: variables.$feature-img-ratio;
        }

        @media (max-width: variables.$bp-xxs-max) {
            width: 100%;

            .article-summary-label {
                bottom: variables.$a-s-h-label-bottom-offset;
            }
        }
    }

    .text {
        padding: variables.$a-s-h-text-padding;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        @media (max-width: variables.$bp-xxs-max) {
            padding: 1rem 0;

            &.showContentTypeLabel {
                padding-top: 1.5rem;
            }
        }
    }

    .TagList {
        overflow: hidden;
        display: inline-block;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        bottom: 0.5rem;
        position: absolute;
        width: calc(100% - 3rem);
    }
}

.ArticleSummaryHeader h5,
.ArticleSummaryHeader h2 {
    font-family: variables.$primary-font;
    color: #505050;
    font-weight: variables.$bold-font-weight;
    font-size: variables.$heading3-font-size;
    margin: 0 0 0.75rem;

    @media (max-width: variables.$bp-md-min) {
        font-size: variables.$heading5-font-size;
    }
}

.ArticleSummaryFooter {
    margin-top: auto;

    .event-date-container {
        font-weight: 700;
        color: #505050;
        margin-right: 5px;
    }

    &:empty {
        display: none;
    }

    @media (max-width: variables.$bp-xs-max) {
        margin-top: 1rem;
    }
}