@use "../../sass/variables";

.SearchPageBar {
  display: flex;
  padding: 1.5rem 0;
  min-width: 100%;
  .searchbar-container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }
  .lm-searchform {
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .search-fields {
      display: flex;
      width: 90%;
      margin-right: 1rem;
      align-items: center;
      .input-wrapper {
        border-bottom: 3px solid variables.$primary-color;
        flex: 1;
        input[type="text"] {
          width: 90%;
          border: none;
          padding: 0.5rem 1rem;
        }
      }
      input[type="submit"] {
        margin-left: 1rem;
      }
      .btn {
        padding: 0.5rem 1rem;
      }
    }
  }
  .search-filters {
    display: flex;
    white-space: nowrap;
    justify-content: flex-end;
    margin: 0rem;
    h5 {
      margin: 0rem;
    }
    .filter,
    .dropdown-filter {
      position: relative;
      margin-bottom: 0;
      margin-right: 0;
      padding-bottom: 10px;
      cursor: pointer;
    }
    .selected {
      border-bottom: 3px solid variables.$primary-color;
    }
    .dropdown-filter {
      padding: 0.5rem;
      border: 1px solid variables.$primary-color;
      color: variables.$primary-color;
      display: flex;
    }
    .dropdown-options {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      padding: 0;
      z-index: 99;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .dropdown-item {
        width: 100%;
        font-size: 0.85rem;
        font-weight: 400;
        border: variables.$section-outline;
        padding: 0.5rem;
        &:hover {
          color: #fff;
          background-color: variables.$primary-color;
        }
      }
    }
    .dropdown-arrow {
      margin-left: 5px;
    }
  }
  @media (max-width: variables.$bp-xs-max) {
    .lm-searchform {
      flex-direction: column;
      align-items: flex-end;
      .search-filters {
        margin-top: 1rem;
      }
      .search-fields {
        width: 100%;
      }
    }
  }
}
