@use "variables";

.asset-info {
    font-size: variables.$small-font-size;
    line-height: 1.4;
    position: relative;
    width: 100%;
    padding: 0.5rem 1rem;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #f2f2f2;
    color: variables.$text-muted-color;
    background-color: transparent;
    margin-bottom: 0;
    &>* {
        display: inline;
        margin-right: 0.5rem;
        font-style: italic;
    }
    &>:last-child {
        margin: 0;
    }
    .asset-reference {
        color: variables.$text-muted-color;
        text-transform: uppercase;
        font-style: normal;
        a {
            color: variables.$link-color;
        }
        strong {
            color: variables.$summary-color;
        }
    }
    &.asset-info-hero {
        max-width: 300px;
    }
    &.asset-info-hero-hImg {
        display: block;
        @media (max-width: variables.$bp-sm-max) and (min-width: variables.$bp-sm-min) {
            border-width: 0;
        }
        @media (max-width: variables.$bp-sm-min) {
            display: none;
        }
    }
    &.asset-info-hero-aImg {
        display: none;
        img {
            width: 100%;
        }
        @media (max-width: variables.$bp-xs-max) {
            display: block;
            border-width: 0;
        }
    }
    .show-in-mobile {
        display: none;
        @media (max-width: variables.$bp-sm-max) {
            display: block;
        }
    }
}
