.AuthorViewPage #Content {
  width: 100%;
}
.AuthorViewPage h1.h2.title {
  border-bottom: 0;
}
.AuthorViewPage h1.h2.title:after {
  display: none;
}
.AuthorViewPage .ArticleList {
  padding-top: 0 !important;
}
.AuthorViewPage .ArticleList .title {
  font-size: 1.5rem;
  border-top: 0;
  margin-top: 1rem;
}
.AuthorViewPage .author-info {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
}
.AuthorViewPage .author-info .title {
  font-weight: initial;
  font-size: 1.25rem;
  margin: 0;
  text-decoration: none;
  border: none;
}
.AuthorViewPage .author-info img.avatar {
  padding: 0;
  margin-right: 1rem;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover !important;
}
.AuthorViewPage .author-info p {
  font-size: 0.8rem;
  color: #929292;
  padding: 0;
  margin: 0 0.25rem 0 0;
  letter-spacing: 0.5px;
}
.AuthorViewPage .ArticleSummary .author {
  border: none;
  padding: 0.5rem 0 0;
}
.AuthorViewPage .author-content {
  width: 100%;
}

#DigitalForumLandingPage {
  width: 100%;
  margin: 0 0 1rem;
}
#DigitalForumLandingPage > * {
  padding: 0 2rem 0 0;
}
@media (max-width: 1023px) {
  #DigitalForumLandingPage {
    margin-bottom: 0;
  }
  #DigitalForumLandingPage > * {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  #iframePage iframe {
    height: calc(100vh - 4rem - 2rem - 0.3rem - 0.3rem);
  }
}
@media (max-width: 1199px) {
  #iframePage iframe {
    height: calc(100vh - 4rem - 0.3rem - 0.3rem);
  }
}
@charset "UTF-8";
@font-face {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_normal-300.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_normal-300.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_normal-400.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_normal-400.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_normal-500.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_normal-500.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_normal-600.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_normal-600.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_italic-300.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-300.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_italic-400.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-400.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_italic-500.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-500.woff") format("woff");
}
@font-face {
  font-family: "futura-pt";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/FuturaPT/Futura_Pt_italic-600.woff2") format("woff2"), url("../fonts/FuturaPT/Futura_Pt_italic-600.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_normal-300.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-300.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_normal-400.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-400.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_normal-600.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-600.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_normal-700.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_normal-700.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_italic-300.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-300.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_italic-400.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-400.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_italic-600.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-600.woff") format("woff");
}
@font-face {
  font-family: "open-sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/OpenSans/Open_Sans_italic-700.woff2") format("woff2"), url("../fonts/OpenSans/Open_Sans_italic-700.woff") format("woff");
}
[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-slide h2, .slick-slide h3 {
  min-height: 50px;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/*********************************************************************************************
   Typography - General global typography
**********************************************************************************************/
h1, .h1 {
  font-family: "futura-pt", sans-serif;
  color: #505050;
  font-weight: 900;
  font-size: 2rem;
  line-height: 1.2;
  margin: 1.5rem 0 1rem;
}
h1 > p, .h1 > p {
  font-size: inherit;
  margin: 0;
}

h2, .h2 {
  font-family: "futura-pt", sans-serif;
  color: #505050;
  font-weight: 400;
  font-size: 1.5rem;
  margin: 1rem 0;
}
h2 > p, .h2 > p {
  font-size: inherit;
  margin: 0;
}

h2.larger, .h2.larger {
  font-size: 1.7rem;
}

h2.smaller, .h2.smaller {
  font-size: 1.3rem;
}

h3, .h3 {
  font-family: "futura-pt", sans-serif;
  color: #505050;
  font-weight: 900;
  font-size: 1.1rem;
  margin: 0.75rem 0;
}
h3 > p, .h3 > p {
  font-size: inherit;
  margin: 0;
}

h3.larger, .h3.larger {
  font-size: 1.125rem;
}

h3.smaller, .h3.smaller {
  font-weight: 400;
  font-size: 0.8rem;
  margin: 1rem 0;
}
h3.smaller > p, .h3.smaller > p {
  font-size: inherit;
  margin: 0;
}

h4, .h4 {
  color: #505050;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  margin: 0.75rem 0;
}
h4 > p, .h4 > p {
  font-size: inherit;
  margin: 0;
}

h5, .h5 {
  font-family: "futura-pt", sans-serif;
  color: #505050;
  font-size: 1rem;
  font-weight: 400;
  margin: 0.375rem 0;
}
h5 > p, .h5 > p {
  font-size: inherit;
  margin: 0;
}

h6, .h6 {
  font-family: "futura-pt", sans-serif;
  font-size: 1rem;
  color: #929292;
  font-weight: 300;
  margin: 0.75rem 0;
}
h6 > p, .h6 > p {
  font-size: inherit;
  margin: 0;
}

.p {
  margin: 1rem 0;
}

.title, .subTitle {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: baseline;
  color: #505050;
  width: 100%;
  padding: 0 0 0.25rem;
  margin: 1.5rem 0 0.5rem;
  border-bottom: 1px solid #505050;
}
.title > *, .subTitle > * {
  margin: 0;
}

time {
  padding: 0;
  margin: 0;
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 300;
  color: #929292;
  letter-spacing: 0.5px;
}

@media (max-width: 799px) {
  h1, .h1 {
    font-size: 1.602rem;
  }
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  font-size: 100%;
  font-weight: initial;
  overflow-x: clip;
  line-height: 1.2;
  -webkit-font-smoothing: auto;
  scroll-behavior: smooth;
}
html.fixed {
  overflow: clip;
}

html,
body {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-text-size-adjust: none;
}

body {
  font-size: 1rem;
  font-family: "futura-pt", sans-serif;
  background: #ffffff;
  color: #555555;
  line-height: 1.2;
}
body > div {
  position: relative;
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

#MainContainer {
  width: 100%;
  margin: 0 auto;
  max-width: 100vw;
}
@media (min-width: 1200px) {
  #MainContainer {
    padding-top: 6.3rem;
  }
}
@media (max-width: 1199px) {
  #MainContainer {
    padding-top: 4.3rem;
  }
}
#MainContainer button[disabled] {
  pointer-events: none;
}

.infographic-layout #MainContainer {
  padding-top: 4rem;
}

#MainHeader:not(.fixed) + #MainContainer {
  padding-top: 0px;
}

#MainContent {
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 2rem;
}
#MainContent[class*=TagPage] #Content {
  overflow-x: hidden;
}
#MainContent #Content {
  width: 100%;
  align-self: stretch;
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
}
#MainContent #Content > * {
  flex: 1 1 auto;
  padding: 0 2rem 0 0;
}
#MainContent #Content > main {
  width: 100%;
}
#MainContent #Content #Homepage {
  margin-top: 0;
}
#MainContent #Content.noSidebar .articleWrapper {
  max-width: 100%;
}
@media (max-width: 1023px) {
  #MainContent {
    flex-wrap: wrap;
  }
  #MainContent #Content {
    margin-bottom: 0;
  }
  #MainContent #Content > * {
    padding: 0;
  }
  #MainContent #Content #ArticleList {
    margin-top: 0;
  }
}

#Homepage .first {
  border: none;
}
#Homepage .first section.articleGroup > header h2 {
  border-top: none;
}

.tag-group {
  width: 100%;
  border: #cccccc 1px solid;
  border-radius: 4px;
  margin: 2em 0;
  padding: 1rem;
}
.tag-group ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tag-group ul li {
  width: 33%;
  padding: 0.3em 0;
  display: flex;
  align-items: center;
}
@media (max-width: 567px) {
  .tag-group ul li {
    width: 50%;
  }
}
.tag-group a {
  color: #34c5f1;
}
.tag-group a:before {
  content: "#";
}
.tag-group a:hover {
  text-decoration: underline;
}

/**************/
/* Formatting */
/**************/
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  opacity: 1 !important;
}

.preload svg {
  display: none !important;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

a > h1:hover,
a > .h1:hover,
a > h3:hover,
a > .h3:hover,
a > p:hover {
  text-decoration: underline;
}

a:hover + .h1,
a:hover + .h3 {
  text-decoration: underline;
}

a.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

a:focus {
  outline: 4px auto #34c5f1;
  outline-offset: -1px;
}

a.underline {
  text-decoration: underline;
}

/* Safely "Hide" for all but Screen Readers */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/***********/
/* Helpers */
/***********/
.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background-primary {
  background-color: #34c5f1 !important;
}

.background-secondary {
  background-color: #515254 !important;
}

.background-white {
  background-color: #fff !important;
}

.background-transparent {
  background-color: transparent !important;
}

.bold {
  font-weight: bold !important;
}

.bolder {
  font-weight: 900 !important;
}

hr.dotted {
  border: 1px dotted #eee;
  margin: 0;
}

.border-light-grey {
  border: 1px solid #e5e5e5;
}

.bottom-border {
  border-bottom: 1px solid !important;
}

.bp-sponsor {
  margin: 2rem auto;
  width: 100%;
}
.bp-sponsor p {
  margin: 0;
}
.bp-sponsor .sponsor-img {
  max-width: fit-content;
  max-height: 2.75rem;
  height: 100%;
  width: auto;
}

.capitalize {
  text-transform: capitalize !important;
}

.cover {
  width: 100%;
  height: 100%;
}

.card {
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
}

.category-group {
  border-radius: 6px;
  margin: 1rem auto;
  padding: 1rem 2rem;
}

.constrained-content {
  max-width: 50rem;
  margin: 0 auto;
}

.article-summary-label {
  position: absolute;
  z-index: 10;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-weight: 600;
  background-color: #fff;
  padding: 0.4rem 1rem;
  color: #5973b8;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  border: 1px solid;
}

a .article-summary-label:hover {
  text-decoration: underline;
}

.columns-two {
  columns: 2;
}
@media (max-width: 799px) {
  .columns-two {
    columns: 1;
  }
}

.columns-three {
  columns: 3;
}
@media (max-width: 799px) {
  .columns-three {
    columns: 1;
  }
}

.columns-four {
  columns: 4;
}
@media (max-width: 799px) {
  .columns-four {
    columns: 1;
  }
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-around {
  align-items: space-around;
}

.flex-align-between {
  align-items: space-between;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-center {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.grid {
  display: grid;
}
@media (max-width: 799px) {
  .grid {
    display: block;
  }
}

.grid-template-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1023px) {
  .grid-template-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.grid-template-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 1023px) {
  .grid-template-columns-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.grid-template-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-gap-default {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
@media (max-width: 799px) {
  .grid-gap-default {
    margin: 1rem;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.font-primary {
  font-family: "futura-pt", sans-serif;
}

.font-secondary {
  font-family: "open-sans", sans-serif;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-bleed {
  width: 100% !important;
}
@media (max-width: 799px) {
  .full-bleed {
    margin-left: auto;
  }
}

.full-bleed > * {
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
}

.hero-header-credit {
  padding: 0.5rem;
  color: #222;
  text-transform: uppercase;
  font-size: 0.75rem;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.hero-header-credit span.white {
  padding: 0.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
}
.hero-header-credit span.black {
  padding: 0.5rem;
  color: #222;
  background: rgba(255, 255, 255, 0.6);
}
.hero-header-credit a {
  color: #5973b8;
}
.hero-header-credit a:hover {
  text-decoration: underline;
}

.hide {
  visibility: hidden !important;
}

.display-none {
  display: none;
}

.icon-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.alt-brand-btn {
  background: #5973b8;
  border: 1px solid #4a90e2;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  color: #fff;
  display: inline-block;
  height: 40px;
  width: 250px;
  text-align: center;
  margin-top: 1.5rem;
}
@media (max-width: 800px) {
  .alt-brand-btn {
    display: block;
    margin: 1.5rem auto 0;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.invalid-feedback {
  display: block !important;
  color: #fff;
  padding: 1rem;
  background-color: #dc3545;
}

.input-btn-group {
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  max-width: 30rem;
}
.input-btn-group :first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.input-btn-group :last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-btn-group input {
  border: 1px #cccccc solid;
  padding: 8px 12px;
  text-overflow: ellipsis;
  width: 100%;
}
.input-btn-group button {
  font-size: 1rem;
  padding: 8px 12px;
  background-color: #34c5f1;
  color: #fff;
  flex-shrink: 0;
}
.input-btn-group .input-group-icon {
  position: absolute;
  top: 4px;
  left: 8px;
  font-size: 20px;
}

.input-btn-group.with-icon input {
  padding-left: 40px;
}

.italic {
  font-style: italic;
}

.generic-box {
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
  margin-bottom: 1rem;
  transition: 0.5s;
  padding: 1rem;
  text-align: left;
}
@media (max-width: 799px) {
  .generic-box {
    text-align: center;
  }
}
.generic-box.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 799px) {
  .generic-box.flex {
    flex-direction: column;
    align-items: center;
  }
}
.generic-box.flex aside {
  flex: 1 1 auto;
}
.generic-box .link-list {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}
.generic-box .link-list a {
  color: #34c5f1;
}
.generic-box .link-list a:hover {
  text-decoration: underline;
}

.gradient-diagonal {
  background: #515254;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #515254 0%, #34c5f1 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #515254 0%, #34c5f1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #515254 0%, #34c5f1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-horizontal {
  background: #515254;
  /* Old browsers */
  background: -moz-linear-gradient(left, #515254 0%, #34c5f1 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #515254 0%, #34c5f1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #515254 0%, #34c5f1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical {
  background: #515254;
  /* Old browsers */
  background: -moz-linear-gradient(top, #515254 0%, #34c5f1 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #515254 0%, #34c5f1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #515254 0%, #34c5f1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical-reverse {
  background: #515254;
  /* Old browsers */
  background: -moz-linear-gradient(top, #34c5f1 0%, #515254 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #34c5f1 0%, #515254 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #34c5f1 0%, #515254 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical-light {
  background: #4f7eb1;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4f7eb1 0%, #79caf0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4f7eb1 0%, #79caf0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4f7eb1 0%, #79caf0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.gradient-vertical-leaderboard {
  background: #ddd;
  /* Old browsers */
  background: -moz-linear-gradient(bottom, #ddd 0%, #fff 50%, #ddd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, #ddd 0%, #fff 50%, #ddd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, #ddd 0%, #fff 50%, #ddd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.header-color {
  color: #505050;
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0;
}

.listed-items > a:after {
  content: ",";
  margin-right: 0.4rem;
}
.listed-items > a:last-child:after {
  content: "";
}

.main-container {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  padding: 0 1rem;
  max-width: 1440px;
}
.main-container.relative {
  position: relative;
}
@media (max-width: 799px) {
  .main-container {
    width: 100%;
  }
}

.no-border {
  border: none !important;
}

.no-background {
  background: none !important;
}

.no-wrap-text {
  white-space: nowrap;
  display: inline-block;
}

.no-hover-underline:hover {
  text-decoration: none !important;
}

.pull-right {
  position: absolute;
  right: 0;
}

.pull-left {
  position: absolute;
  left: 0;
}

.section {
  box-shadow: none;
  border: 1px solid #e5e5e5;
  margin: 1.5rem 0;
  position: relative;
  z-index: 1;
}

.section#MainFooter {
  margin: 0;
}

.section-padding {
  padding: 3.5rem 0;
}

.section.inverted {
  background: #555555;
  color: #ffffff;
}

.section + .section {
  border-top: none;
}

.section-overflow {
  width: 100%;
  background-color: #bbb;
  margin: 5.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  max-height: 240px;
}
@media (max-width: 567px) {
  .section-overflow {
    height: auto;
    max-height: none;
  }
}
.section-overflow .section-container {
  width: 90vw;
  max-width: 1366px;
  margin: -2rem 0;
  border: 4px solid #aaa;
  border-radius: 6px;
  overflow: hidden;
  background-color: #34c5f1;
  color: #fff;
  height: calc(100% + 4rem);
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
@media (max-width: 567px) {
  .section-overflow .section-container {
    flex-direction: column;
    text-align: center;
  }
}
.section-overflow .section-container h1,
.section-overflow .section-container .h1 {
  color: inherit;
  margin: 0.5rem 0;
}
.section-overflow .section-container p {
  margin: 0;
}
.section-overflow .section-container .action-btn {
  margin-top: 1rem;
}
.section-overflow .section-container.secondary {
  color: #34c5f1;
  border: 4px solid #ccc;
}
.section-overflow .section-container .left {
  margin-right: 2rem;
}
@media (max-width: 567px) {
  .section-overflow .section-container .left {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
@media (max-width: 567px) {
  .section-overflow .section-container .right {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
.section-overflow .overflow-image {
  height: 100%;
  overflow: visible;
}
@media (max-width: 567px) {
  .section-overflow .overflow-image {
    height: 180px;
    max-height: 180px;
  }
}
.section-overflow .overflow-image img {
  background-color: #fff;
  height: calc(100% + 5rem);
  margin: -3rem auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.section-overflow .overflow-image img.default-image {
  background: none;
  box-shadow: none;
}

.sponsoredBy {
  padding-top: 0.5rem;
  color: #777;
  font-size: 0.875rem;
}

.sponsorName {
  text-transform: uppercase;
  color: #34c5f1;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.text-primary {
  color: #34c5f1 !important;
}

.text-secondary {
  color: #515254 !important;
}

.text-tertiary {
  color: #5973b8 !important;
}

.text-shadow {
  text-shadow: none !important;
}

.text-white {
  color: #fff !important;
}

.text-link {
  color: #5973b8 !important;
}

.text-inverted {
  color: #ffffff !important;
}

.text-placeholder {
  color: #999;
}

.text-with-space {
  margin-left: 0.4rem !important;
  margin-right: 0.4rem !important;
}

.text-with-space-left {
  margin-left: 0.4rem !important;
}

.text-with-space-right {
  margin-right: 0.4rem !important;
}

.text-with-more-space {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.text-with-more-space-left {
  margin-left: 1rem !important;
}

.text-with-more-space-right {
  margin-right: 1rem !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase !important;
}

.underline {
  text-decoration: underline !important;
}

.m-t-0 {
  margin-top: 0rem;
}

.m-b-0 {
  margin-bottom: 0rem;
}

.m-l-0 {
  margin-left: 0rem;
}

.m-r-0 {
  margin-right: 0rem;
}

.m-v-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.m-h-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.p-b-0 {
  padding-bottom: 0rem;
}

.p-v-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.p-h-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.p-0 {
  padding: 0rem;
}

.m-0 {
  margin: 0rem;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-b-1 {
  margin-bottom: 1rem;
}

.m-l-1 {
  margin-left: 1rem;
}

.m-r-1 {
  margin-right: 1rem;
}

.m-v-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-h-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.p-b-1 {
  padding-bottom: 1rem;
}

.p-v-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-h-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-1 {
  padding: 1rem;
}

.m-1 {
  margin: 1rem;
}

.m-t-2 {
  margin-top: 2rem;
}

.m-b-2 {
  margin-bottom: 2rem;
}

.m-l-2 {
  margin-left: 2rem;
}

.m-r-2 {
  margin-right: 2rem;
}

.m-v-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-h-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.p-b-2 {
  padding-bottom: 2rem;
}

.p-v-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-h-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.p-2 {
  padding: 2rem;
}

.m-2 {
  margin: 2rem;
}

.m-t-3 {
  margin-top: 3rem;
}

.m-b-3 {
  margin-bottom: 3rem;
}

.m-l-3 {
  margin-left: 3rem;
}

.m-r-3 {
  margin-right: 3rem;
}

.m-v-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-h-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.p-b-3 {
  padding-bottom: 3rem;
}

.p-v-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-h-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-3 {
  padding: 3rem;
}

.m-3 {
  margin: 3rem;
}

.m-t-4 {
  margin-top: 4rem;
}

.m-b-4 {
  margin-bottom: 4rem;
}

.m-l-4 {
  margin-left: 4rem;
}

.m-r-4 {
  margin-right: 4rem;
}

.m-v-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.m-h-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.p-b-4 {
  padding-bottom: 4rem;
}

.p-v-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-h-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.p-4 {
  padding: 4rem;
}

.m-4 {
  margin: 4rem;
}

.m-t-5 {
  margin-top: 5rem;
}

.m-b-5 {
  margin-bottom: 5rem;
}

.m-l-5 {
  margin-left: 5rem;
}

.m-r-5 {
  margin-right: 5rem;
}

.m-v-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.m-h-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.p-b-5 {
  padding-bottom: 5rem;
}

.p-v-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-h-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.p-5 {
  padding: 5rem;
}

.m-5 {
  margin: 5rem;
}

.m-t-6 {
  margin-top: 6rem;
}

.m-b-6 {
  margin-bottom: 6rem;
}

.m-l-6 {
  margin-left: 6rem;
}

.m-r-6 {
  margin-right: 6rem;
}

.m-v-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.m-h-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.p-b-6 {
  padding-bottom: 6rem;
}

.p-v-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-h-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.p-6 {
  padding: 6rem;
}

.m-6 {
  margin: 6rem;
}

.m-h-a {
  margin-left: auto;
  margin-right: auto;
}

.m-l-a {
  margin-left: auto;
}

.l-h-1 {
  line-height: 1.15;
}

.l-h-3 {
  line-height: 1.3;
}

.l-h-5 {
  line-height: 1.5;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

/* Browswer Specific */
.isChrome .max-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.isChrome .max-2-lines > * {
  overflow: hidden;
  margin: 0;
}
.isChrome .max-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isChrome .max-3-lines > * {
  overflow: hidden;
  margin: 0;
}
.isChrome .max-4-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.isChrome .max-4-lines > * {
  overflow: hidden;
  margin: 0;
}

.notChrome .max-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.15;
  max-height: 2.4em;
  padding: 1px 0;
}
.notChrome .max-2-lines > * {
  overflow: hidden;
  margin: 0;
}
.notChrome .max-2-lines.l-h-3 {
  line-height: 1.3;
  max-height: 2.7em;
}
.notChrome .max-2-lines.l-h-5 {
  line-height: 1.5;
  max-height: 3em;
}
.notChrome .max-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.15;
  max-height: 3.6em;
  padding: 1px 0;
}
.notChrome .max-3-lines > * {
  overflow: hidden;
  margin: 0;
}
.notChrome .max-3-lines.l-h-3 {
  line-height: 1.3;
  max-height: 4em;
}
.notChrome .max-3-lines.l-h-5 {
  line-height: 1.5;
  max-height: 4.5em;
}
.notChrome .max-4-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.15;
  max-height: 4.8em;
  padding: 1px 0;
}
.notChrome .max-4-lines > * {
  overflow: hidden;
  margin: 0;
}
.notChrome .max-4-lines.l-h-3 {
  line-height: 1.3;
  max-height: 5.3em;
}
.notChrome .max-4-lines.l-h-5 {
  line-height: 1.5;
  max-height: 6em;
}

p:empty {
  display: none !important;
}

.article-img:before,
.article-tab-img:before,
#FeaturedGrid .image:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 500ms ease-in-out;
}
.article-img:hover,
.article-tab-img:hover,
#FeaturedGrid .image:hover {
  display: block;
  width: 100%;
  height: 100%;
}
.article-img:hover img,
.article-img:hover source,
.article-tab-img:hover img,
.article-tab-img:hover source,
#FeaturedGrid .image:hover img,
#FeaturedGrid .image:hover source {
  transform: scale(1.025);
}
.article-img img,
.article-img source,
.article-tab-img img,
.article-tab-img source,
#FeaturedGrid .image img,
#FeaturedGrid .image source {
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 500ms, filter 200ms ease-in-out;
}

.share-widget {
  margin-top: 1rem;
}
.share-widget > span {
  font-size: 0.875rem;
}
.share-widget .a2a_svg {
  margin-top: 0.3rem;
}

#Torpedo {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3);
}
#Torpedo .advertisement {
  background: #ffffff;
  margin: 0;
  padding: 0;
}
#Torpedo .advertisement[data-zone*=TORPEDO] > div {
  padding: 0;
  background: none;
  border: none !important;
}

#app:not(.cookie-policy) div[id^=hs-web][id*=bottom],
#app:not(.cookie-policy) div[class^=leadinModal][class*=bottom] .leadinModal-content {
  bottom: 4.3rem;
}

@media (min-width: 800px) {
  #app.cookie-policy div[id^=hs-web][id*=bottom],
  #app.cookie-policy div[class^=leadinModal][class*=bottom] .leadinModal-content {
    bottom: 7.8rem;
  }
}
@media (max-width: 799px) {
  #app.cookie-policy div[id^=hs-web][id*=bottom],
  #app.cookie-policy div[class^=leadinModal][class*=bottom] .leadinModal-content {
    bottom: 9.3rem;
  }
}
@media (max-width: 567px) {
  #app.cookie-policy div[id^=hs-web][id*=bottom],
  #app.cookie-policy div[class^=leadinModal][class*=bottom] .leadinModal-content {
    bottom: 10.8rem;
  }
}

body.showPreHeader {
  margin-top: 300px;
}
@media (max-width: 1023px) {
  body.showPreHeader {
    margin-top: 135px;
  }
}

#preHeader {
  width: 100%;
  height: 300px;
  position: fixed;
  top: 0;
  z-index: -1;
}
#preHeader + #MainHeader + #MainContainer {
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}
#preHeader .section,
#preHeader section {
  margin: 0;
}
#preHeader .section.advertisement,
#preHeader section.advertisement {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12), inset 0px 4px 17px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1023px) {
  #preHeader {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    height: 135px;
  }
}

#MainHeader {
  background-color: #ffffff;
  box-shadow: 0px 4px 7px rgba(52, 197, 241, 0.2), 0px -2px 8px -1px rgba(0, 0, 0, 0.1);
  border-width: 0.3rem;
  border-style: none none solid none;
  border-color: #34c5f1;
  position: relative;
  z-index: 3;
  height: 6.3rem;
}
#MainHeader.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin: 0 !important;
}
#MainHeader.collapse {
  height: 0 !important;
  transition: height 0.5s;
}
#MainHeader.collapse *, #MainHeader.collapse > * {
  display: none !important;
}
#MainHeader #MainHeaderContent {
  width: 90vw;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 4rem 2rem;
}
#MainHeader #brand {
  grid-column: 1/1;
  grid-row: 1/3;
  display: inline-block;
  background-image: url("../assets/main_logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  margin: 0.5rem 0;
}
#MainHeader #HeaderTop {
  grid-column: 2/7;
  grid-row: 1/1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5rem 0;
}
#MainHeader #MenusMobile {
  grid-column: 2/7;
  grid-row: 2/2;
  display: none;
}
#MainHeader #MenusDesktop {
  grid-column: 2/7;
  grid-row: 2/2;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
  /** Handle dropdown-content */
  /** Handle single column dropdown content layout */
  /** Handle multi column dropdown content layout */
}
#MainHeader #MenusDesktop .dropdown {
  position: relative;
  display: inline-block;
}
#MainHeader #MenusDesktop .dropdown .navItem,
#MainHeader #MenusDesktop .dropdown .subNavItem {
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
}
#MainHeader #MenusDesktop .dropdown .navItem {
  display: block;
}
#MainHeader #MenusDesktop .dropdown .navItem:hover {
  color: #34c5f1;
}
#MainHeader #MenusDesktop .dropdown .navItem.inverted {
  color: #ffffff;
  background-color: #5973b8;
  box-shadow: none;
}
#MainHeader #MenusDesktop .dropdown:not(.static):hover > .navItem {
  background-color: transparent;
  color: #34c5f1;
}
#MainHeader #MenusDesktop .dropdown:not(.static):hover > .navItem.inverted {
  background-color: #34c5f1;
  color: #fff;
}
#MainHeader #MenusDesktop .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  border: 2px solid #34c5f1;
}
#MainHeader #MenusDesktop .dropdown-content .subNavItem {
  display: flex;
  flex-basis: 2.2rem;
  padding: 0 1rem;
  align-items: center;
  background-color: #fff;
}
#MainHeader #MenusDesktop .dropdown-content .subNavItem:hover {
  color: #ffffff;
  background-color: #34c5f1;
}
#MainHeader #MenusDesktop .dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}
#MainHeader #MenusDesktop .dropdown:hover .dropdown-content > * {
  flex-basis: 2.2rem;
}
#MainHeader #MenusDesktop .dropdown:hover .dropdown-content.multi-col {
  display: grid;
  grid-template-rows: repeat(6, 2.2rem);
  grid-auto-flow: column;
}
#MainHeader #MenusDesktop .dropdown:last-of-type .dropdown-content {
  right: 0;
}
@media (max-width: 1500px) {
  #MainHeader #MainHeaderContent {
    grid-template-columns: 1fr 1fr;
  }
  #MainHeader #brand {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  #MainHeader #MenusDesktop {
    grid-column: 1/7;
    grid-row: 2/3;
    justify-content: space-between;
  }
}
@media (max-width: 1199px) {
  #MainHeader {
    height: 4.3rem;
  }
  #MainHeader #MainHeaderContent {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4rem;
  }
  #MainHeader #MenusMobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  #MainHeader #MenusDesktop {
    display: none;
  }
}
@media (max-width: 799px) {
  #MainHeader #MainHeaderContent {
    width: 100%;
  }
  #MainHeader #brand {
    grid-column: 1/4;
  }
  #MainHeader .InlineSearch,
  #MainHeader .subscribe-btn.action-btn {
    display: none;
  }
}

.ad {
  color: #fff;
  background-color: #333333;
  margin: 1em auto;
  width: 720px;
  max-width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.advertisement {
  text-align: center;
}
.advertisement > div {
  position: relative;
}
.advertisement > div::before {
  content: "Advertisement";
  color: #999999;
  padding: 0;
  font-size: 11px;
  position: absolute;
  bottom: -17px;
  right: 0;
  left: 0;
  text-align: center;
  text-transform: uppercase;
}
.advertisement[data-zone*=LEADERBOARD], .advertisement[data-zone*=BILLBOARD] {
  background-color: #f4f4f4;
  padding: 1rem 0;
  width: 100%;
}
.advertisement[data-zone*=LEADERBOARD] {
  height: 138px;
  width: 100%;
}
.advertisement[data-zone*=BILLBOARD] {
  height: 300px;
  width: 100%;
  padding: 1rem 0;
}
.advertisement[data-zone*=MEDIUM-RECTANGLE], .advertisement[data-zone*=MEDIUM_RECTANGLE] {
  height: 270px;
}
.advertisement[data-zone*=TORPEDO], .advertisement[data-zone*=ANCHOR] {
  height: 4.3rem;
}
.advertisement[data-zone*=HALF] {
  height: 600px;
}
.advertisement .topLeaderboard-ad,
.advertisement .topLeaderboardBelowHero-ad,
.advertisement .bottomLeaderboard-ad {
  margin: 0;
}
@media (max-width: 1023px) {
  .advertisement .topLeaderboard-ad,
  .advertisement .topLeaderboardBelowHero-ad,
  .advertisement .bottomLeaderboard-ad {
    width: 100%;
    height: 85px;
    margin: 1rem auto;
  }
}
@media (max-width: 1023px) {
  .advertisement {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  .advertisement[data-zone*=LEADERBOARD] {
    height: 85px;
    width: 100%;
  }
  .advertisement[data-zone*=BILLBOARD] {
    height: 135px;
    width: 100%;
  }
}
@media (min-width: 568px) {
  .advertisement--sponsored-boombox {
    float: right;
    padding: 0 !important;
    width: 300px !important;
    height: 250px !important;
    margin: 0.75rem 0 1rem 2rem !important;
  }
}

.relocate-desktop-ad,
.relocate-mobile-ad {
  display: none;
}

.advertisement.preHeader-ad {
  margin: 0;
}

.ArticleList.ArticleListCard > .list {
  justify-content: space-between;
}
.ArticleList.ArticleListCard .ArticleSummary {
  width: calc(33.33333% - 0.5rem);
  flex-direction: column;
  background: #34c5f1;
}
@media (max-width: 567px) {
  .ArticleList.ArticleListCard .ArticleSummary {
    width: 100%;
  }
}
.ArticleList.ArticleListCard .ArticleSummary .thumbnail .thumbnailWrapper {
  width: 100%;
  padding-bottom: 50%;
}
.ArticleList.ArticleListCard .ArticleSummary section.text {
  flex: 1;
}

.ArticleSummary {
  display: flex;
  position: relative;
  transition: 0.5s;
  overflow: hidden;
  width: 100%;
  border-bottom: solid;
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
  padding: 0.5rem 0 1.5rem;
  margin: 0.5rem 0;
  box-shadow: none;
}
@media (max-width: 567px) {
  .ArticleSummary {
    flex-direction: column;
    padding-bottom: 0;
  }
}
.ArticleSummary section.text a {
  color: #515254 !important;
}
.ArticleSummary section.text .h3,
.ArticleSummary section.text h3 {
  margin-top: 0;
}
@media (max-width: 567px) {
  .ArticleSummary section.text .h3,
  .ArticleSummary section.text h3 {
    margin-top: 1rem;
  }
}
.ArticleSummary section.text p {
  margin: 0;
}
.ArticleSummary .thumbnail {
  position: relative;
  flex-shrink: 0;
  display: flex;
  background: url("../assets/dot.png");
  background-image: image-set(url("../assets/dot.webp") type("image/webp"), url("../assets/dot.png") type("image/png"));
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-style: solid;
}
.ArticleSummary .thumbnailWrapper {
  transition: 0.5s;
  width: 22.5rem;
  padding-bottom: 50%;
  position: relative;
  margin: 0;
  background-color: #333333;
}
.ArticleSummary .thumbnailWrapper span {
  margin-bottom: -1px;
}
.ArticleSummary .thumbnailWrapper .article-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ArticleSummary .thumbnailWrapper picture {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.ArticleSummary .thumbnailWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 500ms ease-in;
  overflow: hidden;
}
@media (max-width: 799px) {
  .ArticleSummary .thumbnailWrapper {
    width: 15.075rem;
    padding-bottom: 50%;
  }
}
@media (max-width: 567px) {
  .ArticleSummary .thumbnailWrapper {
    width: 100%;
  }
  .ArticleSummary .thumbnailWrapper .article-summary-label {
    bottom: -1.15rem;
  }
}
.ArticleSummary .text {
  padding: 0.25rem 1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 567px) {
  .ArticleSummary .text {
    padding: 1rem 0;
  }
  .ArticleSummary .text.showContentTypeLabel {
    padding-top: 1.5rem;
  }
}
.ArticleSummary .TagList {
  overflow: hidden;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  bottom: 0.5rem;
  position: absolute;
  width: calc(100% - 3rem);
}

.ArticleSummaryHeader h5,
.ArticleSummaryHeader h2 {
  font-family: "futura-pt", sans-serif;
  color: #505050;
  font-weight: 600;
  font-size: 1.424rem;
  margin: 0 0 0.75rem;
}
@media (max-width: 1024px) {
  .ArticleSummaryHeader h5,
  .ArticleSummaryHeader h2 {
    font-size: 1.125rem;
  }
}

.ArticleSummaryFooter {
  margin-top: auto;
}
.ArticleSummaryFooter .event-date-container {
  font-weight: 700;
  color: #505050;
  margin-right: 5px;
}
.ArticleSummaryFooter:empty {
  display: none;
}
@media (max-width: 799px) {
  .ArticleSummaryFooter {
    margin-top: 1rem;
  }
}

.TrendsSummary {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: calc(25% - 1rem);
  -ms-flex-preferred-size: calc(33.3333% - 1.5rem);
  max-width: calc(25% - 1.5rem);
  height: auto;
  padding: 0;
  margin: 0.5rem;
  border: 1px solid #e5e5e5;
}
@media (max-width: 1023px) {
  .TrendsSummary {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}
@media (max-width: 799px) {
  .TrendsSummary {
    flex-basis: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
  }
}
.TrendsSummary .thumbnail {
  position: relative;
  flex-shrink: 0;
  display: flex;
  background: url("../assets/dot.png");
  background-image: image-set(url("../assets/dot.webp") type("image/webp"), url("../assets/dot.png") type("image/png"));
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-style: solid;
}
.TrendsSummary .thumbnailWrapper {
  transition: 0.5s;
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  margin: 0;
  background-color: #333333;
}
.TrendsSummary .thumbnailWrapper .article-summary-label {
  transform: unset;
  left: 1rem;
  font-weight: normal;
}
.TrendsSummary .thumbnailWrapper .article-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.TrendsSummary .thumbnailWrapper picture {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.TrendsSummary .thumbnailWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 500ms ease-in;
  overflow: hidden;
}
.TrendsSummary .text {
  padding: 1rem;
}
.TrendsSummary .text .max-4-lines > * {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.TrendsSummary .footer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 1rem 1rem;
}
@media (max-width: 799px) {
  .TrendsSummary .footer {
    align-items: center;
    justify-content: center;
  }
}
.TrendsSummary .footer .bp-sponsor {
  width: fit-content;
  margin: 1rem 0 0;
}

.ArticleSummaryVertical {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.5s;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #999999;
  border-radius: 0;
  background-color: transparent;
  padding: 1.25rem 0;
  margin: 0;
  box-shadow: none;
  height: 100%;
}
.ArticleSummaryVertical .thumbnail {
  width: 100%;
  height: 50%;
  flex-basis: 50%;
  overflow: hidden;
}
.ArticleSummaryVertical.featured .thumbnail {
  flex-basis: 70%;
}
.ArticleSummaryVertical.featured .text {
  flex-basis: 30%;
}
.ArticleSummaryVertical.featured .text h2 {
  font-size: 1.424rem;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .ArticleSummaryVertical.featured .text h2 {
    font-size: 1.125rem;
  }
}
.ArticleSummaryVertical .thumbnail-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ArticleSummaryVertical .thumbnail-wrapper, .ArticleSummaryVertical .article-link, .ArticleSummaryVertical .article-img, .ArticleSummaryVertical picture, .ArticleSummaryVertical img {
  width: 100%;
  height: 100%;
  margin: 0;
}
.ArticleSummaryVertical img {
  object-fit: cover;
}
.ArticleSummaryVertical .text {
  flex-grow: 1;
  height: 50%;
  padding: 1rem 0 0;
}
.ArticleSummaryVertical .text header {
  display: flex;
  flex-direction: column;
}
.ArticleSummaryVertical .text h2 {
  margin-top: 0;
  font-weight: initial;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.1;
}
.ArticleSummaryVertical .text h6 {
  margin: 0;
}
.ArticleSummaryVertical .text main {
  margin: 0;
}
.ArticleSummaryVertical .text .summary {
  margin-top: 0;
}
.ArticleSummaryVertical .text footer {
  align-items: flex-end;
}
.ArticleSummaryVertical .text footer a {
  height: fit-content;
}
.ArticleSummaryVertical .text footer .sponsoredBy {
  flex-wrap: wrap;
  text-align: right;
}
.ArticleSummaryVertical .text footer .sponsoredBy span {
  margin-right: 5px;
}
@media (max-width: 567px) {
  .ArticleSummaryVertical .text footer .sponsoredBy {
    display: none;
  }
}
.ArticleSummaryVertical .text footer .logo {
  max-width: 180px;
  max-height: 100%;
  margin-left: 1rem;
}
.ArticleSummaryVertical .text footer .logo picture {
  display: flex;
}
.ArticleSummaryVertical .text footer .logo picture img {
  max-height: 30px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.isIE .ArticleSummaryVertical > .thumbnail, .isIE .ArticleSummaryVertical > .text {
  flex: 1 1 auto !important;
}
.isIE .ArticleSummaryVertical .thumbnail {
  max-height: 240px;
}
.isIE .ArticleSummaryVertical.featured > .thumbnail, .isIE .ArticleSummaryVertical.featured > .text {
  flex: 1 1 auto !important;
}
.isIE .ArticleSummaryVertical.featured .thumbnail {
  max-height: 350px;
}

#MainFooter {
  text-align: center;
  padding-bottom: 7.5rem;
  position: relative;
}
#MainFooter .main-container {
  flex-direction: column;
  align-items: center;
}
#MainFooter header > * {
  display: inline-block;
  margin-bottom: 0;
}
#MainFooter header .logo {
  height: 5rem;
  width: 15rem;
  margin-bottom: 1rem;
}
#MainFooter header .logo.lazy-background.visible {
  background-image: url("../assets/main_logo_light.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#MainFooter header .socialMedia .socialMediaIcon svg {
  color: #ffffff;
}
#MainFooter header .socialMedia .socialMediaIcon svg:hover {
  color: #dee3f1;
}
#MainFooter ul {
  list-style-type: none;
  padding: 0;
}
#MainFooter li {
  list-style: none;
  display: inline-block;
  margin: 0 1rem;
}
#MainFooter .facebookPages li {
  color: #929292;
}
#MainFooter nav {
  margin: 2.5rem auto;
}
#MainFooter .labX {
  font-size: 0.85rem;
}
#MainFooter .labX > * {
  display: inline-block;
}
#MainFooter .labX a {
  color: #34c5f1;
  margin: 0 4px;
}
#MainFooter.inverted .labX a {
  color: #ffffff;
}
#MainFooter .copyRight {
  font-size: 0.85rem;
  width: 100%;
}
@media (max-width: 1023px) {
  #MainFooter .copyRight {
    text-align: center;
    padding: 2rem 0 1rem;
  }
}

.missingImage {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
  background-color: #333333;
}
.missingImage svg {
  height: 3rem;
  width: auto;
}

.articleTab {
  display: flex;
  text-align: left;
  width: calc(50% - 1rem);
  overflow: hidden;
  align-items: center;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
  padding: 0 0 1rem 0;
  margin: 0 0.5rem 1rem;
  box-shadow: none;
}
@media (max-width: 799px) {
  .articleTab {
    margin: 0 0 1rem;
    width: 100%;
  }
}
.articleTab h3 {
  margin-top: 0;
  margin-bottom: 0.25rem;
}
.articleTab h3.max-2-lines {
  display: none;
}
.articleTab h3.max-4-lines {
  display: inline-block;
  display: -webkit-box;
}
.articleTab h3.with-summary.max-3-lines {
  display: none;
}
.articleTab h3.with-summary.max-2-lines {
  display: inline-block;
  display: -webkit-box;
}
.articleTab h3.with-summary {
  margin-bottom: 0;
}
@media (max-width: 567px) {
  .articleTab h3.with-summary.max-3-lines {
    display: inline-block;
    display: -webkit-box;
  }
  .articleTab h3.with-summary.max-2-lines {
    display: none;
  }
}
.articleTab .summary {
  margin-top: 2px;
}
.articleTab .summary > font,
.articleTab .summary > span {
  font-family: "futura-pt", sans-serif !important;
  color: #929292 !important;
  font-size: 0.85rem !important;
}
@media (max-width: 567px) {
  .articleTab .summary {
    display: none !important;
  }
}
.articleTab h6 {
  margin: 0;
}
.articleTab .articleTabImg {
  position: relative;
  height: 4rem;
  width: 4rem;
  flex-shrink: 0;
  overflow: hidden;
  background: url("../assets/dot.png");
  background-image: image-set(url("../assets/dot.webp") type("image/webp"), url("../assets/dot.png") type("image/png"));
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-style: solid;
}
.articleTab .articleTabImg img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.articleTab .articleTabImg a.articleImgLink {
  display: block;
  height: 100%;
}
.articleTab .articleTabText {
  padding: 2px 0.5rem 2px 1rem;
  font-style: normal;
}
.articleTab .articleTabText strong {
  font-weight: initial;
  font-style: normal;
}

.tabListAd {
  width: calc(33% - 1rem);
  background-color: #f4f4f4;
  padding: 1.5rem 0;
  border: 1px solid #e2e2e2 !important;
  align-self: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 799px) {
  .tabListAd {
    width: 100%;
  }
}
.articleTab.forMedia {
  display: block;
  width: calc(33% - 0.67rem);
  border-style: solid;
  border-width: 1px;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  margin: 0 1rem 0 0;
}
.articleTab.forMedia h3 {
  overflow: visible;
  display: block;
  -webkit-line-clamp: none;
  height: auto;
}
.articleTab.forMedia h3.with-summary {
  margin-bottom: 0;
}
.articleTab.forMedia p {
  margin-bottom: 1rem;
}
.articleTab.forMedia .articleTabText {
  padding: 1rem 1rem 0;
}
.articleTab.forMedia .articleTabImg {
  position: relative;
  padding-top: 50%;
  height: auto;
  overflow: visible;
  width: 100%;
  background: url("../assets/dot.png");
  background-image: image-set(url("../assets/dot.webp") type("image/webp"), url("../assets/dot.png") type("image/png"));
  border-bottom-width: 0 0 1px 0;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;
  border-right: none;
}
.articleTab.forMedia .articleTabImg a,
.articleTab.forMedia .articleTabImg span {
  margin-bottom: -1px;
}
.articleTab.forMedia .articleTabImg .article-tab-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
}
.articleTab.forMedia .articleTabImg img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.articleTab.forMedia .articleTabImg a.articleImgLink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (max-width: 567px) {
  .articleTab.forMedia .summary {
    display: -webkit-box !important;
  }
}
@media (min-width: 568px) and (max-width: 799px) {
  .articleTab.forMedia {
    width: calc(50% - 1rem);
  }
}
@media (max-width: 567px) {
  .articleTab.forMedia {
    width: calc(100% - 1rem);
    margin-bottom: 0.75rem;
  }
}

.articleTabList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: center;
}

#Sponsored {
  margin: 1rem auto 0;
}
#Sponsored h2 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 20px;
  border-bottom: none;
}
#Sponsored .articleTabList {
  justify-content: space-between;
}
@media (min-width: 568px) and (max-width: 799px) {
  #Sponsored .articleTabList {
    justify-content: center;
  }
  #Sponsored .articleTab {
    margin-bottom: 0.75rem;
  }
  #Sponsored .articleTab:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 567px) {
  #Sponsored .articleTab {
    margin-right: 0;
    width: 100%;
  }
  #Sponsored .articleTab:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 800px) {
  #Sponsored .articleTab:last-child {
    margin-right: 0;
  }
}

.articleGroup {
  margin: 1rem 0;
  width: 100%;
}
.articleGroup .group {
  display: grid;
  grid-template-columns: repeat(4, minmax(20%, 1fr));
  grid-template-rows: repeat(2, 275px);
  gap: 1rem;
  grid-gap: 1rem;
  margin-top: 0.5rem;
}
.articleGroup .group .feature {
  grid-column: 1/3;
  grid-row: 1/3;
  width: 100%;
  height: 100%;
}
.articleGroup .group .grid-ad {
  margin: 0 auto auto;
}
.articleGroup .group article {
  padding: 0;
  border: none;
}
.articleGroup .group article:nth-child(1) {
  border-right: 1px solid #e5e5e5;
  padding-right: 1rem;
}
.articleGroup .group article footer {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.5rem;
}
@media (max-width: 1024px) {
  .articleGroup .group {
    grid-template-columns: repeat(2, minmax(45%, 1fr));
    grid-template-rows: none;
    gap: 1.5rem;
    grid-gap: 1.5rem;
    padding-bottom: 0;
    border-bottom: 0;
  }
  .articleGroup .group article, .articleGroup .group article:nth-child(1) {
    border-width: 0 0 1px;
    padding-right: 0;
  }
  .articleGroup .group article footer {
    padding-bottom: 1rem;
  }
}
@media (max-width: 567px) {
  .articleGroup .group {
    display: flex;
    flex-wrap: wrap;
  }
  .articleGroup .group .thumbnail {
    flex-basis: auto;
  }
}

.socialMedia {
  color: #505050;
  max-width: 450px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0;
  float: right;
}
.socialMedia p {
  margin: 0 4px 0 0;
}
.socialMedia .socialMediaIcon span {
  display: none;
}
.socialMedia .socialMediaIcon svg {
  color: #5973b8;
  height: 1.675rem;
  width: 1.675rem;
  margin: 0.15rem;
  transform: scale(1);
  transition: transform 100ms ease-in;
}
.socialMedia .socialMediaIcon svg:hover {
  color: #3a4b79;
}
.socialMedia .at-style-responsive .at-share-btn {
  margin: 0 3px;
}
.socialMedia .at-style-responsive .at-share-btn:focus, .socialMedia .at-style-responsive .at-share-btn:hover {
  transform: none;
  outline: none;
}
.socialMedia .at-style-responsive .at-share-btn:hover {
  background-color: #3a4b79 !important;
}

#Hero {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
#Hero .h1 {
  font-size: 2.25rem;
}
#Hero .heroInfo a, #Hero .heroInfoMobile a {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
#Hero .slide.for-articles {
  max-width: 800px;
  margin: 0 auto;
}
#Hero .hero {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 55vh;
  margin: 0 auto;
}
#Hero .lm-hero {
  padding-bottom: 35%;
}
@media (max-width: 800px) {
  #Hero .lm-hero {
    padding-bottom: 50%;
  }
}
#Hero .hero.articleView {
  display: block;
}
@media (max-width: 800px) {
  #Hero .hero.articleView {
    display: none;
  }
}
#Hero .articleImg {
  display: none;
}
#Hero .articleImg img {
  width: 100%;
  height: 100%;
}
@media (max-width: 799px) {
  #Hero .articleImg {
    display: block;
  }
}
#Hero .heroContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
#Hero .heroImg {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#Hero .heroImg img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  object-fit: cover;
}
@media (max-width: 799px) {
  #Hero .heroImg img {
    height: 100%;
    width: auto;
    min-width: 100%;
  }
}
#Hero .heroInfo {
  bottom: 0;
  color: #333333;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: left;
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #999999;
  border-radius: 0;
  border-bottom: none;
}
@media (max-width: 799px) {
  #Hero .heroInfo {
    display: none;
  }
}
#Hero .heroInfoSide {
  display: none;
}
#Hero .heroInfoMobile {
  display: none;
  height: 12rem;
  background-color: #141416;
  text-align: left;
  padding: 0 1.5rem;
  position: relative;
  justify-content: center;
  flex-direction: column;
  border: none;
}
#Hero .heroInfoMobile .h1 {
  font-size: 2rem;
  margin: 0.5rem 0;
}
#Hero .heroInfoMobile .p {
  margin: 0 0 0.5rem;
}
#Hero .heroInfoMobile .max-2-lines {
  display: inline-block;
  display: -webkit-box;
}
#Hero .heroInfoMobile .max-3-lines {
  display: none;
}
@media (max-width: 799px) {
  #Hero .heroInfoMobile {
    display: flex;
  }
}
@media (max-width: 567px) {
  #Hero .heroInfoMobile .h1 {
    font-size: 1.5rem;
  }
  #Hero .heroInfoMobile .max-3-lines {
    display: -webkit-box;
  }
  #Hero .heroInfoMobile .max-2-lines {
    display: none;
  }
}
#Hero a:hover .h1, #Hero a:hover .h2, #Hero a:hover .h3 {
  text-decoration: underline;
}
#Hero .lm-heroInfoMobile {
  background-color: #34c5f1;
  border: none;
}
#Hero .lm-heroInfoMobile div {
  color: #fff;
  justify-content: center;
}
#Hero .lm-heroInfoMobile p {
  color: #fff;
  text-align: center;
  font-style: italic;
}

#Sidebar {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
#Sidebar .sidebar-item {
  margin: 1rem 0;
}
#Sidebar .sidebar-item > * {
  position: sticky;
  transition: 0.5s ease-in-out;
  top: 0.5rem;
  margin: 0;
}
#Sidebar .sidebar-item:has(.advertisement.halfPage-ad) {
  display: none;
}
#Sidebar .sidebar-item:has(.advertisement.halfPage-ad:not([style*="display: none;"])) {
  display: flex;
}
#Sidebar.headerShown {
  top: calc(4rem + 2rem);
}
#Sidebar.headerShown .sidebar-item > * {
  top: 7rem;
}
#Sidebar .CurrentIssue {
  background-color: transparent;
  margin: 0;
}
@media (max-width: 1023px) {
  #Sidebar .CurrentIssue {
    display: flex;
    justify-content: center;
  }
}
#Sidebar #SidebarContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 300px;
}
#Sidebar #SidebarContent .main-container {
  width: 100%;
  padding: 0 1rem;
}
#Sidebar #SidebarContent > * {
  flex: 1 1 auto;
}
#Sidebar h3,
#Sidebar .h3 {
  font-size: 1em;
  font-weight: 600;
}
#Sidebar .h1.subTitle {
  font-size: 1.424rem;
  padding-bottom: 0.5rem;
}
#Sidebar .learmoreBtn {
  display: none;
}
#Sidebar .ad {
  width: 300px;
  height: 250px;
}
#Sidebar .advertisement + .advertisement {
  margin-top: 0;
}
#Sidebar .articleTabList {
  justify-content: center;
}
#Sidebar .articleTab {
  opacity: 1 !important;
  transform: none !important;
  margin: 0.75rem 0 0.5rem;
  padding: 0 0.5rem 1rem;
}
@media (min-width: 1024px) {
  #Sidebar .articleTab {
    width: calc(100% - 1rem);
  }
}
@media (max-width: 1023px) {
  #Sidebar .schemaAddress {
    justify-content: center;
  }
}
#Sidebar .socialMedia {
  justify-content: center;
}

#SidebarWithAssetInfo {
  margin: 0 auto;
}

.error {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin: 2em 0;
  padding: 1rem;
}

.breadcrumbs {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 0;
  font-size: 0.85rem;
  opacity: 0.75;
  font-style: italic;
}
.breadcrumbs li {
  list-style-type: none;
  display: inline-block;
}
.breadcrumbs li a {
  color: #5973b8;
}
.breadcrumbs li a:hover {
  text-decoration: underline;
}
.breadcrumbs li:after {
  content: "/";
  margin: 0.4rem;
}
.breadcrumbs li:last-child:after {
  content: "";
}

#NewsletterSignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  max-height: 240px;
}
#NewsletterSignUp.lazy-background.visible {
  background-image: url(../assets/eNewsletter_bg.jpeg);
  background-position: center;
  background-size: cover;
}
@media (max-width: 1023px) {
  #NewsletterSignUp {
    flex-direction: column;
    text-align: center;
    height: 100%;
    max-height: 100%;
  }
}
#NewsletterSignUp label {
  font-size: 1rem;
  margin-bottom: 1rem;
}
#NewsletterSignUp input[type=email] {
  height: 2.25rem;
  width: 300px;
  max-width: 100%;
  text-align: center;
  box-shadow: none;
  border: 1px solid #ccc;
  margin: 0.5rem auto;
}
#NewsletterSignUp .actions {
  margin: 0;
  padding: 0;
}
#NewsletterSignUp .hs-button {
  background: #5973b8;
  color: #ffffff;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  border: 1px solid #fff;
  cursor: pointer;
  font-family: "futura-pt", sans-serif;
}
#NewsletterSignUp input {
  position: relative;
  top: 0;
  left: 0;
}
#NewsletterSignUp input[type=submit]:active {
  top: 2px;
}
#NewsletterSignUp ul {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
}
#NewsletterSignUp .cover-img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  padding: 0 2rem;
  max-width: 450px;
  margin-bottom: -1rem;
  margin-top: -1rem;
}
#NewsletterSignUp .cover-img img {
  width: 100%;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
}
#NewsletterSignUp .cover-text {
  padding: 2rem;
}
#NewsletterSignUp .cover-text .h1, #NewsletterSignUp .cover-text p {
  margin: 0;
}
#NewsletterSignUp .cover-text button {
  margin-top: 1rem;
}

#NewsletterWrapper .section#Newsletter {
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  opacity: 0.5;
  margin: 0 auto;
  transition-property: all;
  transition-duration: 1.5s;
  z-index: 2;
}
#NewsletterWrapper .section#Newsletter:hover {
  transition-property: all;
  transition-duration: 1.5s;
  opacity: 1;
}

.section#Newsletter {
  margin: 3rem auto 4rem;
  max-height: 240px;
}
@media (max-width: 1023px) {
  .section#Newsletter {
    max-height: 100%;
  }
}
@media (max-width: 1023px) {
  .section#Newsletter .left {
    flex-direction: column;
    padding: 2rem 0 3rem;
  }
}

input {
  font-size: 1rem;
  outline: none;
  color: #555;
}

input::placeholder {
  color: #5973b8;
}

form .btn {
  background-color: #34c5f1;
  color: #fff;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0;
  cursor: pointer;
}
form .input-standard {
  border-radius: 0;
  border: 1px #cccccc solid;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  box-shadow: none;
}

.inline-searchform {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}
.inline-searchform input[name=for] {
  flex-grow: 2;
}

/* TODO: check where we can use $tertiary-color instead of a bunch of action-btn-color. */
button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button, select {
  text-transform: none;
}

button {
  overflow: visible;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.action-btn {
  display: flex;
  max-height: 2.5rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #5973b8;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
}
.action-btn.primary {
  background: #34c5f1;
}
.action-btn.secondary {
  background: #515254;
}
.action-btn:active {
  top: 2px;
}
.action-btn:hover {
  transition: transform 20ms;
  text-decoration: none;
  background: #dee3f1;
  color: #5973b8;
  border-color: #5973b8;
}
.action-btn:disabled {
  background: #515254;
  color: #ffffff;
  border-color: 1px solid transparent;
  pointer-events: none;
}
.action-btn.compact {
  width: fit-content;
}

.action-btn.inverted {
  background: #dee3f1;
  color: #5973b8;
  border-color: #5973b8;
}
.action-btn.inverted:hover {
  color: #ffffff;
  background: #5973b8;
  border-color: #dee3f1;
}

.action-btn.small {
  padding: 0.25rem 0.5rem;
}

.btn {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
  display: inline-block;
  text-decoration: none;
}
.btn.branded {
  background: #5973b8;
}
.btn.hollow {
  background: transparent;
}
.btn.altBranded {
  background: #5973b8;
  text-align: center;
}
.btn:hover {
  border-color: #fff;
}

svg[data-prefix=far], svg[data-prefix=fas], svg[data-prefix=fal] {
  margin-right: 0.35rem;
}

.video-block .video-player {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* Assume a 16x9 ratio */
}
.video-block .video-player.ratio-4x3 {
  padding-bottom: 75%;
}
.video-block .video-player .embed-responsive-item,
.video-block .video-player iframe,
.video-block .video-player embed,
.video-block .video-player object,
.video-block .video-player video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.video-block p {
  margin: 0;
}

@media print {
  #MainHeader {
    display: none;
  }
  #MainContainer #Hero {
    display: none;
  }
  #MainContainer #Sidebar {
    display: none;
  }
  #MainContainer .CurrentIssue {
    display: none;
  }
  #MainContainer #Sponsored {
    display: none;
  }
  #MainContainer #Marketplace {
    display: none;
  }
  #MainContainer #ExtraContent {
    display: none;
  }
  #MainFooter {
    display: none;
  }
  #Torpedo {
    display: none;
  }
  #Peelback {
    display: none;
  }
  .advertisement {
    display: none;
  }
}
.ArticleList.VendorList table {
  width: 100%;
}
.ArticleList.VendorList h3 {
  margin: 1rem 0 0;
}
.ArticleList.VendorList .sponsoredList,
.ArticleList.VendorList .fullList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.ArticleList.VendorList .fullList {
  margin-top: 1rem;
}
.ArticleList.VendorList .unsponsoredList {
  flex-basis: 47%;
  width: 47%;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-radius: 0;
  padding: 1rem 0;
}

#vendorSite {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 90%;
  font-weight: normal;
  color: #34c5f1;
  padding: 0.5rem 0 1.5rem;
}

.featuredVendorList {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.featuredVendorList:empty {
  padding-bottom: 0;
}
.featuredVendorList .featuredVendor {
  width: 23%;
  margin: 0 0.5rem;
  text-align: center;
  padding-top: 1rem;
}
.featuredVendorList .featuredVendor:only-child {
  margin-right: auto;
}
@media (max-width: 800px) {
  .featuredVendorList .featuredVendor {
    width: 100%;
  }
}
.featuredVendorList figure {
  margin: 0rem auto;
  min-height: 100px;
  position: relative;
  max-width: 200px;
}
.featuredVendorList figure a:empty {
  display: block;
  background: #efefef;
}
.featuredVendorList figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.featuredVendorList .text {
  margin-top: 0rem;
}
.featuredVendorList + .title {
  margin-top: 0;
}

.VendorSummary {
  display: flex;
  flex-basis: 47%;
  width: 47%;
  justify-content: space-between;
  padding: 0.5rem 0;
  padding-right: 1rem;
  border-bottom: 1px solid #e5e5e5;
  min-height: 80px;
}
@media (max-width: 1023px) {
  .VendorSummary {
    display: block;
    width: 100%;
    text-align: center;
  }
  .VendorSummary td {
    display: block;
  }
}
.VendorSummary .thumbnailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}
.VendorSummary .thumbnailWrapper img {
  width: 100%;
  height: auto;
}
.VendorSummary .text {
  width: 100%;
  padding-left: 0;
}
.VendorSummary .text main h3 a#vendorSite {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #34c5f1 !important;
}
.VendorSummary .thumbnail {
  width: 20%;
  overflow: hidden;
  max-height: 80px;
}
.VendorSummary .level-two .article-summary-label {
  bottom: 0.75rem;
  left: 0;
  height: 21px;
  width: 68px;
  background-color: #34c5f1;
  color: #fff;
  font-size: 12px;
  padding: 3px;
  transform: none;
}

@media (max-width: 700px) {
  .ArticleSummary.VendorSummary {
    flex-wrap: wrap;
  }
  .ArticleSummary.VendorSummary .level-two .article-summary-label {
    top: 0.25rem;
    right: 0;
    left: auto;
  }
}
@media (max-width: 568px) {
  .ArticleList.VendorList .fullList {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .ArticleSummary.VendorSummary {
    width: 90vw;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: auto;
  }
  .ArticleSummary.VendorSummary .text {
    min-height: 2rem;
  }
  .ArticleSummary.VendorSummary .thumbnailWrapper .article-img {
    position: static;
  }
  .ArticleSummary.VendorSummary .level-two .article-summary-label {
    top: 0.5rem;
    right: 0;
  }
  .ArticleSummary.VendorSummary section.text .h3 {
    margin-top: 0;
  }
}
h3.vendor {
  margin: 1rem 0 0;
}

#TrendsListHero header {
  width: 100%;
}
#TrendsListHero #TrendsFeatured {
  display: flex;
  padding: 1.5rem;
  width: 100%;
  margin: 0 0.5rem 1rem;
}
#TrendsListHero #TrendsFeatured .heroWrapper {
  order: 2;
  position: relative;
  flex-basis: 50%;
  width: 50%;
  margin-left: 1rem;
}
#TrendsListHero #TrendsFeatured .heroInfoSide {
  order: 1;
  flex-basis: 50%;
  width: 50%;
}
#TrendsListHero #TrendsFeatured .heroImg {
  width: 100%;
}
#TrendsListHero #TrendsFeatured .heroImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#TrendsListHero #TrendsFeatured .heroImg .articleTabImg {
  height: 300px;
}
#TrendsListHero #TrendsFeatured .heroImg .articleTabImg .missingImage {
  position: relative;
}
#TrendsListHero #TrendsFeatured .article-img:before {
  content: none;
}
#TrendsListHero #TrendsFeatured ul {
  padding-left: 1.2rem;
}
#TrendsListHero #TrendsFeatured li {
  padding-bottom: 0.5rem;
}
@media (max-width: 799px) {
  #TrendsListHero #TrendsFeatured {
    flex-direction: column;
    padding: 0;
  }
  #TrendsListHero #TrendsFeatured .h2 {
    font-weight: 500;
    font-size: 1.3rem;
  }
  #TrendsListHero #TrendsFeatured .heroWrapper {
    order: 1;
    position: relative;
    width: 100%;
    flex-basis: 100%;
    margin: 0 0 1rem;
  }
  #TrendsListHero #TrendsFeatured .heroInfoSide {
    order: 2;
    width: 100%;
    flex-basis: 100%;
    padding: 1rem;
  }
  #TrendsListHero #TrendsFeatured .heroImg img {
    max-height: 300px;
  }
  #TrendsListHero #TrendsFeatured .action-btn {
    margin: 1rem auto 0;
    max-width: 250px;
  }
}
@media (max-width: 799px) {
  #TrendsListHero .isIE .heroWrapper, #TrendsListHero .isIE .heroInfoSide {
    flex-basis: auto !important;
  }
}

.LatestIssue .flex {
  flex-direction: column;
}
.LatestIssue h1, .LatestIssue .h1 {
  color: inherit;
}
.LatestIssue h4 {
  margin-top: 0;
}

.CurrentIssue {
  width: 100%;
  background-color: #ccc;
  margin: 1.5rem 0 3rem;
  z-index: 2;
}
.CurrentIssue .main-container {
  max-height: 240px;
  height: 240px;
}
@media (max-width: 1023px) {
  .CurrentIssue .main-container {
    max-height: 100%;
  }
}
@media (max-width: 799px) {
  .CurrentIssue .main-container {
    height: 100%;
  }
}
.CurrentIssue .section-padding {
  padding: 0;
  background-image: url(../assets/magazine_bg.jpg), linear-gradient(to bottom, #144e8c 0%, #041e50 100%);
  background-size: cover, auto;
}
.CurrentIssue #MagazineContent {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.CurrentIssue #MagazineContent .magazine-image {
  transform: rotate(0deg);
  width: 210px;
  padding: 0;
  margin: 0;
}
.CurrentIssue #MagazineContent .magazine-image img {
  width: 100%;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.CurrentIssue #MagazineContent .magazine-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1.5rem;
  margin: 0 0.5rem 1rem;
}
.CurrentIssue #MagazineContent .magazine-info > * {
  flex: 1 1 auto;
}
.CurrentIssue #MagazineContent .magazine-info h1, .CurrentIssue #MagazineContent .magazine-info .h1, .CurrentIssue #MagazineContent .magazine-info h4, .CurrentIssue #MagazineContent .magazine-info .h4, .CurrentIssue #MagazineContent .magazine-info p {
  width: 100%;
  margin: 0.25rem 0;
}
.CurrentIssue #MagazineContent .left {
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 799px) {
  .CurrentIssue #MagazineContent .left {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0 3rem;
    text-align: center;
  }
}
.CurrentIssue #MagazineContent .right.text-white h3, .CurrentIssue #MagazineContent .right.text-white .h3 {
  color: #fff;
}

.section.CurrentIssue + footer {
  margin-top: -3rem;
}

.MagazineGateBtn {
  display: flex;
}
.MagazineGateBtn .btn {
  white-space: nowrap;
  font-size: 14px;
  text-transform: uppercase;
  background-color: unset;
  padding: 0.5rem;
}
.MagazineGateBtn .btn.downloadBtn {
  margin-right: 3rem;
}
.MagazineGateBtn .btn:focus {
  outline: 0;
}
.MagazineGateBtn .btn:hover {
  text-decoration: underline;
  border-color: transparent;
}
@media (max-width: 567px) {
  .MagazineGateBtn {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .MagazineGateBtn .btn {
    margin: 0;
  }
}

.asset-info {
  font-size: 0.875rem;
  line-height: 1.4;
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: #f2f2f2;
  color: #929292;
  background-color: transparent;
  margin-bottom: 0;
}
.asset-info > * {
  display: inline;
  margin-right: 0.5rem;
  font-style: italic;
}
.asset-info > :last-child {
  margin: 0;
}
.asset-info .asset-reference {
  color: #929292;
  text-transform: uppercase;
  font-style: normal;
}
.asset-info .asset-reference a {
  color: #5973b8;
}
.asset-info .asset-reference strong {
  color: #666666;
}
.asset-info.asset-info-hero {
  max-width: 300px;
}
.asset-info.asset-info-hero-hImg {
  display: block;
}
@media (max-width: 1023px) and (min-width: 800px) {
  .asset-info.asset-info-hero-hImg {
    border-width: 0;
  }
}
@media (max-width: 800px) {
  .asset-info.asset-info-hero-hImg {
    display: none;
  }
}
.asset-info.asset-info-hero-aImg {
  display: none;
}
.asset-info.asset-info-hero-aImg img {
  width: 100%;
}
@media (max-width: 799px) {
  .asset-info.asset-info-hero-aImg {
    display: block;
    border-width: 0;
  }
}
.asset-info .show-in-mobile {
  display: none;
}
@media (max-width: 1023px) {
  .asset-info .show-in-mobile {
    display: block;
  }
}

.event-card {
  border: 1px solid #ccc;
  background: #f4f4f4;
  padding: 1rem;
  line-height: 1.4;
  margin: 2.5rem auto 1rem;
  width: 300px;
  flex-grow: 0 !important;
  text-align: left;
}
@media (max-width: 1023px) {
  .event-card {
    margin: 0;
    width: 100%;
  }
}
.event-card .extras {
  margin-top: 1rem;
}
.event-card h3 {
  margin-top: 0;
  font-weight: initial;
}
.event-card a {
  text-decoration: none;
}
.event-card .event-details-label {
  font-family: "futura-pt", sans-serif;
}
.event-card time {
  display: block;
  font-size: 1em;
  color: #444444;
  font-family: "open-sans", sans-serif;
}
.event-card .sponsors {
  background-color: #fff;
  padding: 1rem;
}

#Sidebar .event-card .socialMedia {
  justify-content: flex-start;
  display: unset;
  float: unset;
}
#Sidebar .event-card .socialMedia p {
  margin: revert;
}

.MagazineSummary {
  width: 100%;
  display: flex;
  transition: 0.5s;
  overflow: hidden;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #e5e5e5;
  border-radius: 0;
  padding: 0.5rem 0 1.5rem;
  margin: 0.5rem 0;
}
.MagazineSummary .thumbnail .thumbnailWrapper {
  border: 1px solid #929292;
  margin: 0;
}
.MagazineSummary .thumbnail .thumbnailWrapper img {
  transition: 0.5s;
  display: block;
  width: 150px;
  height: 186px;
}
.MagazineSummary .text {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  width: 65%;
}
.MagazineSummary .text footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  width: 700px;
  max-width: 90%;
}
.MagazineSummary:not(.currentIssue) time a, .MagazineSummary:not(.currentIssue) footer a {
  color: initial;
}
.MagazineSummary time {
  text-transform: uppercase;
}
.MagazineSummary .missingImage {
  position: relative;
  width: 150px;
  height: 186px;
}

#speechify-root {
  display: inline-block !important;
  width: 100% !important;
  max-width: 450px !important;
  margin-top: 1rem !important;
}
#speechify-root * {
  white-space: nowrap;
}
#speechify-root #gated-cta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
#speechify-root #gated-cta #AudioArticleSignUp {
  width: 100%;
}
#speechify-root #inline-player-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 200px;
  padding: 15px 10px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(229, 229, 229);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 17px 0px;
}
#speechify-root #inline-player-container #inline-player-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 8px;
}
#speechify-root #inline-player-container #inline-player-main #inline-player-header a {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}
#speechify-root #inline-player-container #inline-player-main #seekbar-container {
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  height: 20px;
}
#speechify-root #inline-player-container #inline-player-main #seekbar-container #seekbar-bar {
  position: relative;
  width: 85%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin: 0 16px;
  color: rgb(52, 197, 241);
}
#speechify-root #inline-player-container #inline-player-main #seekbar-container #seekbar-thumb {
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: rgb(52, 197, 241);
  border-radius: 100px;
  left: -5px;
  top: -5px;
}
#speechify-root #inline-player-container #speechify-icon {
  line-height: 0;
  margin-left: 12px;
  color: rgb(52, 197, 241);
}

.CurrentMagazineIssueSidebar {
  display: flex;
  justify-content: center;
}
.CurrentMagazineIssueSidebar .magazine {
  display: flex;
  width: 300px;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  background: #f8f8f8;
}
.CurrentMagazineIssueSidebar .magazine-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
}
.CurrentMagazineIssueSidebar .magazine-info .top,
.CurrentMagazineIssueSidebar .magazine-info .bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  text-align: end;
}
.CurrentMagazineIssueSidebar .magazine-info .magazine-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.CurrentMagazineIssueSidebar .magazine-info .magazine-summary {
  color: #00123b;
}
.CurrentMagazineIssueSidebar .magazine-image img {
  border: 1px solid #555555;
}
.CurrentMagazineIssueSidebar button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 6px;
  border: 1px solid #00123b;
  background: #00123b;
}
.CurrentMagazineIssueSidebar button a {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.467px;
  text-decoration: none;
}
.CurrentMagazineIssueSidebar button:hover {
  background: #5973b8;
}

.newsletter-signup {
  display: inline-block;
  border: 1px solid #144E8C;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 2rem 2rem 1.5rem;
  background-color: #ebf9fe;
  width: 100%;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1019607843);
}
.newsletter-signup h2 {
  font-size: xx-large;
  color: #00123b;
  font-weight: bolder;
  line-height: 130% !important;
  margin: 0;
}
.newsletter-signup h3 {
  font-size: 24px;
  color: #00123b;
  font-weight: normal;
  margin: 0;
}
.newsletter-signup b {
  font-weight: bolder;
}
.newsletter-signup .newsletter-signup-main {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.newsletter-signup .text-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  min-width: 300px;
  flex-grow: 1;
  gap: 1rem;
}
.newsletter-signup .form-area {
  flex-grow: 1;
  min-width: 250px;
}
.newsletter-signup form {
  border: 1px solid #00123b;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
}
.newsletter-signup label {
  font-family: "futura-pt", sans-serif;
}
.newsletter-signup .input {
  width: 100%;
  border: 1px solid rgba(52, 197, 241, 0.4);
  box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px 2px 3px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  background: #fff;
}
.newsletter-signup ::placeholder {
  color: #BBBBBB;
  font-style: italic;
}
.newsletter-signup .error {
  margin: 0;
}
.newsletter-signup input[name=email],
.newsletter-signup select[name=lmg_demo_country] {
  height: 40px;
  width: 100%;
  border: none;
  padding: 0.5rem;
  font-family: "futura-pt", sans-serif;
  font-weight: 100;
  outline: none;
  background: transparent;
  text-align: left;
}
.newsletter-signup .hs_lmg_demo_country .input {
  padding-right: 10px;
}
.newsletter-signup .hs_lmg_demo_country select {
  cursor: pointer;
}
.newsletter-signup .hs-error-msgs {
  margin: 0 !important;
  padding: 0;
  font-family: "futura-pt", sans-serif;
  color: #c00;
}
.newsletter-signup .hs-error-msgs li {
  display: block;
}
.newsletter-signup .hs-button {
  background-color: #00123b;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  padding: 8px 10px;
  font-family: "futura-pt", sans-serif;
  width: 100%;
}
.newsletter-signup .submitted-message {
  margin: 0 !important;
  line-height: 1.5 !important;
  padding: 5px 8px 0;
  font-family: "futura-pt", sans-serif;
}
.newsletter-signup .newsletter-signup-footer {
  font-size: 12px;
  margin-top: 1rem;
  text-align: center;
}

.thank-you-hubspot-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.thank-you-hubspot-form .field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.thank-you-hubspot-form label {
  color: #555555;
  text-align: left;
}
.thank-you-hubspot-form .hs-input {
  box-sizing: border-box;
  max-width: unset;
  height: 40px;
  width: 100%;
  padding: 0 0.5rem;
  margin: 0;
  border-radius: 6px 6px 0px 0px;
  background: #fff;
  border: 2px solid #ccc;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #ccc;
  text-align: left;
}
.thank-you-hubspot-form .hs-input:focus {
  border-bottom: 4px solid #5973b8;
}
.thank-you-hubspot-form ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 799px) {
  .thank-you-hubspot-form ul[role=checkbox] {
    columns: 1;
  }
}
@media (min-width: 800px) and (max-width: 1199px) {
  .thank-you-hubspot-form ul[role=checkbox] {
    columns: 2;
  }
}
@media (min-width: 1200px) {
  .thank-you-hubspot-form ul[role=checkbox] {
    columns: 3;
  }
}
.thank-you-hubspot-form ul[role=checkbox] label {
  display: flex;
  gap: 0.5rem;
}
.thank-you-hubspot-form ul[role=checkbox] .hs-input {
  height: 20px;
  width: 20px;
  box-shadow: none;
}
.thank-you-hubspot-form .hs-form-required {
  margin-left: 3px;
  color: #c00;
}
.thank-you-hubspot-form input[type=submit] {
  display: inline-block;
  color: #ffffff;
  background: #5973b8;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  text-transform: uppercase;
  margin: 0.25rem 0;
  text-decoration: none;
}
.thank-you-hubspot-form input[type=submit]:hover {
  transition: transform 20ms;
  text-decoration: none;
  background: #dee3f1;
  color: #5973b8;
  border-color: #5973b8;
}
.thank-you-hubspot-form .is-placeholder {
  color: #929292;
  font-style: italic;
}
.thank-you-hubspot-form .hs-error-msgs,
.thank-you-hubspot-form .hs-error-msgs label {
  color: #c00;
  font-weight: normal;
  padding-left: 0;
}
.thank-you-hubspot-form input.hs-input.error,
.thank-you-hubspot-form div.field.error input,
.thank-you-hubspot-form div.field.error .chzn-choices,
.thank-you-hubspot-form textarea.hs-input.error,
.thank-you-hubspot-form select.hs-input.error {
  border-color: #c00;
  border-bottom: 4px solid #c00;
}
#TrendsLandingPage .border-light-grey {
  width: 100%;
}
#TrendsLandingPage .slide {
  display: flex;
  padding: 1.5rem;
  width: 100%;
}
#TrendsLandingPage .heroWrapper {
  order: 2;
  position: relative;
  flex-basis: 50%;
  width: 50%;
  margin-left: 1rem;
}
#TrendsLandingPage .heroInfoSide {
  order: 1;
  width: 50%;
  flex-basis: 50%;
}
#TrendsLandingPage .heroImg {
  width: 100%;
}
#TrendsLandingPage .heroImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#TrendsLandingPage #Content {
  padding: 0 1.5rem;
}
@media (max-width: 799px) {
  #TrendsLandingPage .slide {
    flex-direction: column;
    padding: 0;
  }
  #TrendsLandingPage .heroWrapper {
    order: 1;
    position: relative;
    width: 100%;
    margin: 0 0 1rem;
  }
  #TrendsLandingPage .heroInfoSide {
    order: 2;
    width: 100%;
    padding: 0 1rem;
  }
  #TrendsLandingPage .heroImg img {
    max-height: 300px;
  }
  #TrendsLandingPage #Content {
    padding: 0 1rem;
  }
}
#TrendsLandingPage #ArticleList .articleTabImg {
  height: 4rem;
  width: 8rem;
}

@media (max-width: 799px) {
  .isIE .heroWrapper, .isIE .heroInfoSide {
    flex: 1 1 auto !important;
  }
}
#TrendsList {
  flex-direction: column;
}
#TrendsList #SSRList, #TrendsList #CSRList {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
body.trends #MainContainer {
  padding-top: 2rem;
}
body.trends #MainHeader {
  height: 2rem;
  border-width: 1px;
}
body.trends #MainHeader #MainHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.7rem;
  max-width: 1600px;
}
body.trends #MainHeader #MainHeaderContent #HeaderLeft {
  display: flex;
  align-items: stretch;
}
body.trends #MainHeader #MainHeaderContent #brand {
  align-self: stretch;
  margin: 0;
  height: auto;
  background-image: url("../assets/main_logo.svg");
  width: 70px;
  margin-left: 0;
  display: block;
  flex-direction: column;
}

#TrendsContent {
  /* css Parallax Styles */
}
#TrendsContent .slide .heroImg {
  max-height: 55vh;
  overflow: hidden;
}
#TrendsContent .parallax-container {
  display: block;
  position: relative;
  height: 45vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#TrendsContent .parallax-container:empty {
  height: 0;
}
#TrendsContent .parallax-container:empty + .ArticleViewPage > .Trends.Default {
  margin-top: 0 !important;
}
#TrendsContent .parallax-container:empty + .ArticleViewPage > .Trends.Default article {
  margin-top: 0;
}
#TrendsContent .parallax-container .parallax-img {
  pointer-events: none;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  width: 100vw;
}
@media (max-width: 1023px) {
  #TrendsContent .parallax-container {
    height: 28vh;
  }
  #TrendsContent .parallax-container .parallax-img {
    background-attachment: initial;
  }
}
#TrendsContent main .unpublished {
  position: relative;
  overflow: hidden;
  filter: grayscale(1) opacity(0.8);
}
#TrendsContent main .unpublished #MainContent {
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 0;
}
#TrendsContent main .unpublished #MainContent #ArticleView {
  margin: 0 auto;
  background: none;
}
#TrendsContent main .unpublished #MainContent #ArticleView article {
  margin: 0 auto;
  max-width: 100%;
}
@media (max-width: 1023px) {
  #TrendsContent main .unpublished #MainContent {
    position: relative;
    top: 0;
  }
}
#TrendsContent #MainContent #ArticleView,
#TrendsContent #MainContent .breadcrumbs {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
#TrendsContent #MainContent .articleWrapper {
  margin: 0;
  display: flex;
  max-width: 100%;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
#TrendsContent #MainContent .articleWrapper article {
  margin-top: -20vh;
  background-color: #fff;
  width: 100%;
  padding: 2rem;
}
@media (max-width: 568px) {
  #TrendsContent #MainContent .articleWrapper article {
    padding: 2rem 0;
  }
}
#TrendsContent #MainContent .articleWrapper article header {
  position: relative;
}
#TrendsContent #MainContent .articleWrapper article header h1 {
  margin-top: 0;
}
#TrendsContent #MainContent .articleWrapper article header h3 {
  font-style: italic;
  font-weight: 100;
}
#TrendsContent #MainContent .articleWrapper article img:not(.avatar) {
  max-width: 100%;
}
#TrendsContent #MainContent .articleWrapper article footer .author-section {
  display: flex;
  align-items: center;
}
#TrendsContent #MainContent .articleWrapper article footer .author-section .bio-section {
  margin-left: 1rem;
}
#TrendsContent #MainContent .articleWrapper article footer .author-section picture {
  width: 100px;
  flex-shrink: 0;
}
#TrendsContent #MainContent .articleWrapper article footer .author-section img.avatar {
  padding: 0;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}
#TrendsContent #MainContent .articleWrapper article footer .author-section p {
  margin-top: 0;
}
@media (max-width: 568px) {
  #TrendsContent #MainContent .articleWrapper article footer .author-section {
    flex-direction: column;
  }
  #TrendsContent #MainContent .articleWrapper article footer .bio-section {
    margin-top: 1rem;
  }
}
@media (max-width: 1023px) {
  #TrendsContent #MainContent .articleWrapper article {
    margin-top: 0;
  }
}
@media (max-width: 799px) {
  #TrendsContent #MainContent .articleWrapper {
    flex-direction: column;
  }
  #TrendsContent #MainContent .articleWrapper > * {
    flex: 1 1 auto;
  }
}
@media (max-width: 1024px) {
  #TrendsContent #MainContent .articleWrapper .articleWrapper #ArticleBody::before {
    border-right: none;
  }
}
#TrendsContent #MainContent .articleWrapper .imageBlock:first-of-type {
  width: 100% !important;
  margin: 1rem 0;
}
#TrendsContent #MainContent #Sidebar {
  padding-top: 2rem;
}
#TrendsContent #MainContent #Sidebar .sidebar-item > * {
  top: calc(4rem + 3rem);
}
#TrendsContent #MainContent #Sidebar.headerShown .sidebar-item > * {
  top: calc(4rem + 2rem + 3rem);
}

#MainContainer #TrendsContent #ConversionPageView article.main-container aside {
  top: calc(4rem + 3rem);
}
#WebinarLandingPage {
  width: 100%;
  margin: 0 0 1rem;
}
#WebinarLandingPage > * {
  padding: 0 2rem 0 0;
}
@media (max-width: 1023px) {
  #WebinarLandingPage {
    margin-bottom: 0;
  }
  #WebinarLandingPage > * {
    padding: 0;
  }
}
.AlphaPaginationContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: #929292;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-width: 0;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
}
@media (max-width: 568px) {
  .AlphaPaginationContainer {
    flex-direction: column;
    justify-content: center;
  }
}
.AlphaPaginationContainer button {
  background: transparent;
  border: none;
  padding: 0.25rem 0.5rem;
  color: #5973b8;
  cursor: pointer;
}
.AlphaPaginationContainer button:hover {
  text-decoration: underline;
}
.AlphaPaginationContainer button.selected:hover {
  text-decoration: none;
}
.AlphaPaginationContainer button.selected {
  cursor: default;
  background-color: #5973b8;
  color: #fff;
}
.AlphaPaginationContainer button:disabled {
  color: #929292;
  cursor: default;
}
.ArticleSection .notLoaded {
  padding-bottom: 25%;
}

.ArticleSection .isEmpty {
  display: none;
}
.ArticleSection .isEmpty + .advertisement {
  display: none;
}
#CookiePolicy {
  height: 0;
  overflow: hidden;
  width: 100%;
  background-color: #5973b8;
  transition: height 1s;
  color: #fff;
  font-size: 0.75rem;
  position: relative;
  z-index: 1000;
}
#CookiePolicy .main-container {
  height: 100%;
}
#CookiePolicy .cookie-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#CookiePolicy button {
  cursor: pointer;
  padding: 0.5rem;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  width: 8rem;
  flex-shrink: 0;
  margin-left: 1rem;
}
#CookiePolicy a {
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 800px) {
  #CookiePolicy.show {
    height: 3.5rem;
  }
}
@media (max-width: 799px) {
  #CookiePolicy.show {
    height: 5rem;
  }
}
@media (max-width: 567px) {
  #CookiePolicy.show {
    height: 6.5rem;
  }
  #CookiePolicy.show .cookie-content {
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
  #CookiePolicy.show button {
    margin: 0.2rem 0;
    padding: 0.2rem;
  }
}
.ArticleNavigation {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
}
@media all and (max-width: 567px) {
  .ArticleNavigation {
    flex-direction: column-reverse;
  }
}
.ArticleNavigation > div {
  max-width: 400px;
  min-width: 30%;
}
@media all and (max-width: 567px) {
  .ArticleNavigation > div {
    width: 100vw;
  }
}
.ArticleNavigation .next {
  text-align: right;
  margin: 0.5rem 1rem;
}
.ArticleNavigation .previous {
  text-align: left;
  margin: 0.5rem 1rem;
}
.ArticleNavigation .ArticleNavigationTab {
  position: relative;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  background: #f5f5f5;
  padding: 1rem;
  align-items: center;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
  transform: scale(1);
}
.ArticleNavigation .ArticleNavigationTab:hover {
  background: #34c5f1;
  color: #fff;
  box-shadow: none;
  transition: all 300ms ease-in-out;
}
.ArticleNavigation .ArticleNavigationTab:hover a, .ArticleNavigation .ArticleNavigationTab:hover i {
  color: #fff;
}
.ArticleNavigation .ArticleNavigationTab .ArticleNavigationTabContent {
  display: flex;
  width: 100%;
  align-items: center;
}
.ArticleNavigation .ArticleNavigationTab .label {
  margin: 0 1rem;
}
.ArticleNavigation .ArticleNavigationTab p {
  margin: 0;
  text-align: right;
  line-height: 1.2;
}
.ArticleNavigation .ArticleNavigationTab a {
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  align-items: center;
}
.ArticleNavigation .ArticleNavigationTab a .missingImage, .ArticleNavigation .ArticleNavigationTab a img {
  height: 50px;
  width: 50px;
  margin-right: auto;
  display: block;
  position: relative;
}
.ArticleNavigation .ArticleNavigationTab i {
  color: #34c5f1;
  font-size: 2.5rem;
  margin: 0;
}
.ArticleNavigation .ArticleNavigationTab .fa-chevron-square-right:before {
  content: "\f32b";
}
.ArticleNavigation .ArticleNavigationTab .fa-chevron-square-left:before {
  content: "\f32a";
}
.ArticleNavigation .previousArticle .ArticleNavigationTabContent, .ArticleNavigation .previousArticle a {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.ArticleNavigation .previousArticle .ArticleNavigationTabContent .missingImage, .ArticleNavigation .previousArticle .ArticleNavigationTabContent img, .ArticleNavigation .previousArticle a .missingImage, .ArticleNavigation .previousArticle a img {
  margin-left: auto;
  margin-right: 0;
}
.ArticleNavigation .previousArticle p {
  text-align: left;
}
.ArticleNavigation div:only-of-type.nextArticle {
  margin-left: auto;
}
.ArticleNavigation div:only-of-type.previousArticle {
  margin-right: auto;
}
.ArticleNavigation .nextArticle .ArticleNavigationTabContent, .ArticleNavigation .nextArticle a {
  flex-direction: row;
  justify-content: flex-end;
}
.ArticleNavigation .nextArticle .ArticleNavigationTabContent .missingImage, .ArticleNavigation .nextArticle .ArticleNavigationTabContent img, .ArticleNavigation .nextArticle a .missingImage, .ArticleNavigation .nextArticle a img {
  margin-right: auto;
  margin-left: 0;
}
.ArticleNavigation .nextArticle p {
  text-align: right;
  flex: 1;
}
@media all and (max-width: 567px) {
  .ArticleNavigation .nextArticle p, .ArticleNavigation .previousArticle p {
    text-align: center;
    margin-bottom: 4rem;
  }
}
#HSInlineForm .progress-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
#HSInlineForm .progress-wrapper::before {
  content: "";
  background-color: #555;
  position: absolute;
  top: 30%;
  left: 40px;
  transform: translateY(-50%);
  height: 2px;
  width: calc(100% - 80px);
  z-index: -1;
}
#HSInlineForm .progress-wrapper .progress-bar {
  background-color: #515254;
  position: absolute;
  top: 30%;
  left: 40px;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
}
#HSInlineForm .progress-wrapper .step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#HSInlineForm .progress-wrapper .step .progress-text {
  padding-top: 0.5rem;
  color: #515254;
}
#HSInlineForm .progress-wrapper .step .progress-circle {
  border: 2px solid #555;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: 0.4s ease;
  transition-delay: 1s;
  padding-top: 2px;
}
#HSInlineForm .progress-wrapper .step.active-form .progress-circle {
  background-color: #515254;
}
#HSInlineForm .in-line-form {
  width: 100%;
  padding: 2rem;
}
#HSInlineForm .in-line-form .hs-input {
  box-sizing: border-box;
  max-width: unset;
  height: 40px;
  width: 100%;
  padding: 0 0.5rem;
  border-radius: 6px 6px 0px 0px;
  background: #fff;
  border: 2px solid #ccc;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #ccc;
  text-align: left;
}
#HSInlineForm .in-line-form .hs-input[type=checkbox] {
  background: none;
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  box-shadow: none;
}
#HSInlineForm .in-line-form li.hs-form-checkbox label {
  display: flex;
  margin-bottom: 1rem;
}
#HSInlineForm .in-line-form .hs-form-required {
  margin-left: 3px;
}
#HSInlineForm .in-line-form .hs-form-required,
#HSInlineForm .in-line-form .hs-error-msgs, #HSInlineForm .in-line-form .hs-error-msgs label {
  color: #c00;
  font-weight: normal;
  padding-left: 0;
}
#HSInlineForm .in-line-form .hs-button, #HSInlineForm .in-line-form input[type=submit] {
  display: inline-block;
  color: #ffffff;
  background: #5973b8;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  text-transform: uppercase;
  margin: 0.25rem 0;
  text-decoration: none;
}
#HSInlineForm .in-line-form .hs-button:hover, #HSInlineForm .in-line-form input[type=submit]:hover {
  transition: transform 20ms;
  text-decoration: none;
  background: #dee3f1;
  color: #5973b8;
  border-color: #5973b8;
}
#HSInlineForm .in-line-form .submitted-message p {
  text-align: left !important;
  margin: 0.5rem 0;
  font-size: 1.5rem;
}
#HSInlineForm .in-line-form .submitted-message p .action-btn {
  font-size: 1rem;
  font-family: "open-sans", sans-serif;
  text-transform: unset;
}
#HSInlineForm .in-line-form .inputs-list:first-child {
  padding-top: 6px;
}
#HSInlineForm .in-line-form .inputs-list > li + li {
  padding-top: 2px;
}
#HSInlineForm .in-line-form ul {
  list-style: none;
}
#HSInlineForm .in-line-form .hs-input:focus {
  border-bottom: 4px solid #5973b8;
}
#HSInlineForm .in-line-form label,
#HSInlineForm .in-line-form .hs-form {
  font-family: "futura-pt", sans-serif;
  color: #000;
}
#HSInlineForm .in-line-form input.hs-input.error,
#HSInlineForm .in-line-form div.field.error input,
#HSInlineForm .in-line-form div.field.error textarea,
#HSInlineForm .in-line-form div.field.error .chzn-choices,
#HSInlineForm .in-line-form textarea.hs-input.error,
#HSInlineForm .in-line-form select.hs-input.error {
  border-color: #c00;
  border-bottom: 4px solid #c00;
  margin: 0;
}
#HSInlineForm .in-line-form .field {
  margin-bottom: 2rem;
}
#HSInlineForm .form-header, #HSInlineForm .form-footer {
  background-color: #efefef;
  padding: 1.5rem 2rem;
}
#HSInlineForm .form-header a, #HSInlineForm .form-footer a {
  color: #5973b8;
  text-decoration: underline;
}
#HSInlineForm .form-footer small {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
@media (max-width: 567px) {
  #HSInlineForm .in-line-form, #HSInlineForm .form-footer {
    padding: 1rem;
  }
}
#HSInlineForm fieldset {
  max-width: unset !important;
}
#MenusMobile .SignIn {
  display: none;
}
#MenusMobile .SearchBar form {
  right: 2rem;
  height: 2rem;
  background-color: transparent;
  max-width: 100%;
}
#MenusMobile .SearchBar input {
  position: relative;
}
#MenusMobile .SearchBar .search-btn,
#MenusMobile .SearchBar .close-btn {
  font-size: 1.25rem;
  margin: 0;
}
#MenusMobile button {
  outline: none;
  border: none;
}
#MenusMobile .open-btn {
  font-size: 1.5rem;
  color: #555555;
  background-color: transparent;
  margin: 0;
  padding: 0 0 0 0.75rem;
}
#MenusMobile .close-btn {
  font-size: 1.5rem;
  color: #34c5f1;
  background-color: transparent;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
#MenusMobile .close-btn:hover {
  color: #515254;
}
#MenusMobile .subscribe-btn {
  padding: 0.5rem;
  margin-right: 0.5rem;
}
#MenusMobile .open .menu-list {
  height: 100vh;
  overflow-y: scroll;
}
#MenusMobile .menu-list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  background-color: #fff;
  z-index: 200;
  box-shadow: 0px 4px 7px rgba(52, 197, 241, 0.2), 0px -2px 8px -1px rgba(0, 0, 0, 0.1);
  text-align: left;
}
#MenusMobile .menu-list #menu-brand {
  background-image: url("../assets/main_logo.svg");
  display: block;
  height: 3rem;
  max-width: 15rem;
  width: 100%;
  background-repeat: no-repeat;
  margin: 1rem 0rem 1rem 2.5rem;
}
#MenusMobile .menu-list-wrapper {
  padding: 0rem;
  background-color: #34c5f1;
  margin: 0rem auto 1rem;
  display: flex;
  justify-content: center;
  height: 100%;
}
#MenusMobile ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
#MenusMobile li > a,
#MenusMobile li div {
  width: 100%;
  display: inline-block;
  padding: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
}
#MenusMobile .closed > .subMenuItems {
  display: none;
}
#MenusMobile .open > .subMenuItems {
  display: block;
}
#MenusMobile .mobileMenu {
  background-color: #34c5f1;
  color: #000000;
  text-transform: uppercase;
  width: 100%;
  padding: 0.5rem 0;
}
#MenusMobile .mobileMenu strong {
  align-items: center;
  color: #000000;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0.9rem;
  font-family: "futura-pt", sans-serif;
  font-weight: normal;
  text-transform: capitalize;
  width: 70%;
  max-width: 270px;
  margin-top: 2rem;
}
#MenusMobile .menu-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}
#MenusMobile .menuItem a,
#MenusMobile .menuItem span {
  padding: 8px 0;
  padding-left: 8px;
  width: 60%;
}
#MenusMobile .subMenu {
  padding-left: 8px;
  background: #fff;
  color: #5973b8;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}
#MenusMobile .mobileMenu.open > .menuItem {
  background: #5973b8;
  color: #fff;
}
#MenusMobile button.submenu-toggle.chevron-icon {
  background: transparent;
  color: #000;
  width: 40%;
  text-align: right;
}
#MenusMobile .mobileMenu.open .menu-item-container > button.submenu-toggle.chevron-icon {
  transform: rotate(90deg); /* Rotate the icon when the submenu is open */
  transition: transform 0.3s ease; /* Smooth transition for rotating the icon */
  color: #fff;
  width: auto;
}
#MenusMobile .mobileMenu.open .subMenu {
  max-height: 800px; /* Adjust as needed */
}
#MenusMobile .mobile-subscribe {
  padding: 0 1rem;
}
#MenusMobile .mobile-subscribe a {
  width: auto;
  color: #ffffff;
  background-color: #333;
  border-radius: 5px;
  padding: 0.9rem;
  font-family: "futura-pt", sans-serif;
  font-weight: initial;
  text-transform: capitalize;
  width: 70%;
  max-width: 270px;
  margin-top: 1rem;
}
#MenusMobile .InlineSearchContainer {
  justify-content: center;
  margin: 0rem auto 1rem;
}
#MenusMobile .InlineSearchContainer .InlineSearchForm {
  width: 100%;
  max-width: 100%;
  background-color: rgb(232, 240, 254);
  border-radius: 0px;
  padding: 0.8rem 0.5rem;
}
#MenusMobile .InlineSearchContainer .search-input {
  width: 100%;
  left: 0;
  border-bottom: 1px solid #5973b8;
  text-align: left;
  padding: 3px 5px;
  font-size: 1.125rem;
  font-weight: 600;
}
@media (max-width: 380px) {
  #MenusMobile .InlineSearchContainer.open.menu-search {
    margin-top: 1rem !important;
  }
}
.InlineSearch {
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.InlineSearchContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.InlineSearchContainer.open .InlineSearchForm {
  opacity: 1;
}
.InlineSearchContainer .icon-btn {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #5973b8;
  position: absolute;
}
.InlineSearchContainer .icon-btn:focus, .InlineSearchContainer .icon-btn:hover {
  color: #515254;
}
.InlineSearchContainer .InlineSearchForm {
  width: 100%;
  max-width: 280px;
  overflow: hidden;
  position: relative;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  transition: width 0.5s ease, height 0.5s ease, opacity 0.25s ease;
  background-color: #eff1f8;
  padding: 0;
  opacity: 0;
}
.InlineSearchContainer .InlineSearchForm form {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.InlineSearchContainer .InlineSearchForm form .search-input {
  width: 100%;
  padding: 0.5rem;
  border-style: solid;
  border-width: 0 0 2px;
  border-color: #5973b8;
  background-color: transparent;
  color: #5973b8;
}
.InlineSearchContainer .InlineSearchForm form .search-input::placeholder {
  font-style: italic;
  color: #5973b8;
}
#Modal .magazine-gate-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

#Modal .magazine-gate-content {
  width: 600px;
  max-width: calc(100% - 30px);
  margin: 10% auto;
  height: auto;
  padding: 2rem 2rem 1rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: visible;
}
#Modal .magazine-gate-content h1 {
  margin: 0;
}
#Modal .magazine-gate-content input[type=submit] {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid #34c5f1;
  font-weight: lighter;
  background: #34c5f1;
}
#Modal .magazine-gate-content input[type=submit]:hover {
  background: #515254;
  color: #ffffff;
  border-color: #34c5f1;
}
#Modal .magazine-gate-content .close-btn {
  float: right;
  font-size: 28px;
  font-weight: 800;
  cursor: pointer;
  margin-top: -15px;
  margin-right: -10px;
}

@media (min-height: 400px) and (max-height: 700px) {
  #Modal .magazine-gate-content {
    margin: 2% auto;
  }
}
.showing-error .submitted-message {
  display: none;
}
#Modal > div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
}
#Modal .modal-gate {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#Modal .modal-gate .button-wrapper, #Modal .modal-gate .modal-gate-content {
  width: 500px;
  position: relative;
  max-width: calc(100% - 30px);
}
#Modal .modal-gate .button-wrapper.large, #Modal .modal-gate .modal-gate-content.large {
  width: 820px;
}
#Modal .modal-gate .modal-gate-content {
  max-height: 90vh;
  border-radius: 6px;
  overflow-y: auto;
}
#Modal .modal-gate .close-btn.corner {
  position: absolute;
  padding: 0.5rem;
  top: 0;
  right: 0;
}
#Modal .modal-gate .close-btn.in-form {
  margin-top: 0.7rem;
}
#Modal .modal-gate .close-btn:hover {
  text-decoration: underline;
}
#Modal .modal-gate .smart-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1.125rem;
  font-weight: initial;
  border-radius: 6px 6px 0 0;
  background-color: #5973b8;
}
@media (max-width: 800px) {
  #Modal .modal-gate .smart-header {
    font-size: 1rem;
  }
}
#Modal .modal-gate form {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  background-color: #eee;
}
#Modal .modal-gate form label {
  font-size: 0.85rem;
}
#Modal .modal-gate form input,
#Modal .modal-gate form select {
  font-size: 0.85rem;
  border-radius: 5px;
  width: 100%;
  padding: 0.3rem;
}
#Modal .modal-gate form button {
  width: 250px;
  align-self: center;
}
#Modal .modal-gate form .form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#Modal .modal-gate form .form-row .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  text-align: left;
  width: 48%;
}
@media (max-width: 800px) {
  #Modal .modal-gate form .form-row {
    flex-direction: column;
  }
  #Modal .modal-gate form .form-row .form-group {
    flex: 1 auto;
    width: 100% !important;
  }
}
#Modal .modal-gate form .error {
  margin: 0;
  padding: 5px;
  text-align: left;
  color: #c00;
}
#Modal .modal-gate form .error-inline {
  font-size: 0.75rem;
  margin-bottom: 1em;
  background-color: transparent;
}
#Modal .modal-gate form .alert {
  color: #c00;
}
#Modal .modal-gate .form-footer {
  font-size: 0.75rem;
  border-radius: 0 0 6px 6px;
}
#Modal .modal-gate .form-footer .email {
  color: #5973b8;
}
#Modal .modal-gate .pdfRelatedContent {
  position: relative;
  background-color: #eee;
  text-align: center;
}
#Modal .modal-gate .pdfRelatedContent .articleTabList {
  gap: 1rem;
  padding-bottom: 0.5rem;
  justify-content: flex-start;
}
#Modal .modal-gate .pdfRelatedContent .articleTabList::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#Modal .modal-gate .pdfRelatedContent .articleTabList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
#Modal .modal-gate .pdfRelatedContent .articleTabList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
#Modal .modal-gate .pdfRelatedContent .articleTabList::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#Modal .modal-gate .pdfRelatedContent .articleTab {
  gap: 1rem;
  flex: 1;
  width: calc(33% - 1rem);
  padding-bottom: 1rem;
  margin: 0;
  background-color: #fff;
}
#Modal .modal-gate .pdfRelatedContent .card-list-arrow {
  display: none;
}
@media (max-width: 650px) {
  #Modal .modal-gate .pdfRelatedContent .articleTabList {
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    transition: scroll-left 0.3s ease;
  }
  #Modal .modal-gate .pdfRelatedContent .articleTabList > * {
    min-width: 250px;
  }
  #Modal .modal-gate .pdfRelatedContent .articleTabList .articleTab {
    scroll-snap-align: start;
  }
  #Modal .modal-gate .pdfRelatedContent .card-list-arrow {
    background-color: #34c5f1;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 65%;
    right: 9%;
    transform: translate(50%, -50%);
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
    font-size: 24px;
    cursor: pointer;
    user-select: none;
  }
}
.Pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: #929292;
  padding: 0.5rem 1rem;
  margin: 1rem auto;
  border-width: 0;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 0;
  background-color: transparent;
}
@media (max-width: 568px) {
  .Pagination {
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .Pagination div:first-of-type {
    margin: 0 0 0.5rem;
  }
}
.Pagination button {
  background: transparent;
  border: none;
  padding: 0.25rem 0.5rem;
  color: #5973b8;
  cursor: pointer;
}
.Pagination button:hover {
  text-decoration: underline;
}
.Pagination button.selected:hover {
  text-decoration: none;
}
.Pagination button.selected {
  cursor: default;
  background-color: #5973b8;
  color: #fff;
}
.Pagination button:disabled {
  color: #929292;
  cursor: default;
}
/* Moved all to _newsletter_signup.scss */
.SearchPageBar {
  display: flex;
  padding: 1.5rem 0;
  min-width: 100%;
}
.SearchPageBar .searchbar-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}
.SearchPageBar .lm-searchform {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.SearchPageBar .lm-searchform .search-fields {
  display: flex;
  width: 90%;
  margin-right: 1rem;
  align-items: center;
}
.SearchPageBar .lm-searchform .search-fields .input-wrapper {
  border-bottom: 3px solid #34c5f1;
  flex: 1;
}
.SearchPageBar .lm-searchform .search-fields .input-wrapper input[type=text] {
  width: 90%;
  border: none;
  padding: 0.5rem 1rem;
}
.SearchPageBar .lm-searchform .search-fields input[type=submit] {
  margin-left: 1rem;
}
.SearchPageBar .lm-searchform .search-fields .btn {
  padding: 0.5rem 1rem;
}
.SearchPageBar .search-filters {
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  margin: 0rem;
}
.SearchPageBar .search-filters h5 {
  margin: 0rem;
}
.SearchPageBar .search-filters .filter,
.SearchPageBar .search-filters .dropdown-filter {
  position: relative;
  margin-bottom: 0;
  margin-right: 0;
  padding-bottom: 10px;
  cursor: pointer;
}
.SearchPageBar .search-filters .selected {
  border-bottom: 3px solid #34c5f1;
}
.SearchPageBar .search-filters .dropdown-filter {
  padding: 0.5rem;
  border: 1px solid #34c5f1;
  color: #34c5f1;
  display: flex;
}
.SearchPageBar .search-filters .dropdown-options {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  padding: 0;
  z-index: 99;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.SearchPageBar .search-filters .dropdown-options .dropdown-item {
  width: 100%;
  font-size: 0.85rem;
  font-weight: 400;
  border: 1px solid #e5e5e5;
  padding: 0.5rem;
}
.SearchPageBar .search-filters .dropdown-options .dropdown-item:hover {
  color: #fff;
  background-color: #34c5f1;
}
.SearchPageBar .search-filters .dropdown-arrow {
  margin-left: 5px;
}
@media (max-width: 799px) {
  .SearchPageBar .lm-searchform {
    flex-direction: column;
    align-items: flex-end;
  }
  .SearchPageBar .lm-searchform .search-filters {
    margin-top: 1rem;
  }
  .SearchPageBar .lm-searchform .search-fields {
    width: 100%;
  }
}
#TrendsNav {
  position: sticky;
  margin: 0;
  padding: 0;
  top: 0;
  z-index: 15;
  transition: 0.6s;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 7px rgba(52, 197, 241, 0.2), 0px -2px 8px -1px rgba(0, 0, 0, 0.1);
  border-bottom: 6px solid #34c5f1;
}
#TrendsNav.headerShown {
  top: 2rem;
  box-shadow: none;
}
#TrendsNav .TrendsNav {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  width: 90vw;
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  #TrendsNav .TrendsNav {
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
  }
  #TrendsNav .TrendsNav .seriesTitle,
  #TrendsNav .TrendsNav .TrendsSponsor {
    padding: 0.5rem;
    max-width: 50%;
    margin: 0;
  }
}
#TrendsNav .TrendsSponsor,
#TrendsNav .seriesTitle {
  font-weight: 300;
  font-size: 0.8rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 110px;
  height: 80px;
  overflow: hidden;
  align-items: flex-start;
  text-transform: uppercase;
  position: relative;
}
#TrendsNav .TrendsSponsor p,
#TrendsNav .seriesTitle p {
  margin: 0;
}
#TrendsNav .TrendsSponsor img,
#TrendsNav .seriesTitle img {
  width: auto;
  height: 100%;
  max-width: 100%;
  max-height: 2.75rem;
}
#TrendsNav .TrendsSponsor span,
#TrendsNav .seriesTitle span {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
}
#TrendsNav .TrendsSponsor {
  align-items: center;
  max-width: 250px;
}
#TrendsNav .menu-wrapper {
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
#TrendsNav .menu-wrapper .menu {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0 1rem;
}
@media (max-width: 1024px) {
  #TrendsNav .menu-wrapper .menu {
    padding: 0;
  }
}
#TrendsNav .ideaNavItem {
  font-weight: 300;
  font-size: 0.8rem;
  flex-grow: 1;
  height: 80px;
  position: relative;
  border: none;
}
@media (max-width: 1024px) {
  #TrendsNav .ideaNavItem {
    height: 60px;
  }
}
#TrendsNav .ideaNavItem a {
  padding: 1rem 0.5rem;
  min-width: 0;
  display: inline-block;
  margin: 0;
  width: calc(100% - 40px);
  height: 100%;
  white-space: normal;
  position: relative;
  border: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#TrendsNav .ideaNavItem a p {
  margin: 0;
  font-weight: 600;
}
#TrendsNav .ideaNavItem a:after {
  content: "";
  border-radius: 0 20px 0 0;
  border: 1px solid #ccc;
  border-left: 0;
  position: absolute;
  top: 0;
  bottom: -5px;
  right: -26px;
  width: 30px;
  background: #fff;
  margin-top: -1px;
  box-shadow: 10px 0 12px rgba(0, 0, 0, 0.03);
}
#TrendsNav .ideaNavItem a:before {
  content: "";
  border: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -40px;
  width: 41px;
  border-right: 0;
  border-bottom: 0;
  background: #fff;
  z-index: -1;
  margin-top: -1px;
}
#TrendsNav .ideaNavItem.active:not(.unpublished):not(.seriesTitle) a, #TrendsNav .ideaNavItem:hover:not(.unpublished):not(.seriesTitle) a {
  background: #34c5f1;
  color: #fff;
  position: relative;
}
#TrendsNav .ideaNavItem.active:not(.unpublished):not(.seriesTitle) a:after, #TrendsNav .ideaNavItem.active:not(.unpublished):not(.seriesTitle) a:before, #TrendsNav .ideaNavItem:hover:not(.unpublished):not(.seriesTitle) a:after, #TrendsNav .ideaNavItem:hover:not(.unpublished):not(.seriesTitle) a:before {
  background: #34c5f1;
}
#TrendsNav .ideaNavItem.unpublished a {
  background: #eaeef6;
  cursor: initial;
  color: #929292;
}
#TrendsNav .ideaNavItem.unpublished a p {
  text-decoration: none;
  color: #929292;
}
#TrendsNav .ideaNavItem.unpublished a:before, #TrendsNav .ideaNavItem.unpublished a:after {
  background: #eaeef6;
}
#TrendsNav .slick-slide:first-of-type {
  padding-left: 0.5rem;
  max-width: 240px;
  border-left: 1px solid #ccc;
}
#TrendsNav .slick-slide:not(:first-of-type).slick-current .ideaNavItem a {
  padding-left: 1.5rem;
}
@media (max-width: 1024px) {
  #TrendsNav .slick-slide:not(:first-of-type).slick-current .ideaNavItem a {
    padding-left: 2.5rem;
  }
}
@media (max-width: 1024px) {
  #TrendsNav .slick-slide:first-of-type {
    border-left: none;
  }
}
@media (min-width: 1024px) {
  #TrendsNav .slick-slide {
    max-width: 210px;
  }
}
#TrendsNav .slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  height: 100%;
  position: absolute;
  width: 1rem;
}
#TrendsNav .slick-arrow svg {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background: #34c5f1;
  padding: 0;
  height: 1.5rem !important;
  width: 1.5rem;
  border-radius: 50%;
  padding: 0.3rem 0;
  position: absolute;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}
#TrendsNav .slick-arrow.slick-disabled {
  display: none;
}
#TrendsNav .slick-arrow.slick-prev {
  left: 0;
  border-left: 1px solid #ccc;
  box-shadow: inset 5px 0 4px rgba(0, 0, 0, 0.2);
}
#TrendsNav .slick-arrow.slick-prev svg {
  left: -100%;
  filter: drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.4));
}
@media (max-width: 1024px) {
  #TrendsNav .slick-arrow.slick-prev svg {
    left: 0.25rem;
  }
}
#TrendsNav .slick-arrow.slick-next {
  right: 0;
  border-right: 1px solid #ccc;
  box-shadow: inset -5px 0 4px rgba(0, 0, 0, 0.2);
}
#TrendsNav .slick-arrow.slick-next svg {
  right: -100%;
  filter: drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.4));
}
@media (max-width: 1024px) {
  #TrendsNav .slick-arrow.slick-next svg {
    right: 0.25rem;
  }
}
#TrendsNav .slick-arrow:before {
  display: none;
}
#LeadForm form {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 1rem 2rem;
  background: rgb(245, 245, 245);
}
#LeadForm .heading {
  font-size: 1.424rem;
  font-weight: initial;
  padding: 1rem 1.5rem;
  background: #34c5f1;
  color: #fff;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-radius: 8px 8px 0 0;
  margin-bottom: 0;
}
#LeadForm .form-title {
  border-bottom: 2px solid #34c5f1;
  display: inline-block;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
}
#LeadForm input,
#LeadForm select,
#LeadForm textarea {
  margin: 0 0 1rem;
  font-size: 1.105rem;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #cccccc;
}
#LeadForm input[type=checkbox],
#LeadForm select[type=checkbox],
#LeadForm textarea[type=checkbox] {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin: 0 0.5rem;
}
#LeadForm .form-section .form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
  #LeadForm .form-section .form-row {
    flex-direction: column;
  }
}
#LeadForm .form-section .form-row.one-item .form-group {
  width: 100%;
}
#LeadForm .form-section .form-row.two-items .form-group {
  width: 45%;
}
#LeadForm .form-section .form-row.three-items .form-group {
  width: 30%;
}
@media screen and (max-width: 800px) {
  #LeadForm .form-section .form-row .form-group {
    flex: 1 auto;
    width: 100% !important;
  }
}
#LeadForm .form-section .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  text-align: left;
}
#LeadForm .form-section .product-info {
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  background: #fff;
}
#LeadForm .form-section .product-info .product-detail {
  padding: 1rem;
}
#LeadForm .form-section .product-info .product-image {
  min-width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
#LeadForm .form-section .product-info .product-image img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
@media screen and (max-width: 568px) {
  #LeadForm .form-section .product-info {
    flex-direction: column;
  }
}
#LeadForm .form-section .product-info .vedor-logo {
  width: 100%;
  height: auto;
  overflow: hidden;
}
#LeadForm .form-section .product-info .vedor-logo img {
  height: 100%;
  width: 150px;
  padding: 0.5rem 0rem;
}
#LeadForm .form-section .form-section-wrapper {
  padding: 1rem;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  background: #fff;
}
#LeadForm .form-section .inline-fieldset {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  margin: 0.5rem 0;
}
#LeadForm a {
  color: #34c5f1;
  text-decoration: underline;
}
#LeadForm .error {
  margin: 0;
  padding: 5px;
  text-align: left;
  color: #c00;
}
#LeadForm .error-inline {
  font-size: 0.9rem;
}
#LeadForm .alert {
  font-style: italic;
  color: #c00;
}

.smart-label {
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
}
.smart-label .inline-required {
  margin-left: 4px;
  margin-right: auto;
  color: #c00;
  font-size: 8px;
}
.smart-label .optIn {
  font-size: 0.9rem;
  font-weight: 100;
}
.smart-label i {
  font-size: 8px;
  margin-bottom: 8px;
  color: #c00;
}